import React, { useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Snackbar,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Edit3 as EditIcon,
  Save as SaveIcon,
} from 'react-feather';
import { CancelOutlined } from '@material-ui/icons';
import APIService from '../../utils/APIService';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 400,
  },
  actions: {
    justifyContent: 'flex-end',
  }
}));

const TranslationEditor = ({
  data, languages, open, onClose, onSuccess
}) => {
  const classes = useStyles();
  const [formItems, setFormItems] = useState([]);
  const [processing, setProcessing] = useState(false);

  languages.forEach((e) => {
    if (!formItems.find((re) => re.localeId === e.id)) {
      if (e.id === data.localeId) {
        formItems.push(data);
      } else {
        formItems.push({
          text: '',
          langId: data.id,
          localeId: e.id
        });
      }
    }
  });

  const getTranslations = () => APIService.getTranslationsByLangId(data.id, (success, json) => {
    if (success && json.result) {
      const records = json.result;

      formItems.map((e) => {
        const translation = records.find((re) => re.localeId === e.localeId);
        if (translation) {
          e.id = translation.id;
          e.text = translation.text;
        }
        return e;
      });
      setFormItems(formItems.map((e) => e));
    }
  });

  const saveForm = () => {
    if (processing) return;
    setProcessing(true);

    const promises = formItems.map((e) => new Promise((resolve, reject) => {
      if (e.id) {
        APIService.editTranslation(e.id, e.text, e.langId, e.localeId, (success, json) => {
          if (success && json.result) resolve(json.result);
          else reject(json.error);
        });
      } else {
        APIService.addTranslation(e.text, e.langId, e.localeId, (success, json) => {
          if (success && json.result) resolve(json.result);
          else reject(json.error);
        });
      }
    }));

    Promise.all(promises).then(() => {
      setProcessing(false);
      if (onSuccess) onSuccess();
    }).catch((e) => {
      setProcessing(false);
      console.log(e);
    });
  };

  useEffect(() => {
    getTranslations();
  }, []);

  return (
    <Snackbar open={open}>
      <Card className={classes.card}>
        <CardHeader
          avatar={<EditIcon />}
          action={(
            <IconButton
              aria-label="dismiss"
              onClick={() => onClose && onClose()}
            >
              <CancelOutlined />
            </IconButton>
          )}
          title="Edit"
          subheader={data.text}
        />
        <CardContent>
          <List>
            {languages.map((e) => {
              const translation = formItems.find((trans) => trans.localeId === e.id);

              return (
                <ListItem key={e.country}>
                  <ListItemIcon>{e.flag}</ListItemIcon>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    onChange={(event) => {
                      translation.text = event.target.value;
                      setFormItems(formItems.map((a) => a));
                    }}
                    type="text"
                    value={translation.text}
                    variant="outlined"
                  />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
        {processing && <LinearProgress />}
        <CardActions disableSpacing className={classes.actions}>
          <IconButton aria-label="save" onClick={() => saveForm()}>
            <SaveIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Snackbar>
  );
};

TranslationEditor.propTypes = {
  data: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default TranslationEditor;
