import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  root: {}
}));

const UserStatsChart = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const months = [];
  const userInMonths = data ? data.userInMonths : [];
  const activeUserInMonths = data ? data.activeUserInMonths : [];

  if (data && data.userInMonths) {
    const date = moment();
    data.userInMonths.forEach(() => {
      months.push(date.format('MMM DD'));
      date.subtract(1, 'month');
    });
  }

  const stats = {
    datasets: [
      {
        backgroundColor: colors.green[500],
        data: userInMonths,
        label: 'User gain'
      },
      {
        backgroundColor: colors.blue[300],
        data: activeUserInMonths,
        label: 'Active user'
      }
    ],
    labels: months.reverse()
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        action={(
          <Button
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
          >
            {`Last ${months.length} months`}
          </Button>
        )}
        title="User Statistics"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={stats}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={3}
      />
    </Card>
  );
};

UserStatsChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default UserStatsChart;
