import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SunREInput from '../dynamic-contents/Editor';

const Uploader = ({ visible, onClose, onSuccess }) => {
  const [, setThumb] = useState('');
  const onCloseModal = () => {
    setThumb(null);
    if (onClose) onClose();
  };
  return (
    <Dialog open={visible} onClose={onCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add FAQ</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            title: '',
            content: '',
            index: null,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            content: Yup.string().required('Content is required'),
          })}
          onSubmit={(values, actions) => {
            APIService.addFAQ(
              values.title,
              values.content,
              values.index,
              (success, json) => {
                actions.setSubmitting(false);
                if (success && json.result) {
                  actions.setStatus('Success');
                  onCloseModal();
                  if (onSuccess) onSuccess(json);
                } else {
                  actions.setErrors({ error: 'Failed to upload!' });
                }
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                fullWidth
                autoFocus
                label="Title"
                margin="dense"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.index && errors.index)}
                helperText={touched.index && errors.index}
                fullWidth
                autoFocus
                label="Index"
                margin="dense"
                name="index"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.index}
                variant="outlined"
              />
              <SunREInput
                label="Content"
                name="content"
                content={values.content}
                placeholder="Content"
                onChange={(text) => setFieldValue('content', text)}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width={400}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
                <Box ml={4} />
                <Button
                  color="secondary"
                  size="large"
                  variant="outlined"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

Uploader.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default Uploader;
