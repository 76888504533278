import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  Input as InputIcon,
  VerifiedUserOutlined as PrivacyIcon,
  // ReportOutlined as ReportIcon,
  NotificationsOutlined as NotifIcon,
  MailOutline as MailIcon,
  StorageOutlined as CacheIcon,
  TableChartOutlined as DashboardIcon,
  LibraryBooksOutlined as FormIcon,
  // MonetizationOn as SubscriptionIcon,
} from '@material-ui/icons';
import {
  Paperclip as PaperclipIcon,
  Users as UsersIcon,
  Settings as SettingsIcon,
  HelpCircle as FAQIcon,
  FileText as TermsIcon,
  Activity as ActivityIcon
  // Flag as LocaleIcon,
  // Globe as LanguageIcon,
} from 'react-feather';
import Settings from 'src/utils/Settings';
import { inject, observer } from 'mobx-react';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/treadmeal.png',
  jobTitle: 'Copyright @ 2021',
  name: 'SMB API Core'
};
const Groups = {
  Tags: 1,
  Templates: 2,
  Menus: 3,
};
const items = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Users'
  },
  {
    href: '/app/applications',
    icon: FormIcon,
    title: 'Applications'
  },
  // {
  //   href: '/app/subscriptions',
  //   icon: SubscriptionIcon,
  //   title: 'Subscriptions'
  // },
  {
    href: '/app/cuisines',
    icon: PaperclipIcon,
    title: 'Cuisines'
  },
  // {
  //   href: '/app/reports',
  //   icon: ReportIcon,
  //   title: 'Reports'
  // },
  {
    toggle: true,
    title: 'Templates',
    icon: TermsIcon,
    group: Groups.Templates,
    items: [
      {
        href: '/app/template-notif',
        icon: NotifIcon,
        title: 'Notification'
      },
      {
        href: '/app/template-email',
        icon: MailIcon,
        title: 'Email'
      },
    ]
  },
  {
    href: '/app/privacy',
    icon: PrivacyIcon,
    title: 'Privacy'
  },
  {
    href: '/app/terms',
    icon: TermsIcon,
    title: 'Terms & Conditions'
  },
  {
    href: '/app/faq',
    icon: FAQIcon,
    title: 'FAQ'
  },
  /*
  {
    href: '/app/regions',
    icon: LocaleIcon,
    title: 'Regions'
  },
  {
    href: '/app/translations',
    icon: LanguageIcon,
    title: 'Translations'
  },
  */
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/app/cache',
    icon: CacheIcon,
    title: 'Cache'
  },
  {
    href: '/app/logs',
    icon: ActivityIcon,
    title: 'Logs'
  },
  {
    href: '/logout',
    icon: InputIcon,
    title: 'Logout'
  },
];

const useStyles = makeStyles(() => ({
  name: {
    color: '#BBBBBB'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    WebkitFilter: 'drop-shadow(0px 0px 2px rgba(255,255,255,0.5))',
    // filter: 'drop-shadow(1px 1px 1px #FFFFFF)',
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
  const { dataStore } = rest;
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [visibleGroup, showGroup] = useState(0);
  const name = Settings.appName();

  const logout = () => {
    Settings.setRole(null);
    Settings.setSessionKey(null);
    dataStore.setRole('');
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const toggleGroup = (group) => {
    if (visibleGroup === group) {
      showGroup(0);
    } else {
      showGroup(group);
    }
  };

  const content = (
    <Box
      style={{ backgroundColor: '#212121' }}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {name || user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          style={{ color: '#BBBBBB' }}
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => {
            const expand = item.group === visibleGroup;
            return (item.toggle
              ? (
                <Box key={item.title}>
                  <NavItem
                    href="#"
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    toggle={expand ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => toggleGroup(item.group)}
                  />
                  <Collapse in={expand} timeout="auto" unmountOnExit>
                    {
                      item.items.map((e) => (
                        <Box key={e.title} paddingLeft={3}>
                          <NavItem
                            href={e.href}
                            key={e.title}
                            title={e.title}
                            icon={e.icon}
                          />
                        </Box>
                      ))
                    }
                  </Collapse>
                </Box>
              ) : (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  onClick={() => item.href === '/logout' && logout()}
                />
              ));
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default inject('dataStore')(observer(NavBar));
