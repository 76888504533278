import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import { HttpMethod } from 'src/utils/Settings';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import Content from './Content';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Logs = () => {
  const classes = useStyles();
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState();
  const [filter, setFilter] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleContent, showContent] = useState(false);

  const [searchText, setSearchText] = useState('');

  const getContents = () => APIService.getLogs((success, json) => {
    if (success && json.result) {
      setContents(json.result);
    }
  });

  const deleteItems = () => APIService.deleteManyLogs(selectedItems, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getContents();
    }
  });

  const deletePost = (id) => APIService.deleteLog(id, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getContents();
    }
  });

  const onShowContent = (item) => {
    showContent(true);
    setContent(item.body);
  };

  useEffect(() => {
    getContents();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Logs"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => deleteItems() : null}
          filterBy={[
            {
              key: { name: 'Method', value: 'method' },
              options: [
                { name: 'Post', value: HttpMethod.POST },
                { name: 'Get', value: HttpMethod.GET },
                { name: 'Put', value: HttpMethod.PUT },
                { name: 'Patch', value: HttpMethod.PATCH },
                { name: 'Delete', value: HttpMethod.DELETE },
              ]
            },
          ]}
          onFilter={(value) => { setFilter(value); }}
        />
        <Box mt={3}>
          <Results
            data={contents}
            filter={filter}
            searchText={searchText}
            selectedIds={selectedItems}
            onPreView={(item) => onShowContent(item)}
            onSelected={(ids) => setSelectedItems(ids)}
            onDelete={(id) => deletePost(id)}
          />
        </Box>
        <Content
          content={content}
          visible={visibleContent}
          onClose={() => showContent(false)}
          onSuccess={() => getContents()}
        />
      </Container>
    </Page>
  );
};

Logs.propTypes = {
};
export default Logs;
