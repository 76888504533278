import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  CardContent,
  makeStyles,
  Typography
} from '@material-ui/core';
import colors from 'src/common/colors';
import OperationHours from './OperationHours';
import PaymentMethods from './PaymentMethods';
import TextHeading from './TextHeading';
import MenuGroup from './MenuGroup';

const useStyles = makeStyles(() => ({
  root: {

  },
  avatar: {
    height: 80,
    width: 80,
    alignSelf: 'center',
  },
  dash: {
    padding: 10,
    borderRadius: 5,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
  },
  descBox: {
    padding: 10,
    minHeight: 100,
    width: '100%',
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  pricePoint: {
    display: 'inline-block'
  }
}));

const TruckProfile = ({
  user, data
}) => {
  const classes = useStyles();
  const { pricePoint, description } = data;
  const points = pricePoint || user?.operation?.pricePoint;
  const { address, city, zipCode } = user?.contact || {};
  // const pricePoints = Array(points || 1).fill('$').join('');
  const openingHours = data?.openingHours?.length !== 0
    ? data?.openingHours
    : user?.operation?.openingHours;
  const paymentMethods = Array.isArray(data?.operation?.paymentMethods)
    && data?.operation?.paymentMethods?.length !== 0
    ? data?.operation?.paymentMethods
    : user?.operation?.paymentMethods;
  const menuGroups = user?.operation?.menuGroups;
  return (
    <Box>
      <CardContent>
        <Typography
          align="center"
          style={{ marginBottom: 20 }}
        >
          {user?.businessName}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {/* <Box className={classes.dash}> */}
          <Avatar
            className={classes.avatar}
            src={user?.avatar || user?.operation?.logo || user?.operation?.logoURL}
          />
          {/* </Box> */}
          <TextHeading style={{ marginTop: 10 }}>Available Trucks</TextHeading>
          {user?.trucks.filter((truck) => truck.status === 'ACTIVE').map((truck, index) => {
            return (
              <Typography style={{ alignSelf: 'flex-start', marginTop: 10, maxWidth: 400 }}>
                {index + 1}
                {'. '}
                {truck.address}
              </Typography>
            );
          })}
          <TextHeading style={{ marginTop: 10 }}>Company Info</TextHeading>
          <Typography>{user?.phoneNumber}</Typography>
          <Typography>
            {address}
            {city ? `, ${city}` : ''}
            {zipCode ? `, ${zipCode}` : ''}
          </Typography>
          <Box display="flex" mt={1}>
            <Box>
              <TextHeading>Cuisine Type</TextHeading>
              <Typography align="center">{user?.cuisine?.title}</Typography>
            </Box>
            <Box align="center" ml={3}>
              <TextHeading>Price Point</TextHeading>
              <Typography className={classes.pricePoint}>
                {'$'.repeat(points)}
              </Typography>
              <Typography className={classes.pricePoint} style={{ color: colors.grey }}>
                {'$'.repeat(4 - points)}
              </Typography>
            </Box>
          </Box>
          <OperationHours data={openingHours || []} center />
          <PaymentMethods data={paymentMethods || []} />
          {description && (
            <Box mb={2} align="center" style={{ width: '100%' }}>
              <TextHeading>Description</TextHeading>
              <Box className={classes.descBox}>
                <Typography>{description}</Typography>
              </Box>
            </Box>
          )}
          {
            menuGroups.length > 0 && (
              <Box mb={2} align="center" style={{ width: '100%' }}>
                <TextHeading>Menu</TextHeading>
                {menuGroups.map((group) => (
                  <MenuGroup
                    style={{ width: '100%' }}
                    data={{
                      name: group.name,
                      description: group.description,
                      menuFoods: group.menuFoods
                    }}
                  />
                ))}
              </Box>
            )
          }
        </Box>
      </CardContent>
    </Box>
  );
};

TruckProfile.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

export default TruckProfile;
