import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Content = ({
  visible, onClose, children
}) => {
  const [, setThumb] = useState('');
  const onCloseModal = () => {
    setThumb(null);
    if (onClose) onClose();
  };
  return (
    <Dialog open={visible} onClose={onCloseModal} aria-labelledby="form-dialog-title">
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

Content.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.func,
};
export default Content;
