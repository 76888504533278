import React, { useEffect, useRef } from 'react';
import ContactUs from '../components/ContactUs';
import Filter from '../components/Filter';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';

const CustomerHome = () => {
  const searchRef = useRef();
  const hiwRef = useRef();
  const pricingRef = useRef();
  const contactRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      switch (window.location.hash) {
        case '#search': searchRef.current.scrollIntoView();
          break;
        case '#how-it-works': hiwRef.current.scrollIntoView();
          break;
        case '#pricing': pricingRef.current.scrollIntoView();
          break;
        case '#contact-us': contactRef.current.scrollIntoView();
          break;
        default:
      }
    }, 300);
  }, []);

  return (
    <>
      <div ref={searchRef}><Filter anchor="search" /></div>
      <div ref={hiwRef}><HowItWorks anchor="how-it-works" /></div>
      <div ref={pricingRef}><Pricing anchor="pricing" /></div>
      <div ref={contactRef}><ContactUs anchor="contact-us" useCard={false} /></div>
    </>
  );
};

export default CustomerHome;
