import {
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import ColorSection from 'src/components/ColorSection';
import SignUpBasicInfo from '../components/SignUpBasicInfo';
import SignUpBusinessInfo from '../components/SignUpBusinessInfo';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    padding: 30,
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: 21,
    fontWeight: '600',
  },
  logo: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 35,
    paddingLeft: 80,
    paddingRight: 80,
  },
  title: {
    fontWeight: '600',
  },
}));

const TruckOwnerApplication = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [step, setStep] = useState(0);

  const handleSubmit = (nextStep, profile) => {
    if (nextStep < 2) {
      setStep(nextStep);
    }
    setData({ ...data, ...profile });

    if (nextStep === 2) {
      navigate('/application-done');
    }
  };

  const stepComp = [
    {
      title: 'Thank you for your interest.\nLet’s start your application.',
      subTitle: 'Please enter your basic account information.',
      children: <SignUpBasicInfo onSubmit={(profile) => handleSubmit(1, profile)} />,
    },
    {
      subTitle: 'Please enter your business’s information.',
      children: <SignUpBusinessInfo data={data} onSubmit={(profile) => handleSubmit(2, profile)} />,
    },
  ];

  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            direction="column"
            alignItems="center"
          >
            <Card className={classes.card}>
              <Typography className={classes.welcomeText}>
                Welcome to
              </Typography>
              <img alt="" src="/static/images/treadmeal_logo.png" className={classes.logo} />
              <Box mt={4} mb={3}>
                {stepComp[step].title && stepComp[step].title.split('\n').map((line) => (
                  <Typography align="center" className={classes.title}>
                    {line}
                  </Typography>
                ))}
              </Box>
              {stepComp[step].subTitle && (
              <Typography align="center" className={classes.desc}>
                {stepComp[step].subTitle}
              </Typography>
              )}
              {
                  stepComp[step].children
                }
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default TruckOwnerApplication;
