import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import TranslationEditor from './Editor';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Translations = () => {
  const classes = useStyles();
  const location = useLocation();
  const [contents, setContents] = useState([]);
  const [languages, setLanguages] = useState(Settings.languages() || []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [item2Edit, editItem] = useState();
  const [notice, showNotice] = useState('');

  const getContents = () => APIService.getTranslations((success, json) => {
    if (success && json.result) {
      setContents(json.result);
    }
  });

  const getLanguages = () => APIService.getLanguages((success, json) => {
    if (success && json.result) {
      Settings.setLanguages(json.result);
      setLanguages(json.result);
    }
  });

  useEffect(() => {
    getContents();
    getLanguages();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Translations"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
        />
        <Box mt={3}>
          <Results
            data={contents}
            searchText={searchText}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
            onEditItem={(item) => editItem(item)}
          />
        </Box>
      </Container>
      {item2Edit && (
        <TranslationEditor
          data={item2Edit}
          languages={languages}
          open={!!item2Edit}
          onClose={() => editItem(null)}
          onSuccess={() => {
            editItem(null);
            showNotice('Updated successfully!');
          }}
        />
      )}
      <Snackbar open={!!notice} autoHideDuration={2000} onClose={() => showNotice(null)}>
        <Alert severity="success">{notice}</Alert>
      </Snackbar>
    </Page>
  );
};

export default Translations;
