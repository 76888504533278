import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Container,
  Grid
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import APIService from 'src/utils/APIService';
import Settings, { Roles } from 'src/utils/Settings';
import MenuPhotos from '../application/MenuPhotos';
import Account from './Account';
import Business from './Business';
import MenuList from '../menu/MenuList';
import EditProfileBasic from './EditProfileBasic';
import SubscriptionInfo from './SubscriptionInfo';
import EditProfileTO from './EditProfileTO';
import EditProfileCustomer from './EditProfileCustomer';

const UserProfile = ({ ...rest }) => {
  const location = useLocation();
  const { dataStore } = rest;
  const params = useParams();
  const [isEditProfile, setIsEditProfile] = useState(false);
  const data = dataStore.application || {};
  const [user, setUser] = useState(data.user || dataStore.user);
  const [role, setRole] = useState(user?.role || Roles.CUSTOMER);
  const [internalUser, setInternalUser] = useState(Settings.profile());
  const isTruckOwner = role === Roles.TRUCK_OWNER;
  const isAdmin = Settings.role() === Roles.ADMIN;

  const fetchProfile = () => {
    if (internalUser?.id === user.id || !user.id) {
      const sessionKey = Settings.sessionKey();
      APIService.getProfile(sessionKey, (success, json) => {
        if (success && json.result) {
          dataStore.setUser(json.result);
          setUser(dataStore.user);
          setRole(dataStore.user?.role);
        }
      });
    } else {
      APIService.getUserProfileById(user.id, (success, json) => {
        if (success && json.result) {
          dataStore.setUser(json.result);
          setUser(dataStore.user);
          setRole(dataStore.user?.role);
        }
      });
    }
  };

  const fetchData = () => {
    if (isAdmin) {
      const { id } = params;
      if (id) {
        APIService.getUserProfileById(id, (success, json) => {
          if (success && json.result) {
            setUser(json.result);
            setRole(json.result.role);
          }
        });
      }
    } else {
      fetchProfile();
    }
  };
  useEffect(() => {
    fetchData();
    setInternalUser(Settings.profile());
  }, [location.pathname]);

  const appImages = data?.images && data.images.length > 0 ? data.images : [];
  const images = user?.applications && user.applications.length > 0
    && user.applications[0].images.length > 0
    ? user.applications[0].images : appImages;

  return (
    <Container style={{ padding: 30, minHeight: '60vh', }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Grid item md={12}>
            <Account
              user={user}
              disableEdit={
                !((internalUser?.role === Roles.ADMIN) || (internalUser?.id === user.id))
                || internalUser?.role === Roles.TRUCK_OWNER
              }
              onEdit={() => setIsEditProfile(true)}
            />
            {isTruckOwner && <Business data={user} user={user} onFetch={() => fetchData()} />}
            {isAdmin && isTruckOwner
              && (
                <MenuPhotos images={images} />
              )}
            {user.subscription && (
              <SubscriptionInfo user={user} />
            )}
          </Grid>
        </Grid>
        {isTruckOwner && (
          <Grid item xs={12} sm={12} md={6}>
            <MenuList user={user} editable={isAdmin} />
          </Grid>
        )}
        <EditProfileBasic
          style={{ padding: 30 }}
          profile={user}
          open={isEditProfile
            && (internalUser?.role === Roles.CUSTOMER && user?.role === Roles.CUSTOMER)}
          onClose={() => { setIsEditProfile(false); fetchData(); }}
        />
        <EditProfileCustomer
          style={{ padding: 30 }}
          profile={user}
          open={isEditProfile
            && (internalUser?.role === Roles.ADMIN && user?.role === Roles.CUSTOMER)}
          onClose={() => { setIsEditProfile(false); fetchData(); }}
        />
        {isEditProfile
          && (internalUser?.role === Roles.ADMIN && user?.role === Roles.TRUCK_OWNER) && (
            <EditProfileTO
              style={{ padding: 30 }}
              profile={user}
              open={isEditProfile
                && (internalUser?.role === Roles.ADMIN && user?.role === Roles.TRUCK_OWNER)}
              onClose={() => { setIsEditProfile(false); fetchData(); }}
            />
        )}
      </Grid>
    </Container>
  );
};

export default inject('dataStore')(observer(UserProfile));
