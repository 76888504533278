import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import colors from 'src/common/colors';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  inner: {},
  heading: {
    maxWidth: 832,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    textAlign: 'center',
    fontSize: 'calc(2.42vw + 2.4vmin)',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: 56,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 20,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  body: (subtitle) => ({
    marginTop: subtitle ? 40 : 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: subtitle ? 20 : 10,
    }
  })
}));

export const ColorSection = ({
  className,
  color,
  title,
  subtitle,
  children,
  anchor,
}) => {
  const classes = useStyle({ subtitle });

  return (
    <Box
      className={clsx(classes.root, className)}
      style={{ backgroundColor: color || colors.white }}
    >
      {!!anchor && <a name={anchor}> </a>}
      <Box className={classes.inner}>
        <Box className={classes.heading}>
          {!!title && <Typography className={classes.title}>{title}</Typography>}
          {!!subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </Box>
        {children && (
          <Box className={classes.body}>
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

ColorSection.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element,
  anchor: PropTypes.string,
};

export default ColorSection;
