import React from 'react';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import dropdownKeyword from './DropdownTag';

const useStyles = makeStyles(({
  root: {
    marginTop: 10,
    marginBottom: 20
  },
  label: {
    color: '#536e7a',
    textAlign: 'center',
    fontSize: 14,
  }
}));

// must be outside of function component
const JoditConfigs = ({
  readonly: false
});

const defaultFonts = [
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Impact',
  'Georgia',
  'Tahoma',
  'Trebuchet MS',
  'Verdana'
];

const SunEditorOptions = ({
  plugins: [dropdownKeyword],
  buttonList: [
    ['undo', 'redo'],
    ['font', 'fontSize'],
    ['fontColor', 'hiliteColor', 'removeFormat'],
    ['paragraphStyle', 'blockquote'],
    [
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript'
    ],
    ['align', 'list', 'lineHeight'],
    ['outdent', 'indent', 'horizontalRule'],

    ['table', 'link', 'image', 'video'],
    // ['imageGallery'], // You must add the 'imageGalleryUrl'.
    ['preview', 'print'],
    ['showBlocks', 'codeView', 'fullScreen'],
    ['dropdown_keyword']
  ],
  font: defaultFonts
});

const SunREInput = ({
  expertMode,
  label,
  height,
  width,
  content,
  placeholder,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!label && <Typography className={classes.label}>{label}</Typography>}
      {expertMode && (
        <JoditEditor
          value={content}
          config={JoditConfigs}
          onChange={(text) => onChange && onChange(text)}
        />
      )}
      {!expertMode
        && (
          <SunEditor
            height={height || 200}
            width={width}
            setContents={content || ''}
            placeholder={placeholder}
            setOptions={SunEditorOptions}
            onChange={(text) => onChange && onChange(text)}
          />
        )}
    </div>
  );
};
SunREInput.propTypes = {
  expertMode: PropTypes.bool,
  label: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
export default SunREInput;
