import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import colors from 'src/common/colors';
import ColorSection from 'src/components/ColorSection';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import * as Yup from 'yup';
import InputSC from 'src/views/menu/InputSC';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    padding: 30,
  },
  logo: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 35,
    paddingLeft: 80,
    paddingRight: 80,
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: 21,
    fontWeight: '600',
  },
  boxButton: {
    marginTop: 30,
  },
  formContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  buttonText: {
    textTransform: 'capitalize',
    fontWeight: 600
  },
  title: {
    marginTop: 20,
  },
  popupContent: {
    padding: 30
  }
}));
const CustomerResetPassword = () => {
  const classes = useStyles();
  const [isViewPopup, setIsViewPopup] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <Typography align="center" className={classes.welcomeText}>
                Welcome to
              </Typography>
              <img alt="" src="/static/images/treadmeal_logo.png" className={classes.logo} />
              <Typography align="center" className={classes.title}>
                Please input your new password.
              </Typography>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().max(16).required('Password is required'),
                  confirmPassword: Yup.string().max(16).required('Confirm password is required'),
                })}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  if (values.password !== values.confirmPassword) {
                    actions.setErrors({ confirmPassword: 'Password mismatch!' });
                    return;
                  }
                  APIService.confirmResetPassword(token, values.password, (success, json) => {
                    if (success && json.result) {
                      setIsViewPopup(true);
                      dispatch(notiActions.viewNoti({ type: 'success', content: 'Reset password successfully!' }));
                    } else {
                      dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
                    }
                  });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form className={classes.formContent} onSubmit={handleSubmit}>
                    {/* <RoundInput
                      disabled
                      className={classes.input}
                      placeholder={token}
                      type="email"
                    /> */}
                    <InputSC
                      label="Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      helperText={errors.password}
                    />
                    <InputSC
                      className={classes.input}
                      label="Confirm Password"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={errors.confirmPassword}
                    />
                    <Box className={classes.boxButton}>
                      <RoundButton type="submit" disabled={isSubmitting}>
                        <Typography className={classes.buttonText}>
                          Reset Password
                        </Typography>
                      </RoundButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={isViewPopup}>
          <DialogTitle>
            Your password has been changed successfully
          </DialogTitle>
          <DialogContent className={classes.popupContent}>
            <RoundButton type="submit" onClick={() => { navigate('/'); }}>
              <Typography className={classes.buttonText}>
                Go to Home
              </Typography>
            </RoundButton>
          </DialogContent>
        </Dialog>
      </Container>
    </ColorSection>
  );
};

export default CustomerResetPassword;
