import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  container: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 12,
    border: `1px solid ${colors.holoBlack}`,
    backgroundColor: colors.white,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  check: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.tmGreen,
  }
}));

const RoundRadio = ({ isSelected, onClick }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.container} onClick={onClick}>
        {isSelected && <Box className={classes.check} />}
      </Box>
    </Box>
  );
};

RoundRadio.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
export default RoundRadio;
