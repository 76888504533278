import { makeStyles } from '@material-ui/core';
import React from 'react';
import ContactUs from '../components/ContactUs';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '60vh'
  }
}));

const ContactUsPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ContactUs useCard className={classes.container} />
    </div>
  );
};

export default ContactUsPage;
