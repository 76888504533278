import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  colors,
  Drawer,
  Grid,
  IconButton,
  List,
  makeStyles,
  TextareaAutosize,
} from '@material-ui/core';
import {
  CancelOutlined,
  CheckCircleOutlined,
  RestaurantMenuOutlined
} from '@material-ui/icons';
import clsx from 'clsx';
import APIService from 'src/utils/APIService';
import { ApplicationStatus } from 'src/utils/Settings';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import Profile from './Profile';
import MenuPhotos from './MenuPhotos';
import OperationHours from './OperationHours';
import PaymentCard from './PaymentCard';
import IconTextButton from './IconTextButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.dark,
  },
}));

const ApplicationForm = ({
  className,
  data,
  onClose,
  onFetch,
  ...rest
}) => {
  const [viewButton, setViewButton] = useState(true);
  const [extraStepForDenial, setExtraStepForDeninal] = useState(false);
  const [extraContent, setExtraContent] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { dataStore } = rest;
  const images = data?.images || [];
  const openingHours = data?.openingHours || [];
  const [status, setStatus] = useState(data?.status || ApplicationStatus.WAITING);

  const onEditMenu = () => {
    dataStore.setApplication(data);
    dataStore.setUser(data.user);
    dataStore.showNotif(false);
    if (onClose) onClose();
    navigate(`/app/profile/${data.user.id}`);
  };

  const onApprove = () => {
    setViewButton(false);
    APIService.processApplicationStatus(data.id, ApplicationStatus.APPROVED, null,
      (success, json) => {
        if (success && json.result) {
          setStatus(ApplicationStatus.APPROVED);
          if (onFetch) onFetch();
          dispatch(notiActions.viewNoti({ type: 'success', content: 'Approved application successfully!' }));
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      });
  };
  const onDeny = () => {
    // setViewButton(false);
    APIService.processApplicationStatus(data.id, ApplicationStatus.DENIED, extraContent,
      (success, json) => {
        if (success && json.result) {
          if (onFetch) onFetch();
          setStatus(ApplicationStatus.DENIED);
          setExtraStepForDeninal(false);
          setExtraContent('');
          dispatch(notiActions.viewNoti({ type: 'success', content: 'Denied application successfully!' }));
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      });
  };

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Card style={{ minHeight: 60 }}>
        <CardHeader
          title={extraStepForDenial ? 'Denial email content' : 'Application Overview'}
          action={!extraStepForDenial && openingHours.length > 0 && images.length > 0 && (
            <IconButton color="secondary" aria-label="menu" onClick={onEditMenu}>
              <RestaurantMenuOutlined />
            </IconButton>
          )}
        />
      </Card>
      <List className={clsx(classes.root, className)}>
        {!extraStepForDenial ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Profile data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <PaymentCard data={data} />
            </Grid>
            {openingHours.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <OperationHours useCard data={openingHours} />
              </Grid>
            )}
            {images.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <MenuPhotos images={images} />
              </Grid>
            )}
            {(status === ApplicationStatus.WAITING
            || status === ApplicationStatus.PENDING) && viewButton && (
              <Box
                display="flex"
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
              >
                <IconTextButton label="Approve" onClick={onApprove}>
                  <CheckCircleOutlined style={{ color: colors.green[600] }} />
                </IconTextButton>
                <Box ml={5} />
                <IconTextButton label="Deny" onClick={() => { setExtraStepForDeninal(true); }}>
                  <CancelOutlined style={{ color: colors.red[500] }} />
                </IconTextButton>
              </Box>
            )}
            {status === ApplicationStatus.DENIED && viewButton && (
              <Box
                display="flex"
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
              >
                <IconTextButton label="Re-Approve" onClick={onApprove}>
                  <CheckCircleOutlined style={{ color: colors.green[600] }} />
                </IconTextButton>
              </Box>
            )}

          </Grid>
        )
          : (
            <Grid container spacing={2}>
              <TextareaAutosize
                style={{ width: '100%', margin: 20, fontFamily: 'Arial' }}
                value={extraContent}
                onChange={(e) => { setExtraContent(e.target.value); console.log(e.target.value); }}
                maxRows={20}
                rowsMin={10}
                aria-label="maximum height"
                placeholder="Email content"
                defaultValue=""
              />
              {viewButton && (
              <Box
                display="flex"
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
              >
                <IconTextButton label="Deny" onClick={onDeny}>
                  <CheckCircleOutlined style={{ color: colors.red[500] }} />
                </IconTextButton>
                <IconTextButton label="Cancel" onClick={() => { setExtraStepForDeninal(false); }}>
                  <CancelOutlined style={{ color: colors.grey[600] }} />
                </IconTextButton>
                <Box ml={5} />
              </Box>
              )}
            </Grid>
          )}
      </List>
    </Drawer>
  );
};

ApplicationForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onFetch: PropTypes.func
};

export default inject('dataStore')(observer(ApplicationForm));
