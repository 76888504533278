const colors = {
  headingTeal: '#08605F',
  white: '#fff',
  black: '#000',
  transparent: 'transparent',
  primary: '#2a2728',
  green: '#5eab27',
  grassGreen: '#6ad62c',
  holoBlack: '#4a4a4a',
  lightGrey: '#D8D8D8',
  action: '#231f20',
  inaction: '#dedede',
  orange: '#ffb600',
  skin: '#F4F6E9',
  tmGreen: '#30bc10',
  lightGreen: '#39E214',
  grey: '#959691',
  darkGrey: '#4B4B4B',
  footerLink: '#6A789B',
  darkBlue: '#08605F',
  inputGrey: '#FAFBFB',
  inputGreyBorder: 'rbga(0,0,0,0.2)',
  red: '#f80000',
  blue: '#1e88e5',
};

export default colors;
