import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Thumb from 'src/views/widgets/Thumb';

const placeholder = '/static/images/upload_camera.svg';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  thumb: ({ square }) => ({
    position: 'absolute',
    top: !square && 4,
    left: !square && 4,
    width: !square && 80,
    height: !square && 80,
    borderRadius: square ? 10 : 40,
    overflow: 'hidden',
  }),
  input: {
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'block',
    width: 88,
    height: 88,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  helpText: {
    padding: '5px 15px',
    fontSize: 12,
  },
  errorInput: {
    borderColor: 'red'
  },
  errorHelpText: {
    color: 'red'
  }
}));

const RoundUpload = ({
  className,
  error,
  helperText,
  id,
  value,
  bg,
  square,
  ...other
}) => {
  const classes = useStyles({ bg, square, value });
  return (
    <Box className={clsx(classes.root, className)}>
      <label
        htmlFor={id}
        className={classes.input}
        style={{ background: !value ? `url(${bg ?? placeholder})` : 'none' }}
      >
        {value && <Thumb className={classes.thumb} width={88} height={88} image={value} />}
        <input
          id={id}
          type="file"
          {...other}
          style={{ display: 'none' }}
        />
      </label>
      {
        helperText
        && (
          <Typography className={clsx(classes.helpText, { [classes.errorHelpText]: error })}>
            {helperText}
          </Typography>
        )
      }
    </Box>
  );
};

RoundUpload.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.object,
  bg: PropTypes.string,
  square: PropTypes.bool,
};

export default RoundUpload;
