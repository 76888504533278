import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  colors,
  makeStyles,
  Typography
} from '@material-ui/core';
import { CancelOutlined, CheckCircleOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {},
}));

const PaymentMethod = ({
  className, name, enabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {enabled
        ? <CheckCircleOutlined color="primary" style={{ color: colors.green[500] }} />
        : <CancelOutlined color="disabled" style={{ color: colors.red[500] }} />}
      <Box width={10} />
      <Typography>{name}</Typography>
    </Box>
  );
};

PaymentMethod.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  enabled: PropTypes.bool,
};

export default PaymentMethod;
