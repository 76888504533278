import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Toolbar = ({
  className,
  filterBy,
  onAdd,
  onDelete,
  onSearch,
  onFilter,
  addText,
  ...rest
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      justify="space-between"
      container
      spacing={24}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search..."
              variant="outlined"
              onChange={(event) => onSearch && onSearch(event.target.value)}
            />
          </Grid>
          {filterBy && filterBy.map((filt) => (
            <Grid item>
              <FormControl size="small" style={{ width: 200, marginLeft: 10 }}>
                <InputLabel style={{ marginTop: -8, marginLeft: 15 }}>{filt.key.name}</InputLabel>
                <Select
                  onChange={(event) => {
                    setFilters({ ...filters, [filt.key.value]: event.target.value });
                    onFilter({ ...filters, [filt.key.value]: event.target.value });
                  }}
                  label={filt.key.name}
                  variant="outlined"
                >
                  <MenuItem value={null}>All</MenuItem>
                  {filt.options && filt.options.map((opt) => (
                    <MenuItem value={opt.value}>{opt.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        {onDelete
          && (
            <Button
              style={{
                color: '#fff',
                marginRight: 20,
                backgroundColor: '#f00'
              }}
              variant="contained"
              onClick={() => onDelete && onDelete()}
            >
              Delete
            </Button>
          )}
        {onAdd
          && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onAdd && onAdd()}
            >
              {addText ?? 'Add Item'}
            </Button>
          )}
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onFilter: PropTypes.func,
  addText: PropTypes.string,
  filterBy: PropTypes.array
};

export default Toolbar;
