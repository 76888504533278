import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Container,
  Grid,
  InputBase,
  Box,
} from '@material-ui/core';
import ColorSection from 'src/components/ColorSection';
import colors from 'src/common/colors';
import Settings from 'src/utils/Settings';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    padding: 30,
  },
  title: {
    color: colors.darkGrey,
    fontSize: 'calc(3vw + 3vmin)',
    [theme.breakpoints.up('lg')]: {
      fontSize: 70,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
  },
  desc: {
    fontSize: 18,
    marginTop: 20,
    color: colors.grey,
    marginBottom: 50,
    lineHeight: '22px'
  },
  image: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  storeImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 162,
    padding: 20,
    cursor: 'pointer'
  },
  input: {
    paddingLeft: 60,
    paddingRight: 40,
    backgroundColor: 'white',
    borderRadius: 50,
    width: '100%',
    height: 60,
    boxShadow: '0px 20px 24px rgba(93,105,29,0.16)',
  },
  iconForward: {
    position: 'absolute',
    width: 51,
    height: 51,
    top: 5,
    right: 10,
  },
  iconMarker: {
    position: 'absolute',
    width: 28,
    height: 28,
    top: 15,
    left: 18,
  }
}));

const Filter = (props) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const [auth, setAuth] = useState({ role: '', sessionKey: '' });
  const dispatch = useDispatch();
  useEffect(() => {
    setAuth({ role: Settings.role(), sessionKey: Settings.sessionKey() });
  }, []);
  const navigate = useNavigate();

  const onSubmit = () => {
    if (auth.role === 'CUSTOMER' && auth.sessionKey) navigate(`/map?keyword=${keyword}`);
    else {
      dispatch(notiActions.viewNoti({ type: 'error', content: 'You need to login with a customer account to use this service' }));
    }
  };

  return (
    <ColorSection color={colors.skin} {...props} className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={6} style={{ padding: 40, paddingTop: 80 }}>
            <Typography className={classes.title}>
              Putting the food truck industry on the map
            </Typography>
            <Typography className={classes.desc}>
              From sushi to burger and fries, whatever you crave,
              find a Food Truck near you.
            </Typography>
            <Box style={{ position: 'relative' }}>
              <InputBase
                id="outlined-basic"
                className={classes.input}
                placeholder="Find a truck near you"
                value={keyword}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onSubmit();
                  }
                }}
                onChange={(e) => {
                  if (!(auth.role && auth.role !== '')) {
                    dispatch(notiActions.viewNoti({ type: 'error', content: 'You need to login with a customer account to use this service' }));
                  }
                  setKeyword(e.target.value);
                }}
              />
              <img alt="" src="/static/images/icon_rounded_marker.png" className={classes.iconMarker} />
              <Box
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                <img alt="" src="/static/images/icon_rounded_forward.png" className={classes.iconForward} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <img alt="" src="/static/images/food_truck.png" className={classes.image} />
            <Grid container spacing={4} justifyContent="center" alignItems="center">
              <Grid item xs={6} sm={6} md={6}>
                <Box onClick={() => { window.location.href = Settings.getPlaystoreURL(); }}>
                  <img alt="" src="/static/images/google_play.png" className={classes.storeImage} />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Box onClick={() => { window.location.href = Settings.getAppstoreURL(); }}>
                  <img alt="" src="/static/images/apple_store.png" className={classes.storeImage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ColorSection>

  );
};

export default Filter;
