import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import InputSC from 'src/views/menu/InputSC';

const useStyles = makeStyles(() => ({
  formContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  submit: {
    marginTop: 30,
    alignSelf: 'center',
  },
  submitLabel: {
    fontWeight: '600',
    textTransform: 'none',
  },
}));

const SignUpBasicInfo = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
      })}
      onSubmit={(values, actions) => {
        const { email, firstName, lastName } = values;
        APIService.checkEmailAvailability(email, (success, json) => {
          if (success && json.result) {
            if (onSubmit) onSubmit({ email, firstName, lastName });
          } else {
            actions.setErrors({ email: json.error || 'This email is unavailable' });
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form className={classes.formContent} onSubmit={handleSubmit}>
          <InputSC
            className={classes.input}
            label="First Name"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="string"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={errors.firstName}
          />
          <InputSC
            className={classes.input}
            label="Last Name"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="string"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={errors.lastName}
          />
          <InputSC
            className={classes.input}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={errors.email}
          />
          <RoundButton className={classes.submit} type="submit">
            <Typography className={classes.submitLabel}>Continue</Typography>
          </RoundButton>
        </form>
      )}
    </Formik>
  );
};

SignUpBasicInfo.propTypes = {
  onSubmit: PropTypes.func,
};

export default SignUpBasicInfo;
