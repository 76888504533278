import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
  Link
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import ColorSection from 'src/components/ColorSection';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import InputSC from 'src/views/menu/InputSC';
import * as Yup from 'yup';
import clsx from 'clsx';
import RoundRadio from '../components/RoundRadio';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh'
  },
  logo: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 35,
    paddingLeft: 80,
    paddingRight: 80,
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  buttonText: {
    textTransform: 'capitalize',
    fontWeight: 600,
    color: 'white'
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: 21,
    fontWeight: '600',
  },
  boxButton: {
    marginTop: 30,
  },
  formContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    marginTop: 20,
  },
  row: {
    display: 'flex',
  },
  agree2Terms: {
    paddingTop: 20,
  },
  twoTerms: {
    fontSize: 14
  },
  link: {
    fontWeight: '600',
  },
  errorText: {
    color: 'red',
    fontSize: 13,
    marginTop: 5
  }
}));
const CustomerSignUp = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Card className={classes.card}>
            <Typography align="center" className={classes.welcomeText}>
              Welcome to
            </Typography>
            <img alt="" src="/static/images/treadmeal_logo.png" className={classes.logo} />
            <Typography align="center" className={classes.title}>
              Please create your account information.
            </Typography>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                agree2Terms: false
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required('First name is required'),
                lastName: Yup.string().required('Last name is required'),
                email: Yup.string().email('Invalid email format').required('Email is required'),
                password: Yup.string().max(16).required('Password is required'),
                confirmPassword: Yup.string().max(16).required('Confirm password is required'),
              })}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                if (values.password !== values.confirmPassword) {
                  actions.setErrors({ confirmPassword: 'Password mismatch!' });
                } else if (!values.agree2Terms) {
                  actions.setErrors({ agree2Terms: 'You must agree to our terms and privacy policies!' });
                } else {
                  APIService.signUpCustomer(
                    values.firstName,
                    values.lastName,
                    values.email.toLowerCase(),
                    values.password,
                    (success, json) => {
                      if (success && json.result) {
                        actions.resetForm();
                        navigate('/signup-thankyou');
                      } else {
                        actions.setErrors({ email: json.error || 'Registration failed!' });
                      }
                    }
                  );
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values
              }) => (
                <form className={classes.formContent} onSubmit={handleSubmit}>
                  <InputSC
                    className={classes.input}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="string"
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={errors.firstName}
                  />
                  <InputSC
                    className={classes.input}
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="string"
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={errors.lastName}
                  />
                  <InputSC
                    className={classes.input}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={errors.email}
                  />
                  <InputSC
                    className={classes.input}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    helperText={errors.password}
                  />
                  <InputSC
                    className={classes.input}
                    label="Confirm password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={errors.confirmPassword}
                  />
                  <Box className={clsx(classes.row, classes.agree2Terms)}>
                    <RoundRadio
                      isSelected={values.agree2Terms}
                      onClick={() => setFieldValue('agree2Terms', !values.agree2Terms)}
                    />
                    <Typography className={classes.twoTerms}>
                      By checking this box  you are agreeing to our
                      <Link className={classes.link} href="/terms" target="_blank"> Terms and Conditions </Link>
                      and
                      <Link className={classes.link} href="/privacy" target="_blank"> Privacy Policy.</Link>
                    </Typography>
                  </Box>
                  {errors.agree2Terms && (
                  <Typography className={classes.errorText}>
                    {errors.agree2Terms}
                  </Typography>
                  )}
                  <Box className={classes.boxButton}>
                    <RoundButton type="submit" disabled={isSubmitting}>
                      <Typography className={classes.buttonText}>
                        Sign Up
                      </Typography>
                    </RoundButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default CustomerSignUp;
