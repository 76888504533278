import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Box, makeStyles, Typography
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import colors from 'src/common/colors';
import Settings from 'src/utils/Settings';

const useStyles = makeStyles(() => ({
  name: {
    display: 'flex',
    flexGrow: 1,
  },
  price: {
    marginLeft: 8,
  },
  desc: {
    width: '100%',
    color: colors.holoBlack,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#eee'
    },
    borderRadius: 15,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  button: {
    '&:hover': {
      backgroundColor: '#ff6666',
    },
  }
}));

const Food = ({
  name,
  price,
  desc,
  onUpdate,
  onDelete
}) => {
  const [role, setRole] = useState('');
  useEffect(() => {
    setRole(Settings.role());
  }, []);
  const classes = useStyles();
  return (
    <Box m={1} ml={1} className={classes.container}>
      <Box className={classes.titleWrapper} onClick={onUpdate}>
        <Box display="flex" width="100%" flexDirection="row">
          <Typography className={classes.name} variant="h5">{name || ''}</Typography>
          <Typography className={classes.price} variant="h5">{price ? `$${Number(price).toFixed(2)}` : ''}</Typography>
        </Box>
        <Typography className={classes.desc}>{desc || ''}</Typography>
      </Box>
      {role === 'ADMIN' && (
      <IconButton onClick={onDelete} className={classes.button}>
        <Delete color="inherit" />
      </IconButton>
      )}
    </Box>
  );
};

Food.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  desc: PropTypes.string,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Food;
