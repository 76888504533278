import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/AndroidOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const PlatformStats = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const platforms = data && data.platformStats ? data.platformStats : [];
  const stats = platforms.length !== 0 ? platforms.map((e) => e.count) : [];

  const colorSet = [
    colors.blue[500],
    colors.green[600],
    colors.orange[600]
  ];
  const percents = {
    datasets: [
      {
        data: stats,
        backgroundColor: colorSet,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: platforms.map((e) => e.platform)
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [];
  if (platforms.length !== 0) {
    let total = 0;
    platforms.forEach((e) => { total += e.count; });

    platforms.forEach((e, index) => {
      let percent = e.count * 100;
      percent /= total;
      devices.push({
        title: e.platform,
        value: percent.toFixed(1),
        icon: e.platform === 'IOS' ? AppleIcon : AndroidIcon,
        color: colorSet[index],
      });
    });
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Platform Stats" />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={percents}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h4"
              >
                {`${value}%`}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

PlatformStats.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default PlatformStats;
