import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@material-ui/core';
import Logo from 'src/components/Logo';
import LogoTreadMeal from 'src/components/LogoTreadMeal';

const HeaderLogo = ({ isAuth }) => {
  return (
    <Box>
      {isAuth ? (
        <LogoTreadMeal />
      )
        : (
          <Box>
            <Hidden mdDown>
              <LogoTreadMeal />
            </Hidden>
            <Hidden lgUp smDown>
              <Logo />
            </Hidden>
            <Hidden mdUp>
              <LogoTreadMeal />
            </Hidden>
          </Box>
        )}
    </Box>
  );
};

HeaderLogo.propTypes = {
  isAuth: PropTypes.bool,
};

export default HeaderLogo;
