import {
  Box,
  Container,
  Grid,
  makeStyles,
  Link,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import colors from 'src/common/colors';
import Logo from 'src/components/Logo';
import Settings from 'src/utils/Settings';
import Thumb from 'src/views/widgets/Thumb';

const navList = [
  [
    {
      title: 'Company',
    },
    {
      title: 'Contact',
      href: '/contact-us'
    },
    {
      title: 'Terms',
      href: '/terms'
    },
    {
      title: 'Privacy',
      href: '/privacy'
    },
  ],
  [
    {
      title: 'Products',
    },
    {
      title: 'FAQs',
      href: '/faqs'
    },
    {
      title: 'Features',
      href: '/how-it-works'
    },
  ],
  [
    {
      title: 'Social',
    },
    {
      title: 'Facebook',
      href: 'https://www.facebook.com/Treadmealapp'
    },
    {
      title: 'Instagram',
      href: 'https://instagram.com/treadmealapp'
    },
    {
      title: 'Twitter',
      href: 'https://twitter.com/treadmealco'
    },
  ],
];

const whiteBackgroundPages = [
  '/pricing',
  '/how-it-works',
  '/contact-us',
  '/home',
  '/404',
  '/application-done',
  '/sign-up/truck-owner',
  '/application/operation/:token',
  '/signup-thankyou',
  '/sign-up/customer',
  '/customer/:token',
  '/reset-password/:token',
  '/users/change-email/:token',
  '/users/verify/:token',
];

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 40,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
    paddingTop: 40,
  },
  linkText: {
    color: colors.footerLink,
    '&:hover': {
      color: colors.tmGreen,
      textDecoration: 'none',
    }
  },
}));

const Footer = ({ backgroundColor }) => {
  const [background, setBackground] = useState('white');
  const classes = useStyles();
  const location = useLocation();
  const { token } = useParams();

  const isWhiteBackground = (pathname) => {
    return whiteBackgroundPages.find((item) => {
      if (item === pathname || item.replace(':token', token) === pathname) return true;
      return false;
    });
  };

  useEffect(() => {
    const signedInHome = Settings.sessionKey() && ['/', '/#'].includes(location.pathname);
    if (signedInHome) {
      setBackground(colors.white);
    } else if (backgroundColor || !isWhiteBackground(location.pathname)) {
      setBackground(colors.skin);
    } else {
      setBackground(colors.white);
    }
  }, [location.pathname]);

  return (
    <Box
      className={classes.root}
      style={{ backgroundColor: background }}
    >
      <Container
        maxWidth="lg"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className={classes.logo}
          >
            <Box
              width="30vw"
              height="30vw"
              maxWidth="226px"
              maxHeight="226px"
            >
              <Logo height="100%" width="100%" />
            </Box>

          </Grid>
          {navList.map((column) => (
            <Grid
              key={column[0].title}
              item
              xs={4}
              sm={4}
              md={3}
              className={classes.column}
            >
              {column.map((item) => (
                !item.href
                  ? (
                    <Typography
                      key={item.title}
                      style={{ fontSize: 14, color: '#6a789b' }}
                    >
                      {item.title}
                    </Typography>
                  )
                  : (
                    <Link
                      key={item.title}
                      href={item.href}
                      className={classes.linkText}
                    >
                      <Typography
                        style={{ fontSize: 14, color: 'rgba(106, 120, 155, 0.6)', marginTop: 6 }}
                      >
                        {item.title}
                      </Typography>
                    </Link>
                  )
              ))}
            </Grid>
          ))}
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" pt={5} pb={2}>
          <Thumb
            uri="/static/images/treadmeal_logo.png"
            height={15}
            width="auto"
          />
          <Typography style={{ fontSize: 11, color: 'rgba(106, 120, 155, 0.5)' }}>
            All rights reserved. 2021.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
};

export default Footer;
