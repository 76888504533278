import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import {
  Link as LinkIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  searchText,
  onSelected,
  selectedIds,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedPromotionIds;

    if (event.target.checked) {
      newSelectedPromotionIds = data.map((promo) => promo.id);
    } else {
      newSelectedPromotionIds = [];
    }
    onSelected(newSelectedPromotionIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedPromotionIds = [];

    if (selectedIndex === -1) {
      newSelectedPromotionIds = newSelectedPromotionIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPromotionIds = newSelectedPromotionIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedPromotionIds = newSelectedPromotionIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPromotionIds = newSelectedPromotionIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    onSelected(newSelectedPromotionIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((promo) => {
    const date = moment(promo.created_at).format('DD/MM/YYYY HH:mm');

    return promo.name.toLowerCase().indexOf(search) >= 0
      || date.toLowerCase().indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === data.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Price
                </TableCell>
                <TableCell>
                  URL
                </TableCell>
                <TableCell>
                  Created At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((promo) => {
                const date = moment(promo.created_at).format('DD/MM/YYYY HH:mm');
                return (
                  <TableRow
                    hover
                    key={promo.id}
                    selected={selectedIds.indexOf(promo.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.indexOf(promo.id) !== -1}
                        onChange={(event) => handleSelectOne(event, promo.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={promo.image}
                        >
                          {getInitials(promo.name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {promo.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {promo.price}
                    </TableCell>
                    <TableCell>
                      <Link href={promo.url}>
                        <LinkIcon />
                      </Link>
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
};

export default Results;
