import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  colors as uColors,
  Card,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import APIService from 'src/utils/APIService';
import ColorSection from 'src/components/ColorSection';
import { useLocation } from 'react-router-dom';
import colors from 'src/common/colors';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CountryCodes from '../../../common/country-codes';

const Countries = CountryCodes.sort((a, b) => a.name.localeCompare(b.name)).map((e) => e.name);

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '30px 0 80px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0 40px 0',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 56,
  },
  receivedText: {
    textAlign: 'center',
    fontSize: 'calc(3vw + 3vmin)',
    fontWeight: 'bolder',
    color: colors.lightGreen,
    [theme.breakpoints.up('lg')]: {
      fontSize: 70,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
  },
  thankYouText: {
    textAlign: 'center',
    fontSize: 'calc(3vw + 3vmin)',
    fontWeight: 'bolder',
    color: 'black',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 80,
    [theme.breakpoints.up('lg')]: {
      fontSize: 70,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
  },
  button: {
    width: 155,
    height: 48,
    borderRadius: 8,
    backgroundColor: '#39e214',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#39e214',
    }
  },
  cssOutlinedInput: {
    height: 56,
    '&$cssFocused $notchedOutline': {
      borderColor: `${colors.grassGreen} !important`,
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${colors.grassGreen} !important`
  },

  select: {
    height: 56,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.grassGreen} !important`,
    }
  },

  label: {
    color: colors.darkBlue,
    fontWeight: 'bold',
  },
  message: {
    height: 137,
    display: 'flex',
    flexDirection: 'column',
  },
  agreement: {
    color: colors.darkBlue,
  },
  submitButton: {
    width: 155,
    height: 48,
    borderRadius: 8,
    backgroundColor: colors.grassGreen,
    fontWeight: 'bold'
  },
  submitText: {
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  error: {
    marginTop: 3,
    paddingLeft: 15,
    fontSize: 12,
    color: uColors.red[500],
  },
  card: {
    '@media only screen and (max-width: 550px)': {
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,

    }
  }
}));

const subtitle = `
Have a Food Truck you’d like to request?
Have an area you’d love to see us in?
Have a question or something else? Let us know!`;

const ContactUsForm = ({ classes, isSubmitted, setIsSubmitted }) => {
  return (
    <Box style={{ backgroundColor: 'white', borderRadius: 5 }}>
      {isSubmitted && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          direction="column"
          justify="space-evenly"
        >
          <Typography className={classes.receivedText}>
            Received!
          </Typography>
          <Typography className={classes.thankYouText}>
            Thank you for your feedback!
          </Typography>
        </Grid>
      )}

      {!isSubmitted && (
        <Formik
          initialValues={{
            email: '',
            country: '',
            name: '',
            businessName: '',
            message: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('Email is required'),
            country: Yup.string().required('Country is required'),
            name: Yup.string().required('Name is required'),
            message: Yup.string().required('Message is required'),
          })}
          onSubmit={(values, actions) => {
            APIService.sendContactMessage(
              values.email,
              values.name,
              values.businessName,
              values.country,
              values.message,
              (success, json) => {
                actions.setSubmitting(false);
                setIsSubmitted(true);
                if (success && json.result) {
                  actions.setStatus('Success');
                  actions.resetForm();
                } else {
                  actions.setErrors({ error: 'Failed to send message!' });
                }
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography className={classes.label}>Your name*</Typography>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    placeholder="Julia William"
                    fullWidth
                    margin="dense"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography className={classes.label}>Contact email*</Typography>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="you@example.com"
                    fullWidth
                    margin="dense"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                    style={{ borderColor: colors.primary }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography className={classes.label}>Company name</Typography>
                  <TextField
                    error={Boolean(touched.businessName && errors.businessName)}
                    helperText={touched.businessName && errors.businessName}
                    placeholder="Company name"
                    fullWidth
                    margin="dense"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="integer"
                    value={values.businessName}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography style={{ lineHeight: 2 }} className={classes.label}>
                    Country*
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Countries}
                    value={values.country}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option}
                    onChange={(e, newInputValue) => { setFieldValue('country', newInputValue ?? ''); }}
                    renderOption={(item) => <Typography key={item}>{`${item}`}</Typography>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        id="demo-simple-select"
                        error={Boolean(touched.country && errors.country)}
                        className={classes.select}
                      />
                    )}
                  />
                  <Typography className={classes.error}>
                    {touched.country && errors.country}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={classes.label}>Your message*</Typography>
                  <TextField
                    className={classes.message}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    placeholder="Type your message..."
                    fullWidth
                    multiline
                    margin="dense"
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.message}
                    variant="outlined"
                    /* eslint-disable react/jsx-no-duplicate-props */
                    inputProps={{ maxLength: 500 }}
                    InputProps={{
                      classes: {
                        root: clsx(classes.message, classes.cssOutlinedInput),
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={classes.agreement}>
                    By submitting this form you agree to our Terms and Conditions
                    and our Privacy Policy which explains how we may collect,
                    use and disclose your personal information including to third parties.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    color="default"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}

                  >
                    <Typography className={classes.submitText}>Contact Us</Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

ContactUsForm.propTypes = {
  classes: PropTypes.any,
  isSubmitted: PropTypes.bool,
  setIsSubmitted: PropTypes.func
};

const ContactUs = (props) => {
  const [background, setBackground] = useState('white');
  const { useCard } = props;
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/contact-us') {
      setBackground(colors.skin);
    } else {
      setBackground('white');
    }
  }, [location.pathname]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <ColorSection title="Contact Us" subtitle={subtitle} color={background} {...props}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_reCAPTCHA_KEY}
        scriptProps={{
          async: true,
        }}
      >
        <Container maxWidth="md">
          {useCard ? (
            <Card>
              <Box pt={4} px={8} className={classes.card}>
                <ContactUsForm
                  classes={classes}
                  isSubmitted={isSubmitted}
                  setIsSubmitted={setIsSubmitted}
                />
              </Box>
            </Card>
          ) : (
            <ContactUsForm
              classes={classes}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
            />
          )}
        </Container>
      </GoogleReCaptchaProvider>
    </ColorSection>
  );
};

ContactUs.propTypes = {
  useCard: PropTypes.bool,
};

export default ContactUs;
