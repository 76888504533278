import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import Users from 'src/views/users';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import Promotions from './views/promotion';
import DynamicContents from './views/dynamic-contents';
import Viewer from './views/dynamic-contents/Viewer';
import PrivacySetting from './views/settings/privacy';
import TermsAndConditionsSetting from './views/settings/terms-and-conditions';
import FAQs from './views/faq';
import Localizations from './views/localization';
import Translations from './views/multilanguage';
import Templates from './views/templates';
import CacheMonitor from './views/cache-monitor';
import Logs from './views/logs';
import MenuGroups from './views/menu-group';
import Cuisines from './views/cuisine';
import Applications from './views/application';
import Profile from './views/profile';
import MenuFoods from './views/menu-food';
import AgentLayout from './layouts/AgentLayout';
import CustomerHome from './views/customer/pages/CustomerHome';
import CustomerLayout from './layouts/CustomerLayout';
import CustomerSignUp from './views/customer/pages/CustomerSignUp';
import CustomerTruckList from './views/customer/pages/CustomerTruckList';
import Filter from './views/customer/components/Filter';
import HowItWorks from './views/customer/components/HowItWorks';
import Pricing from './views/customer/components/Pricing';
import ApplicationDonePage from './views/customer/pages/ApplicationDonePage';
import CustomerMap from './views/customer/pages/CustomerMap';
import ContactUsPage from './views/customer/pages/ContactUsPage';
import TruckOwnerApplication from './views/customer/pages/TruckOwnerApplication';
import TruckOwnerSetOperation from './views/customer/pages/TruckOwnerSetOperation';
import CustomerResetPassword from './views/customer/pages/CustomerResetPassword';
import CustomerInitPassword from './views/customer/pages/CustomerInitPassword';
import CustomerEmailVerificationDone from './views/customer/pages/CustomerEmailVerificationDone';
import ChangeEmailDone from './views/customer/pages/ChangeEmailDone';
import SignUpThankYou from './views/customer/pages/SignUpThankYou';
import { TemplateType } from './utils/Settings';
import ForgotPassword from './views/customer/pages/ForgotPassword';
import RouteProxy from './views/proxy';
// import Subscriptions from './views/subscriptions';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'users', element: <Users /> },
      { path: 'profile/:id', element: <Profile /> },
      { path: 'applications', element: <Applications /> },
      // { path: 'subscriptions', element: <Subscriptions /> },
      { path: 'dynamic-contents', element: <DynamicContents /> },
      { path: 'faq', element: <FAQs /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'cuisines', element: <Cuisines /> },
      { path: 'menus/groups', element: <MenuGroups /> },
      { path: 'menus/foods', element: <MenuFoods /> },
      { path: 'template-notif', element: <Templates type={TemplateType.NOTIFICATION} /> },
      { path: 'template-email', element: <Templates type={TemplateType.EMAIL} /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'promotions', element: <Promotions /> },
      { path: 'privacy', element: <PrivacySetting /> },
      { path: 'terms', element: <TermsAndConditionsSetting /> },
      { path: 'regions', element: <Localizations /> },
      { path: 'translations', element: <Translations /> },
      { path: 'logs', element: <Logs /> },
      { path: 'cache', element: <CacheMonitor /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'login', element: <LoginView /> },
    ]
  },
  {
    path: 'agent',
    element: <AgentLayout />,
    children: [
      { path: 'profile', element: <Profile /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <CustomerLayout />,
    children: [
      { path: '/', element: <RouteProxy /> },
      { path: 'home', element: <Filter /> },
      { path: 'how-it-works', element: <HowItWorks /> },
      { path: 'pricing', element: <Pricing /> },
      { path: 'map', element: <CustomerMap /> },
      { path: 'reset-password/:token', element: <CustomerResetPassword /> },
      { path: 'customer/:token', element: <CustomerInitPassword /> },
      { path: 'sign-up/customer', element: <CustomerSignUp /> },
      { path: 'sign-up/truck-owner', element: <TruckOwnerApplication /> },
      { path: 'application/operation/:token', element: <TruckOwnerSetOperation /> },
      { path: 'food-truck', element: <CustomerTruckList /> },
      { path: 'contact-us', element: <ContactUsPage /> },
      { path: 'search', element: <CustomerHome /> },
      { path: 'signup-thankyou', element: <SignUpThankYou /> },
      { path: 'application-done', element: <ApplicationDonePage /> },
      { path: 'users/verify/:token', element: <CustomerEmailVerificationDone /> },
      { path: 'users/change-email/:token', element: <ChangeEmailDone /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'profile', element: <Profile /> },
      { path: '404', element: <NotFoundView /> },
      // { path: 'login', element: <LoginView /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'privacy', element: <Viewer type="privacy" /> },
      { path: 'terms', element: <Viewer type="terms" /> },
      { path: 'faqs', element: <Viewer type="faqs" /> },
      { path: 'dynamic', element: <Viewer type="dynamic" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  },
];

export default routes;
