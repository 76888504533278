import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Settings, { TemplateType } from 'src/utils/Settings';
import APIService from 'src/utils/APIService';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import SiteInfo from './SiteInfo';
import SMTP from './SMTP';
import EmailTemplates from './EmailTemplates';
import NotiTemplates from './NotiTemplates';
import StoreSetting from './StoreSetting';
import StripeSetting from './StripeSetting';
import GAuth2FA from './GAuth2FA';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const location = useLocation();
  const [key, updateKey] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [settings, setSettings] = useState({});
  const dispatch = useDispatch();
  const getSettings = () => APIService.getSettings((success, json) => {
    if (success && json.result) {
      const data = {};
      json.result.forEach((e) => {
        data[e.key] = e;
        if (e.key === 'APP_NAME') {
          Settings.setAppName(e.value);
        } else if (e.key === 'SITE_NAME') {
          Settings.setSiteName(e.value);
        }
      });
      setSettings(data);
      updateKey(moment().unix());
    }
  });

  const fetTemplates = (type) => new Promise((resolve, reject) => {
    APIService.getDynamicContents(type, (success, json) => {
      if (success && json.result) {
        resolve(json.result);
      } else {
        reject(json.error);
      }
    });
  });

  useEffect(() => {
    getSettings();
    Promise.all([
      fetTemplates(TemplateType.EMAIL),
      fetTemplates(TemplateType.NOTIFICATION),
    ]).then((values) => {
      setTemplates(values[0].concat(values[1]));
      updateKey(moment().unix());
    }).catch((error) => {
      console.warn(error);
      dispatch(notiActions.viewNoti({ type: 'error', content: 'error' }));
    });
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <SiteInfo key={`site${key}`} data={settings} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <GAuth2FA data={settings} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <SMTP key={`smtp${key}`} data={settings} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <StoreSetting key={`store${key}`} data={settings} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <StripeSetting key={`stripe${key}`} data={settings} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <NotiTemplates key={`tmplnoti${key}`} data={settings} templates={templates} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <EmailTemplates key={`tmplemail${key}`} data={settings} templates={templates} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsView;
