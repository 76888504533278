import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import {
  Box,
  TextField,
  Typography,
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import RoundButton from 'src/components/RoundButton';

const Confirm2FA = ({ ...rest }) => {
  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().length(6).required('Input 2FA OTP code'),
      })}
      onSubmit={(values, actions) => {
        APIService.verify2FACode(values.code, (success, json) => {
          actions.setSubmitting(false);
          if (success && json.result) {
            if (rest.onSuccess) rest.onSuccess();
          } else {
            actions.setErrors({ code: json.error || 'Invalid OTP' });
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Security
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Enter 2FA Code
            </Typography>
          </Box>
          <TextField
            error={Boolean(touched.code && errors.code)}
            fullWidth
            helperText={touched.code && errors.code}
            label="2FA OTP Code"
            margin="normal"
            name="code"
            onBlur={handleBlur}
            onChange={handleChange}
            type="code"
            value={values.code}
            variant="outlined"
          />
          <Box my={2}>
            <RoundButton
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </RoundButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default inject('dataStore')(observer(Confirm2FA));
