import {
  Box,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  container: {
    width: 19,
    height: 19,
    borderRadius: 20,
    marginRight: 12,
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 4px rgba(0,0,0,0.5)',
    '&:hover': {
      backgroundColor: '#efefef',
    },
    cursor: 'pointer'
  },
  check: {
    color: colors.tmGreen,
    fontSize: 14,
  }
}));

const CustomRadio = ({ isSelected, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} onClick={onClick}>
      {isSelected && <CheckIcon fontSize="small" className={classes.check} />}
    </Box>
  );
};

CustomRadio.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
export default CustomRadio;
