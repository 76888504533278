/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

const ImageView = ({
    images,
    index,
    onConfirm,
}) => {
    return (
        <div>
            <Lightbox
                images={images.map((image) => { return { url: image }; })}
                onClose={onConfirm}
                startIndex={index}
            />
        </div>
    );
};
ImageView.propTypes = {
    onConfirm: PropTypes.func,
    images: PropTypes.array,
    index: PropTypes.number,
};

const ImagesViewer = ({
    onClose, images, index, active,
}) => {
    if (!active) return null;
    return (
        <>
            {ReactDOM.createPortal(
                <ImageView
                    images={images}
                    index={index}
                    onConfirm={onClose}
                />,
                document.getElementById('backdrop-root')
            )}
        </>
    );
};
ImagesViewer.propTypes = {
    onClose: PropTypes.func,
    images: PropTypes.array,
    index: PropTypes.number,
    active: PropTypes.bool,
};

export default ImagesViewer;
