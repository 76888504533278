import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Settings, { ApplicationStatus } from 'src/utils/Settings';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import ApplicationForm from './ApplicationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Applications = () => {
  const classes = useStyles();
  const location = useLocation();
  const [applications, setApplications] = useState([]);
  const [application, setApplication] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();

  const getApplications = () => APIService.getApplications((success, json) => {
    if (success && json.result) {
      setApplications(json.result);
    }
  });

  const getApplicationById = (id) => APIService.getApplicationById(id, (success, json) => {
    if (success && json.result) {
      setApplication(json.result);
    }
  });

  const viewProfile = (user) => {
    Settings.setApplication({});
    Settings.setOtherProfile(user);
    navigate(`/app/profile/${user.id}`);
  };

  useEffect(() => {
    getApplications();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Applications"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          filterBy={[
            {
              key: { name: 'Status', value: 'status' },
              options: [
                { name: 'Waiting', value: ApplicationStatus.WAITING },
                { name: 'Pending', value: ApplicationStatus.PENDING },
                { name: 'Approved', value: ApplicationStatus.APPROVED },
                { name: 'Denied', value: ApplicationStatus.DENIED },
              ]
            },
          ]}
          onFilter={(value) => { setFilter(value); }}
        />
        <Box mt={3}>
          <Results
            data={applications}
            searchText={searchText}
            filter={filter}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
            onEdit={(item) => {
              getApplicationById(item.id);
            }}
            onView={viewProfile}
          />
        </Box>
        {
          application
          && (
            <ApplicationForm
              data={application}
              onClose={() => setApplication(null)}
              onFetch={getApplications}
            />
          )
        }
      </Container>
    </Page>
  );
};

export default Applications;
