import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import colors from 'src/common/colors';
import TextHeading from 'src/components/TextHeading';
import Settings from 'src/utils/Settings';
import APIService from '../../utils/APIService';
import Menu from './Menu';
import MenuEditor from './MenuEditor';

const useStyles = makeStyles(() => ({
  root: {},
  heading: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.headingTeal,
  }
}));

const MenuList = ({
  className, user, editable, ...rest
}) => {
  const classes = useStyles();
  const [add, setAddFlag] = useState(false);
  const [updateMenuGroup, setUpdateMenuGroup] = useState();
  const [groups, setGroups] = useState([]);
  const operationId = user?.operation?.id;

  const getMenu = () => {
    if (operationId) {
      APIService.getMenuGroupsByOperation(operationId, (success, json) => {
        if (success && json.result) {
          const { dataStore } = rest;
          dataStore.setMenuList(json.result);
          setGroups(json.result);
        }
      });
    }
  };

  useEffect(() => {
    getMenu();
  }, [user]);

  const addMenu = () => {
    setAddFlag(true);
  };

  const deleteMenuGroup = (menuGroup) => {
    APIService.deleteMenuGroup(menuGroup.id, (success, json) => {
      if (success && json.result) {
        const { dataStore } = rest;
        dataStore.setMenuList((preGroups) => preGroups.filter((item) => item.id !== menuGroup.id));
        setGroups(dataStore.menu);
      }
    });
  };

  const closeEditor = (menu) => {
    if (menu && menu.id) {
      const { dataStore } = rest;
      if (updateMenuGroup?.id) {
        dataStore.setMenuList(groups.map((group) => {
          if (group.id === menu.id) {
            return { ...group, name: menu.name, description: menu.description };
          }
          return group;
        }));
      } else {
        dataStore.setMenuList(groups.concat([{ ...menu, menuFoods: [] }]));
      }
      setGroups(dataStore.menu);
    }
    setAddFlag(false);
    setUpdateMenuGroup();
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        avatar={<Box width={20} />}
        title={<TextHeading variant="h6" className={classes.heading}>Menu</TextHeading>}
        className={classes.heading}
        action={editable && (
          <IconButton onClick={addMenu}>
            <AddCircleOutline />
          </IconButton>
        )}
      />
      <CardContent>
        {groups && groups.map((e) => (
          <Menu
            key={e.id}
            data={e}
            editable={editable}
            onDelete={() => deleteMenuGroup(e)}
            onUpdate={() => {
              const role = Settings.role();
              if (role === 'ADMIN') {
                setUpdateMenuGroup(e);
                setAddFlag(true);
              }
            }}
          />
        ))}
      </CardContent>
      {add && (
      <MenuEditor
        menuGroup={updateMenuGroup}
        data={user?.operation}
        onClose={closeEditor}
      />
      )}
    </Card>
  );
};

MenuList.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  editable: PropTypes.bool,
};

export default inject('dataStore')(observer(MenuList));
