import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Settings, { Roles } from 'src/utils/Settings';
import RoundButton from 'src/components/RoundButton';
import { inject, observer } from 'mobx-react';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import { getToken } from 'src/firebase';
import Confirm2FA from './Confirm2FA';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '80vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    // backgroundSize: '100% 100%',
    // backgroundRepeat: 'no-repeat',
    // backgroundImage: 'url(/static/images/login.bg.jpg)',
  },
  form: {
    paddingTop: 20,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: 'white',
    // backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
  }
}));

const LoginView = ({ ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [account2FA, setAccount2FA] = useState(null);
  const [enabled2FA, setEnabled2FA] = useState(false);
  const { dataStore } = rest;
  const dispatch = useDispatch();
  const navigateBaseOnRole = (role) => {
    switch (role) {
      case Roles.ADMIN:
        navigate('/app/dashboard', { replace: true });
        break;
      case Roles.TRUCK_OWNER:
        navigate('/agent/profile', { replace: true });
        break;
      default:
        navigate('/', { replace: true });
    }
  };

  const check2FA = () => APIService.check2FA((success, json) => {
    if (success && json.result) {
      setEnabled2FA(true);
    }
  });

  useEffect(() => {
    check2FA();
    const sessionKey = Settings.sessionKey();
    if (sessionKey) {
      const jwt = jwtDecode(sessionKey);
      // const admin = jwt.role && jwt.role === Roles.ADMIN;
      if (jwt) {
        navigateBaseOnRole(jwt.role);
      }
    }
  }, [location.pathname]);

  const handleSetToken = (token) => {
    APIService.pushNotificationToken(token, token, (success, json) => {
      if (success && json.result) {
        console.log('success');
      }
      dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
    });
  };

  const handleSuccessLogin = (result) => {
    Settings.setSessionKey(result.token);
    Settings.setRole(result.role);
    Settings.setProfile({
      ...result
    });
    dataStore.setUser(result);
    dataStore.setRole(result.role);
    navigateBaseOnRole(result.role);
    if (result.role === 'ADMIN') {
      getToken(handleSetToken);
    }
    // location.reload();
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.form}>
          {
            enabled2FA && !!account2FA
              ? <Confirm2FA onSuccess={() => handleSuccessLogin(account2FA)} />
              : (
                <Formik
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                  })}
                  onSubmit={(values, actions) => {
                    APIService.signIn(values.email, values.password, (success, json) => {
                      actions.setSubmitting(false);
                      if (success && json.result) {
                        const { result } = json;
                        if (enabled2FA && result.role === 'ADMIN') {
                          setAccount2FA(result);
                        } else {
                          handleSuccessLogin(result);
                        }
                      } else {
                        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
                      }
                    });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}>
                        <Typography
                          color="textPrimary"
                          variant="h2"
                        >
                          Sign in
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Sign in to dashboard
                        </Typography>
                      </Box>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <RoundButton
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Sign in now
                        </RoundButton>
                      </Box>
                    </form>
                  )}
                </Formik>
              )
          }
        </Container>
      </Box>
    </Page>
  );
};

export default inject('dataStore')(observer(LoginView));
