import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
  Box,
  Avatar,
} from '@material-ui/core';
import StringUtils from 'src/utils/StringUtils';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    width: 40,
    height: 40,
  }
}));

const StatsCard = ({
  className, stats, onFlush, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Key Count
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {stats.keys || ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Key Size
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {StringUtils.convertBytes(stats.ksize)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Hits
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {stats.hits}
            </Typography>
          </Grid>
          {onFlush && (
            <Grid item>
              <Box onClick={() => onFlush()}>
                <Avatar className={classes.avatar}>
                  <DeleteIcon />
                </Avatar>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

StatsCard.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.object,
  onFlush: PropTypes.func,
};

export default StatsCard;
