import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  errorText: {
    fontSize: 12,
    color: '#f00',
  }
});

export default function TextSearch({
  label, size, value, options, error, helperText, onInputChange, onSelect
}) {
  const classes = useStyles();

  return (
    <Box>
      <Autocomplete
        options={options}
        classes={{
          option: classes.option,
        }}
        value={value}
        disableClearable
        clearOnBlur={false}
        onInputChange={onInputChange}
        autoHighlight
        getOptionLabel={(option) => option?.name || ''}
        renderOption={(option) => (
          <>
            {option.name}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            label={label}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(event, val) => {
          if (onSelect) onSelect(val);
        }}
      />
      {error && !!helperText && <Typography className={classes.errorText}>{helperText}</Typography>}
    </Box>
  );
}

TextSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  options: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
};
