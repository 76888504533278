import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Drawer, Grid, makeStyles, Avatar,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UploadButton from 'src/components/UploadButton';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import Thumb from '../widgets/Thumb';
import InputSC from '../menu/InputSC';

const useStyle = makeStyles((theme) => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 30,
  },
  thumb: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    width: 88,
    height: 88,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  dash: {
    padding: 10,
    borderRadius: 55,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
  }
}));

const EditProfileBasic = ({
  profile, open, onClose, ...rest
}) => {
  const classes = useStyle();

  const { dataStore } = rest;

  const onEdit = (values, actions) => {
    APIService.editBasicProfile(values, (success, json) => {
      if (success && json.result) {
        const data = { ...profile, ...json.result };
        dataStore.setUser(data);
        if (onClose) onClose();
      }
    });
    actions.setSubmitting(false);
  };
  return (
    <Drawer anchor="right" open={open} onClose={() => onClose(false)}>
      <Grid className={classes.root} {...rest}>
        <Grid item xs={12} sm={12} md={12}>
          <Formik
            initialValues={{
              firstName: profile.firstName,
              lastName: profile.lastName,
              avatarUrl: profile.avatarURL
              ?? (profile?.operation?.logoURL
                ? profile?.operation?.logoURL
                : null),
              tempAvatar: null,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().max(100).required('First Name is required'),
              lastName: Yup.string().max(80).required('Last Name is required'),
            })}
            onSubmit={(values, actions) => {
              onEdit(values, actions);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form className={classes.formContent} onSubmit={handleSubmit}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box className={classes.dash}>
                    {values.tempAvatar == null ? (
                      <Avatar
                        className={classes.thumb}
                        variant="circular"
                        width={88}
                        height={88}
                        src={values.avatarUrl ?? '/static/images/img_no_avatar.png'}
                      />
                    )
                      : (
                        <Thumb
                          className={classes.thumb}
                          image={values.tempAvatar}
                          width={88}
                          height={88}
                          uri={!values.tempAvatar ? '/static/images/img_no_avatar.png' : null}
                        />
                      )}
                  </Box>
                </Box>
                <UploadButton
                  onChange={(e) => { setFieldValue('tempAvatar', e.currentTarget.files[0]); console.log(e.currentTarget.files[0]); }}
                />
                <InputSC
                  label="First Name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={errors.firstName}
                />
                <InputSC
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={errors.lastName}
                />

                <Box padding="40px 0px">
                  <RoundButton
                    type="submit"
                    disabled={isSubmitting}
                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                  >
                    Save

                  </RoundButton>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Drawer>
  );
};

EditProfileBasic.propTypes = {
  profile: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default inject('dataStore')(observer(EditProfileBasic));
