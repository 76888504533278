import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  List,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import SwitchButtonGroup from 'src/components/SwitchButtonGroup';
import { Roles } from 'src/utils/Settings';
import RoundButton from 'src/components/RoundButton';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    height: '100%'
  },
  content: {
    height: '100%',
  },
  header: {
    marginBottom: '30px'
  },
  image: {
    height: 300,
    width: 'auto',
    alignSelf: 'center'
  },
}));

const userRoles = [
  { key: Roles.CUSTOMER, value: 'Customer' },
  { key: Roles.TRUCK_OWNER, value: 'Food Truck Owner' }
];

const userImages = {
  [Roles.CUSTOMER]: '/static/images/customer.png',
  [Roles.TRUCK_OWNER]: '/static/images/food_truck.png',
};

const SelectUserRoleForm = ({
  className,
  selectedRole,
  onChangeRole,
  onChangeStep
}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography align="center" variant="h5" className={classes.header}>
            Create Customer or Food Truck Owner
          </Typography>
          <List className={clsx(className)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <SwitchButtonGroup
                  items={userRoles}
                  onChange={onChangeRole}
                  selected={selectedRole}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  paddingY="50px"
                >
                  <img src={userImages[selectedRole]} className={classes.image} alt="customer" />
                </Box>
              </Grid>
            </Grid>
          </List>
        </CardContent>
      </Card>
      <Box padding="40px 36px">
        <RoundButton onClick={onChangeStep}>Continue</RoundButton>
      </Box>
    </>
  );
};

SelectUserRoleForm.propTypes = {
  className: PropTypes.string,
  selectedRole: PropTypes.string,
  onChangeRole: PropTypes.func,
  onChangeStep: PropTypes.func
};

export default SelectUserRoleForm;
