import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Drawer,
  Grid,
  List,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import clsx from 'clsx';
import APIService from 'src/utils/APIService';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import InputSC from './InputSC';
import RoundButton from './RoundButton';

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: 'white',
  },
  desc: {
    paddingBottom: 10
  }
}));
const MAX_NAME = 100;
const MAX_DESC = 300;

const MenuEditor = ({
  className,
  data,
  onClose,
  menuGroup
}) => {
  const classes = useStyles();
  const operationId = data?.id;
  const dispatch = useDispatch();
  const [counterDesc, setCounterDesc] = useState(menuGroup?.description?.length ?? 0);
  const onSave = (values, actions) => {
    if (menuGroup?.id) {
      APIService.updateMenuGroup(menuGroup.id, values.name, values.desc,
        (success, json) => {
          actions.setSubmitting(false);
          if (success && json.result) {
            const { result } = json;
            dispatch(notiActions.viewNoti({ type: 'success', content: 'Updated menu group successfully!' }));
            if (onClose) onClose(result);
          } else {
            dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
          }
        });
    } else {
      APIService.addMenuGroup(operationId, values.name, values.desc,
        (success, json) => {
          actions.setSubmitting(false);
          if (success && json.result) {
            const { result } = json;
            dispatch(notiActions.viewNoti({ type: 'success', content: 'Added menu group successfully!' }));
            if (onClose) onClose(result);
          } else {
            dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
          }
        });
    }
  };

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Card style={{ overflowY: 'auto' }}>
        <CardContent>
          <Typography align="center" variant="h5">
            {menuGroup?.id ? 'Update Menu Group' : 'Add New Menu Group'}
          </Typography>
          <List className={clsx(classes.root, className)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Formik
                  initialValues={{
                    name: menuGroup?.name ?? '',
                    desc: menuGroup?.description ?? '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().max(MAX_NAME, `Name limit ${MAX_NAME} words!`).required('Name is required'),
                    desc: Yup.string().max(MAX_DESC, `Description limit ${MAX_DESC} words!`),
                  })}
                  onSubmit={(values, actions) => {
                    onSave(values, actions);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldError,
                    setTouched,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <InputSC
                        error={Boolean(touched.name && errors.name)}
                        label="Name"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={errors.name}
                        value={values.name}
                      />
                      <Box style={{ display: 'flex' }}>
                        <InputSC
                          error={Boolean(touched.desc && errors.desc)}
                          label="Description"
                          name="desc"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.length <= MAX_DESC) {
                              setFieldValue('desc', e.target.value);
                              setTouched({ desc: true });
                              setCounterDesc(e.target.value.length);
                            } else {
                              setFieldError('desc', `Description limit ${MAX_DESC} words!`);
                            }
                          }}
                          value={values.desc}
                          multiline
                          helperText={errors.desc}
                          inputClass={classes.desc}
                        />
                        <Typography style={{
                          position: 'absolute',
                          bottom: touched.desc && errors.desc ? 97 : 75,
                          right: 15,
                          fontSize: 12,
                          color: touched.desc && errors.desc ? 'red' : 'black'
                        }}
                        >
                          {counterDesc}
                          /
                          {MAX_DESC}

                        </Typography>
                      </Box>
                      <RoundButton type="submit" label="Save" disabled={isSubmitting} />
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </List>
        </CardContent>
      </Card>
    </Drawer>
  );
};

MenuEditor.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func,
  menuGroup: PropTypes.object,
};

export default inject('dataStore')(observer(MenuEditor));
