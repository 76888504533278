import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export const stripeSecret = process.env.REACT_APP_STRIPE_API_KEY;
const stripe = loadStripe(stripeSecret);

const StripePayment = ({ children }) => {
  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  );
};

StripePayment.propTypes = {
  children: PropTypes.any,
};

export default StripePayment;
