import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import ColorSection from 'src/components/ColorSection';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  textContainer: {
    position: 'absolute',
    left: 20,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    maxWidth: 318,
    maxHeight: 63,
    width: '20vw',
    height: '10vw',
    marginTop: 50,
    borderRadius: 21.5,
    backgroundColor: '#39e214',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#39e214',
    }
  },
  title: {
    color: colors.tmGreen,
    // fontSize: 92,
    fontSize: '7vmin',
    fontWeight: 'bold'
  },
  content: {
    fontSize: '3vmin',
    fontWeight: 'bold'
  },
}));

const NotFoundView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onRequest = () => navigate('/contact-us');

  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container style={{ position: 'relative' }}>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>
            no results found
          </Typography>
          <Typography className={classes.content}>
            Have a Food Truck request or want Treadmeal in your area?
          </Typography>
          <Typography className={classes.content}>
            Click below and we’ll let you know when we arrive!
          </Typography>
          <Button className={classes.button} onClick={() => onRequest()}>
            <Typography style={{ fontWeight: 600, fontSize: 15, textTransform: 'capitalize' }}>
              Request
            </Typography>
          </Button>
        </Box>
        <img
          alt="Logo"
          src="/static/images/not_found_img.png"
          width="100%"
          style={{
            float: 'right',
            maxWidth: 640,
            maxHeight: 500
          }}
        />
      </Container>
    </ColorSection>
  );
};

export default NotFoundView;
