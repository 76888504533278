import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
  IconButton,
  colors,
  Chip,
  TableSortLabel,
} from '@material-ui/core';

import {
  Accessibility,
  CheckCircle,
  CheckCircleOutline,
  HelpOutline,
  HighlightOff,
  LocalShipping,
  SupervisedUserCircle
} from '@material-ui/icons';
import CustomSwitch from 'src/components/CustomSwitch';
import { Roles } from 'src/utils/Settings';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

const Results = ({
  className,
  data,
  filter,
  searchText,
  onSelected,
  onView,
  onVerify,
  onSuspend,
  selectedIds,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState([]);
  const [sortItem, setSortItem] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('asc');

  const handleSelectAll = (event) => {
    let newSelectedItemIds;

    if (event.target.checked) {
      newSelectedItemIds = data.map((item) => item.id);
    } else {
      newSelectedItemIds = [];
    }
    onSelected(newSelectedItemIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItemIds = newSelectedItemIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    onSelected(newSelectedItemIds);
  };

  const onSort = (a, b, type) => {
    if (typeof (a) === 'number' && typeof (b) === 'number') {
      return type === 'asc' ? (a - b) : (b - a);
    }
    if (typeof (a) === 'string' && typeof (b) === 'string') {
      return type === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    }
    if (typeof (a) === 'undefined' || !a) return 1;
    if (typeof (b) === 'undefined' || !b) return -1;
    return 0;
  };

  const handleSort = (item) => {
    setSortItem(item);
    if (!item.getItem) return;
    if (orderBy !== item.title) {
      setOrderBy(item.title);
      setOrderType('asc');
      setRecords((preRecords) => preRecords.sort((a, b) => onSort(item.getItem(a), item.getItem(b), 'asc')));
    } else {
      setRecords((preRecords) => preRecords.sort((a, b) => onSort(item.getItem(a), item.getItem(b), orderType === 'asc' ? 'desc' : 'asc')));
      setOrderType((preState) => (preState === 'asc' ? 'desc' : 'asc'));
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const renderRoleChip = (role) => {
    let icon;
    let color;
    switch (role) {
      case Roles.ADMIN:
        icon = <SupervisedUserCircle style={{ color: 'white' }} />;
        color = '#fb8c00';
        break;
      case Roles.CUSTOMER:
        icon = <Accessibility style={{ color: 'white' }} />;
        color = '#43a047';
        break;
      case Roles.TRUCK_OWNER:
        icon = <LocalShipping style={{ color: 'white' }} />;
        color = '#00acc1';
        break;
      default:
        icon = <HelpOutline style={{ color: 'white' }} />;
        color = '#e53935';
    }
    return <Chip size="small" icon={icon} label={role} style={{ background: color, color: 'white' }} />;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onFilter = (searchKeyword) => {
    return data.filter((item) => {
      const date = moment(item.created_at).format('DD/MM/YYYY HH:mm');
      if (filter) {
        let not = false;
        Object.keys(filter)
          .forEach((key) => {
            if (!filter[key]) return;
            if (item[key] !== filter[key]) not = true;
          });
        if (not) return false;
      }
      return item.email?.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
      || item.firstName?.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
      || item.lastName?.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
      || item.businessName?.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
      || item.phoneNumber?.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
      || date.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
    });
  };

  const search = searchText ? searchText.toLowerCase() : '';
  useEffect(() => {
    if (sortItem) {
      setRecords(onFilter(search).sort((a, b) => onSort(
        sortItem.getItem(a), sortItem.getItem(b), orderType
      )));
    } else {
      setRecords(onFilter(search));
    }
  }, [data, search, filter]);

  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === data.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {[
                  {
                    title: 'Id',
                    sortable: true,
                    getItem: (item) => item?.id,
                  },
                  {
                    title: 'Account',
                    sortable: true,
                    getItem: (item) => item?.email,
                  },
                  {
                    title: 'First Name',
                    sortable: true,
                    getItem: (item) => item?.firstName,
                  },
                  {
                    title: 'Last Name',
                    sortable: true,
                    getItem: (item) => item?.lastName,
                  },
                  {
                    title: 'Business Name',
                    sortable: true,
                    getItem: (item) => item?.businessName,
                  },
                  {
                    title: 'Phone Number',
                    sortable: true,
                    getItem: (item) => item?.phoneNumber,
                  },
                  {
                    title: 'Role',
                    sortable: false,
                  },
                  {
                    title: 'Verified',
                    sortable: false,
                  },
                  {
                    title: 'Created At',
                    sortable: false,
                  },
                  {
                    title: 'Verify',
                    sortable: false,
                  },
                  {
                    title: 'Active/Suspended',
                    sortable: false,
                  },
                ].map((item) => (
                  <TableCell key={item.title}>
                    {item.sortable ? (
                      <TableSortLabel
                        active={orderBy === item.title}
                        direction={orderBy === item.title ? orderType : 'asc'}
                        onClick={() => handleSort(item)}
                        style={{ display: 'flex' }}
                      >
                        {item.title}
                      </TableSortLabel>
                    ) : item.title}

                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                const date = moment(item.createdAt).format('DD/MM/YYYY HH:mm');
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.indexOf(item.id) !== -1}
                        onChange={(event) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      {item.id}
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                        className={classes.cursorPointer}
                        onClick={() => onView && onView(item)}
                      >
                        <Avatar
                          className={classes.avatar}
                          src={item.image || item?.avatarURL}
                        />
                        <Typography>{item.email || item.phoneNo || item.socialID}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.firstName}
                    </TableCell>
                    <TableCell>
                      {item.lastName}
                    </TableCell>
                    <TableCell>
                      {item.businessName}
                    </TableCell>
                    <TableCell>
                      {item.phoneNumber}
                    </TableCell>
                    <TableCell>
                      {renderRoleChip(item.role)}
                    </TableCell>
                    <TableCell>
                      {item.verified ? <CheckCircle style={{ color: colors.green[500] }} />
                        : <HighlightOff style={{ color: colors.red[500] }} />}
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                    <TableCell>
                      {
                        !item.verified && (
                          <IconButton onClick={() => onVerify(item.id)}>
                            <CheckCircleOutline style={{ color: colors.green[800] }} />
                          </IconButton>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        checked={item?.suspended}
                        onChange={((event) => onSuspend(item.id, event.target.checked))}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  onView: PropTypes.func,
  onVerify: PropTypes.func,
  onSuspend: PropTypes.func,
  selectedIds: PropTypes.array,
};

export default Results;
