import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import { AlternateEmail } from '@material-ui/icons';
import CardTitle from 'src/components/CardTitle';
import colors from 'src/common/colors';
import CustomSwitch from 'src/components/CustomSwitch';

const useStyles = makeStyles(({
  root: {}
}));

const SMTP = ({
  className, data, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    SMTP_SERVER: '',
    SMTP_USERNAME: '',
    SMTP_PASSWORD: '',
    MAILGUN_API_KEY: '',
    MAILGUN_DOMAIN: '',
    ADMIN_EMAIL: '',
    ENABLE_SMTP: 'false',
  });
  const dispatch = useDispatch();

  const bindData = (e) => {
    setValues({
      SMTP_SERVER: e.SMTP_SERVER?.value || '',
      SMTP_USERNAME: e.SMTP_USERNAME?.value || '',
      SMTP_PASSWORD: e.SMTP_PASSWORD?.value || '',
      MAILGUN_API_KEY: e.MAILGUN_API_KEY?.value || '',
      MAILGUN_DOMAIN: e.MAILGUN_DOMAIN?.value || '',
      ADMIN_EMAIL: e.ADMIN_EMAIL?.value || '',
      ENABLE_DEVELOPMENT_SMTP: e.ENABLE_DEVELOPMENT_SMTP?.value || 'false',
    });
  };

  useEffect(() => {
    bindData(data);
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const keys = ['SMTP_SERVER', 'SMTP_USERNAME', 'SMTP_PASSWORD', 'MAILGUN_API_KEY', 'MAILGUN_DOMAIN', 'ADMIN_EMAIL', 'ENABLE_DEVELOPMENT_SMTP'];
    keys.forEach((k) => {
      if (!data[k]) {
        data[k] = {
          key: k,
          title: k,
          value: values[k],
        };
      } else {
        data[k].value = values[k];
      }
    });
    const redata = keys.map((k) => data[k]);

    APIService.saveSettings(redata, (success, json) => {
      if (success && json.result) {
        bindData(data);
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Saved successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
    });
  };
  console.log(values.ENABLE_DEVELOPMENT_SMTP === 'true');

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title={(<CardTitle title="SMTP" icon={<AlternateEmail style={{ color: colors.red }} />} />)}
          subheader="Email configuration"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="SMTP Server"
            margin="normal"
            name="SMTP_SERVER"
            onChange={handleChange}
            value={values.SMTP_SERVER}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="User/Email"
            margin="normal"
            name="SMTP_USERNAME"
            onChange={handleChange}
            value={values.SMTP_USERNAME}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="SMTP_PASSWORD"
            type="password"
            onChange={handleChange}
            value={values.SMTP_PASSWORD}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Mailgun API Key"
            margin="normal"
            name="MAILGUN_API_KEY"
            onChange={handleChange}
            value={values.MAILGUN_API_KEY}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Mailgun Domain"
            margin="normal"
            name="MAILGUN_DOMAIN"
            onChange={handleChange}
            value={values.MAILGUN_DOMAIN}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Admin email"
            margin="normal"
            name="ADMIN_EMAIL"
            onChange={handleChange}
            value={values.ADMIN_EMAIL}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        {/* <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box> */}

        <Grid container justify="space-between">
          <Grid item>
            <Box
              display="flex"
              p={2}
            >
              <CustomSwitch
                checked={values.ENABLE_DEVELOPMENT_SMTP === 'true'}
                onChange={(event) => {
                  setValues({
                    ...values,
                    ENABLE_DEVELOPMENT_SMTP: `${event.target.checked}`
                  });
                }}
              />
              <Typography style={{
                marginTop: 10
              }}
              >
                SMTP dev mode
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

SMTP.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default SMTP;
