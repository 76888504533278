import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  List,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import InputSC from '../menu/InputSC';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  content: {
    height: '100vh',
  },
  header: {
    marginBottom: '30px'
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}));

const CustomerForm = ({ className, onFetch }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSave = (values, actions) => {
    APIService.addCustomer(values.firstName, values.lastName, values.email, (success, json) => {
      if (success && json.result) {
        console.log(json.result);
        onFetch();
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Created customer successfully!' }));
        actions.resetForm();
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        actions.resetForm();
      }
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography align="center" variant="h5" className={classes.header}>
            Fill Customer info
          </Typography>
          <List className={clsx(className)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().max(100).required('First Name is required'),
                    lastName: Yup.string().max(80).required('Last Name is required'),
                    email: Yup.string().email('Invalid email format').required('Email is required'),
                  })}
                  onSubmit={(values, actions) => {
                    onSave(values, actions);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form className={classes.formContent} onSubmit={handleSubmit}>
                      <InputSC
                        label="First Name"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={errors.firstName}
                      />
                      <InputSC
                        label="Last Name"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={errors.lastName}
                      />
                      <InputSC
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        helperText={errors.email}
                      />
                      <Box padding="40px 36px">
                        <RoundButton type="submit" disabled={isSubmitting}>Submit</RoundButton>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

CustomerForm.propTypes = {
  className: PropTypes.string,
  onFetch: PropTypes.func
};

export default CustomerForm;
