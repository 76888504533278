import { makeStyles, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState
} from 'react';
import ReactSlider from 'react-slider';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  slider: {
    top: 20,
    height: 40,
    width: '100%',
  },
  thumb: {
    position: 'absolute',
    cursor: 'pointer',
    top: -20
  },
  track: {
    position: 'relative',
    background: 'black',
    height: 2,
    marginLeft: 20,
    marginRight: 20,
  },
  thumbActive: {
  },
  ruler: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 40
  }
}));
const CustomSlider = ({ onChange, marks, value }) => {
  const [currentValue, setCurrentValue] = useState(value ?? 4);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const classes = useStyles();
  const markRef = useRef();
  useEffect(() => {
    setLeft(markRef?.current?.offsetLeft ?? 0);
    setWidth(markRef?.current?.offsetWidth ?? 0);
  }, []);
  window.addEventListener('resize', () => {
    setLeft(markRef?.current?.offsetLeft ?? 0);
    setWidth(markRef?.current?.offsetWidth ?? 0);
  });
  return (
    <Box className={classes.root}>
      <Box className={classes.ruler} ref={markRef}>
        {
            marks && marks.map((val, idx) => {
              return (
                <Box
                  key={`${idx.toString()}`}
                  style={{
                    color: idx < currentValue ? 'black' : colors.lightGrey,
                    cursor: 'pointer',
                    marginTop: 7,
                    padding: 1,
                    width: 40,
                    position: 'absolute',
                    left: left + ((width - 40) / (marks.length - 1)) * idx
                  }}
                  onClick={() => { setCurrentValue(idx + 1); onChange(idx + 1); }}
                >
                  <Typography style={{ fontSize: 21, textAlign: 'center' }}>
                    {val}
                  </Typography>
                </Box>
              );
            })
        }
      </Box>
      <ReactSlider
        className={classes.slider}
        thumbActiveClassName={classes.thumbActive}
        min={1}
        max={marks ? marks.length : 4}
        thumbClassName={classes.thumb}
        trackClassName={classes.track}
        onChange={(val) => setCurrentValue(val)}
        onAfterChange={() => onChange(currentValue)}
        value={currentValue}
        valueLabelDisplay="auto"
        renderThumb={(props) => (
          <img
            alt=""
            src="/static/images/icon_slider_thumb.png"
            width={40}
            {...props}
          />
        )}
      />
    </Box>

  );
};

CustomSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  marks: PropTypes.array,
};

export default CustomSlider;
