import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  List,
  makeStyles,
  MenuItem,
  Box,
  CardHeader,
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RoundUpload from 'src/components/RoundUpload';
import RoundSelect from 'src/components/RoundSelect';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import InputSC from '../menu/InputSC';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  content: {
    height: 'calc(100vh - 30px)',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflowY: 'auto'
  }
}));

const TruckOwnerForm = ({ className, onClose }) => {
  const classes = useStyles();
  const [cuisines, setCuisines] = useState();
  const dispatch = useDispatch();

  const getCuisines = () => APIService.getCuisines((success, json) => {
    if (success && json.result) {
      setCuisines(json.result);
    }
  });

  const onSave = (values, actions) => {
    APIService.addTruckOwner(
      values.logo,
      values.firstName,
      values.lastName,
      values.businessName,
      values.cuisineId,
      values.phoneNumber,
      values.email,
      values.address,
      values.city,
      values.zipCode,
      (success, json) => {
        actions.setSubmitting(false);
        if (success && json.result) {
          const { result } = json;
          if (onClose) onClose(result);
          dispatch(notiActions.viewNoti({ type: 'success', content: 'Added Truck Owner successfully!' }));
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      }
    );
  };

  useEffect(() => {
    getCuisines();
  }, []);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title="Please enter truck owner information" style={{ textAlign: 'center' }} />
        <CardContent className={classes.content}>
          <List className={clsx(className)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Formik
                  initialValues={{
                    logo: '',
                    firstName: '',
                    lastName: '',
                    businessName: '',
                    cuisineId: '',
                    phoneNumber: '',
                    email: '',
                    address: '',
                    city: '',
                    zipCode: ''
                  }}
                  validationSchema={Yup.object().shape({
                    logo: Yup.string().required('Logo is required'),
                    firstName: Yup.string().max(100).required('First Name is required'),
                    lastName: Yup.string().max(100).required('Last Name is required'),
                    businessName: Yup.string().max(100).required('Name of Company is required'),
                    cuisineId: Yup.number().required('Cuisine Type is required'),
                    phoneNumber: Yup.string()
                      .matches(/^[0-9-+]{1,12}$/, 'Phone Number must be a valid phone number')
                      .min(10, 'Phone Number must have at least 10 digits')
                      .max(12, 'Phone Number must have less than 13 digits')
                      .required('Phone Number is required'),
                    email: Yup.string().email('Email must be a valid email').required('Email is required'),
                    address: Yup.string().max(255).required('Address is required'),
                    city: Yup.string().max(255).required('City is required'),
                    zipCode: Yup.string()
                      .min(5, 'Zip code must have 5 digits')
                      .max(5, 'Zip code must have 5 digits')
                      .required('Zip Code is required'),
                  })}
                  onSubmit={(values, actions) => {
                    onSave(values, actions);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setErrors,
                    setTouched,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box className={classes.formContent}>
                        <RoundUpload
                          name="logo"
                          bg="/static/images/upload_logo.svg"
                          onBlur={handleBlur}
                          onChange={(e) => { setFieldValue('logo', e.currentTarget.files[0]); }}
                          value={values.logo}
                          error={Boolean(touched.logo && errors.logo)}
                          helperText={
                            (touched.logo && errors.logo) ? errors.logo : ''
                          }
                        />
                        <InputSC
                          label="First Name"
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstName}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={
                            (touched.firstName && errors.firstName) ? errors.firstName : ''
                          }
                        />
                        <InputSC
                          label="Last Name"
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastName}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={
                            (touched.lastName && errors.lastName) ? errors.lastName : ''
                          }
                        />
                        <InputSC
                          label="Name of Company"
                          name="businessName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.businessName}
                          error={Boolean(touched.businessName && errors.businessName)}
                          helperText={
                            (touched.businessName && errors.businessName) ? errors.businessName : ''
                          }
                        />
                        <RoundSelect
                          name="cuisineId"
                          onBlur={handleBlur}
                          style={{ marginTop: 18 }}
                          onChange={handleChange}
                          height={56}
                          value={values.cuisineId.toString()}
                          defaultValue=""
                          error={Boolean(touched.cuisineId && errors.cuisineId)}
                          helperText={
                            (touched.cuisineId && errors.cuisineId) ? errors.cuisineId : ''
                          }
                        >
                          <MenuItem disabled value="">
                            Cuisine
                          </MenuItem>
                          {
                            cuisines
                            && cuisines.map((cuisine) => (
                              <MenuItem key={cuisine.id} value={cuisine.id}>
                                {cuisine.title}
                              </MenuItem>
                            ))
                          }
                        </RoundSelect>
                        <InputSC
                          label="Phone Number"
                          name="phoneNumber"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value?.length <= 12) {
                              setTouched({ phoneNumber: true });
                              setFieldValue('phoneNumber', e.target.value);
                            } else {
                              setErrors({ phoneNumber: 'Phone Number must have less than 13 digits' });
                            }
                          }}
                          value={values.phoneNumber}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={
                            (touched.phoneNumber && errors.phoneNumber) ? errors.phoneNumber : ''
                          }
                        />
                        <InputSC
                          label="Email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          helperText={
                            (touched.email && errors.email) ? errors.email : ''
                          }
                        />
                        <InputSC
                          label="Address"
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          error={Boolean(touched.address && errors.address)}
                          helperText={
                            (touched.address && errors.address) ? errors.address : ''
                          }
                        />
                        <InputSC
                          label="City"
                          name="city"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city}
                          error={Boolean(touched.city && errors.city)}
                          helperText={
                            (touched.city && errors.city) ? errors.city : ''
                          }
                        />
                        <InputSC
                          label="Zip Code"
                          name="zipCode"
                          type="number"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value?.length < 6) {
                              setTouched({ zipCode: true });
                              setFieldValue('zipCode', e.target.value);
                            } else {
                              setErrors({ zipCode: 'Zip code must have 5 digits' });
                            }
                          }}
                          value={values.zipCode}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                          helperText={
                            (touched.zipCode && errors.zipCode) ? errors.zipCode : ''
                          }
                        />
                      </Box>
                      <Box mt={5} mb={3}>
                        <RoundButton type="submit" disabled={isSubmitting}>Submit</RoundButton>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

TruckOwnerForm.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default TruckOwnerForm;
