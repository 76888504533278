export const Roles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  CUSTOMER: 'CUSTOMER',
  TRUCK_OWNER: 'TRUCK_OWNER'
};

export const ApplicationStatus = {
  WAITING: 'WAITING',
  PENDING: 'PENDING',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED'
};

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const SubscriptionStatus = {
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  TRIALING: 'TRIALING',
  PAST_DUE: 'PAST_DUE',
  UNPAID: 'UNPAID',
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED'
};

export const PaymentType = {
  APPLE: 'APPLE',
  STRIPE: 'STRIPE',
};

export const TemplateType = {
  EMAIL: 'TEMPL_EMAIL',
  NOTIFICATION: 'TEMPL_NOTIF',
};

export default class Settings {
  static getItem(key) {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  static setItem(key, value) {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static appName() {
    return this.getItem('appName');
  }

  static setAppName(value) {
    this.setItem('appName', value);
  }

  static siteName() {
    return this.getItem('siteName');
  }

  static setSiteName(value) {
    this.setItem('siteName', value);
  }

  static sessionKey() {
    return this.getItem('sessionKey');
  }

  static setSessionKey(value) {
    this.setItem('sessionKey', value);
  }

  static role() {
    return this.getItem('role');
  }

  static setRole(value) {
    this.setItem('role', value);
  }

  static profile() {
    return this.getItem('profile');
  }

  static setProfile(value) {
    this.setItem('profile', value);
  }

  static getPlaystoreURL() {
    return this.getItem('playstore');
  }

  static setPlaystoreURL(value) {
    this.setItem('playstore', value);
  }

  static getAppstoreURL() {
    return this.getItem('appstore');
  }

  static setAppstoreURL(value) {
    this.setItem('appstore', value);
  }

  static otherProfile() {
    return this.getItem('otherProfile');
  }

  static setOtherProfile(value) {
    this.setItem('otherProfile', value);
  }

  static application() {
    return this.getItem('application');
  }

  static setApplication(value) {
    this.setItem('application', value);
  }

  static setDynamicContent(value) {
    return this.setItem('dynamicContent', value);
  }

  static dynamicContent() {
    return this.getItem('dynamicContent');
  }

  static languages() {
    return this.getItem('languages');
  }

  static setLanguages(value) {
    this.setItem('languages', value);
  }

  static translations() {
    return this.getItem('translations');
  }

  static setTranslations(value) {
    this.setItem('translations', value);
  }
}
