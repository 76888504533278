import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SunREInput from './Editor';

const Uploader = ({ visible, onClose, onSuccess }) => {
  const [, setThumb] = useState('');
  const onCloseModal = () => {
    setThumb(null);
    if (onClose) onClose();
  };
  return (
    <Dialog open={visible} onClose={onCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Dynamic Content</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            title: '',
            type: '',
            key: '',
            content: ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            type: Yup.string().required('Type is required'),
            // key: Yup.string().required('Key is required'),
            content: Yup.string().required('Content is required'),
          })}
          onSubmit={(values, actions) => {
            APIService.addDynamicContent(
              values.type,
              values.key,
              values.title,
              values.content,
              (success, json) => {
                actions.setSubmitting(false);
                if (success && json.result) {
                  actions.setStatus('Success');
                  onCloseModal();
                  if (onSuccess) onSuccess(json);
                } else {
                  actions.setErrors({ error: 'Failed to upload!' });
                }
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                select
                fullWidth
                label="Type"
                margin="dense"
                id="select-type"
                name="type"
                variant="outlined"
                value={values.type}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <MenuItem value="FAQ">FAQ</MenuItem>
                <MenuItem value="PRIVACY_POLICY">Privacy Policy</MenuItem>
                <MenuItem value="TERM_OF_USE">Term Of Use</MenuItem>
              </TextField>
              <TextField
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                fullWidth
                autoFocus
                label="Title"
                margin="dense"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.key && errors.key)}
                helperText={touched.key && errors.key}
                fullWidth
                autoFocus
                label="Key"
                margin="dense"
                name="key"
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                value={values.key}
                variant="outlined"
              />
              <SunREInput
                label="Content"
                name="content"
                content={values.content}
                placeholder="Content"
                onChange={(text) => setFieldValue('content', text)}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width={400}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
                <Box ml={4} />
                <Button
                  color="secondary"
                  size="large"
                  variant="outlined"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

Uploader.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default Uploader;
