/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
// import Settings from 'src/utils/Settings';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  input: {
    display: 'none',
  },
  label: {
    cursor: 'pointer',
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = rest;
  const [image, setImage] = useState({});
  const onImageChange = (event) => {
    if (event.currentTarget.files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage((preState) => { return { ...preState, url: reader.result }; });
      };
      reader.readAsDataURL(event.currentTarget.files[0]);
      setImage((preState) => { return { ...preState, file: event.currentTarget?.files[0] }; });
    }
  };

  const onSave = () => {
    APIService.adminUpdateAvatar(image, (success, json) => {
      if (success && json.result) {
        user.avatarURL = json.result.avatarURL;
        setImage({});
        Settings.setProfile(user);
      }
    });
  };
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={image.url ?? user?.avatarURL}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            style={{ textAlign: 'center' }}
          >
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          {/* <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user.city} ${user.country}`}
          </Typography> */}
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${'GTM-7'}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.button}>
        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onImageChange} />
        <label htmlFor="icon-button-file" className={classes.label}>
          <Box display="flex" flexDirection="row" alignItems="center" className={classes.label}>
            <Typography>
              Upload picture
            </Typography>
          </Box>
        </label>
      </CardActions>
      {image.url && (
      <CardActions className={classes.button}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave()}
          style={{ alignSelf: 'center' }}
        >
          Save details
        </Button>
      </CardActions>
      )}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
