import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, IconButton, makeStyles,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 40,
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  inner: {
    cursor: 'pointer',
  }
}));

const AddDash = ({ editable, onClick, className }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {editable && (
        <Box
          className={clsx(classes.inner, className)}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
        >
          <IconButton onClick={onClick}>
            <AddCircleOutline />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

AddDash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
};

export default AddDash;
