import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Popover
} from '@material-ui/core';
import Settings from 'src/utils/Settings';
import APIService from 'src/utils/APIService';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import NotificationItem from 'src/views/notification/NotificationItem';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: 60,
    height: 60
  },
  boxPopover: {
    width: 380,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingTop: 10,
    paddingBottom: 5,
    backgroundColor: theme.palette.background.dark,
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  dataStore,
  ...rest
}) => {
  const classes = useStyles();
  const [polling, setPolling] = useState(0);
  const [viewPopover, setViewPopover] = useState(false);
  const navigate = useNavigate();
  const [name, setAppName] = useState('');

  useEffect(() => {
    if (dataStore.newNotification) {
      setViewPopover(true);
      setTimeout(() => {
        setViewPopover(false);
        dataStore.setNewNotification();
      }, 5000);
    }
  }, [dataStore.newNotification]);

  useEffect(() => {
    setTimeout(() => setPolling(polling + 1), 1000);
  }, [polling, dataStore]);

  useEffect(() => {
    APIService.getLiteSettings((success, json) => {
      if (success && json.result) {
        const { appName, siteName } = json.result;
        setAppName(appName);
        Settings.setAppName(appName);
        Settings.setSiteName(siteName);
      }
    });
  }, []);

  const logout = () => {
    dataStore.setNotifications([]);
    dataStore.setRole('');
    Settings.setSessionKey(null);
    Settings.setRole(null);
    navigate('/', { replace: true });
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={3}
      {...rest}
    >
      <Toolbar>
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Typography color="textPrimary" variant="h4" align="center">{name || ''}</Typography>
        </Box>
        <Hidden mdDown>
          <IconButton color="primary" onClick={() => dataStore.showNotif(true)}>
            <Badge
              badgeContent={dataStore.notifications.length}
              color="error"
              variant="standard"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            open={viewPopover}
            onClose={() => {
              setViewPopover(false);
              dataStore.setNewNotification();
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -60,
              horizontal: 100,
            }}
          >
            <Box
              onClick={() => dataStore.showNotif(true)}
              className={classes.boxPopover}
            >
              <NotificationItem
                data={dataStore.newNotification ?? {}}
              />
            </Box>
          </Popover>
          <IconButton color="primary" onClick={() => logout()}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  dataStore: PropTypes.any,
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default inject('dataStore')(observer(TopBar));
