import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import APIService from 'src/utils/APIService';
import Footer from 'src/views/customer/components/Footer';
import Header from 'src/views/customer/components/Header';
import Settings, { Roles } from 'src/utils/Settings';
import FlyTop from 'src/components/FlyTop';
import DrawerMenu from 'src/views/customer/components/DrawerMenu';
import Maintenance from 'src/views/customer/pages/Maintenance';

const authList = [
  {
    key: 'AUTH_HOME',
    title: 'Home',
    path: '/'
  },
  {
    key: 'AUTH_MAP',
    title: 'Map',
    path: '/map'
  },
  {
    key: 'AUTH_FOOD_TRUCKS',
    title: 'Food Trucks',
    path: '/food-truck'
  },
  {
    key: 'AUTH_SEARCH',
    title: 'Search',
    path: '#searching'
  },

];

const navList = [
  {
    key: 'NAV_HOME',
    title: 'Home',
    path: '/'
  },
  {
    key: 'HOW_IT_WORKS',
    title: 'How it Works',
    path: '/#how-it-works'
  },
  {
    key: 'NAV_PRICING',
    title: 'Pricing',
    path: '/#pricing'
  },
  {
    key: 'NAV_CONTACT_US',
    title: 'Contact Us',
    path: '/#contact-us'
  },
  {
    key: 'NAV_SEARCH',
    title: 'Search',
    path: '/#search'
  },
];

const dynamicList = ['/privacy', '/terms', '/faqs', '/dynamic'];

const AUTH_LOCATION = [
  '/map',
  '/profile',
  '/food-truck',
];

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
  contentWrapper: {
    minHeight: '60vh'
  },
  button: {
    width: '90%',
    display: 'flex',
    boxShadow: '0px 2px 6px rgba(0,0,0,0.27)',
    background: '#39E214',
    padding: '10px 20px',
    margin: ' 10px 0px 10px 10px ',
    borderRadius: '30px',
    border: 'none',
    color: '#000',
    '&:hover': {
      backgroundColor: '#231F20',
      color: '#39E214'
    }
  },
  loginTag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10
  },
  loginImg: {
    width: 50,
    height: 50,
    borderRadius: 50
  },
  logoutBtn: {
    width: 30,
    height: 50,
    alignItems: 'center'
  },
  logoutIcon: {
    width: 24,
    height: 24
  },
  name: {
    marginLeft: 10,
    minWidth: 100,
    maxWidth: 150
  }
}));

const CustomerLayout = ({ ...rest }) => {
  const { hostname } = window.location;
  const isAdminSite = hostname.includes('admin');
  const [isAuth, setIsAuth] = useState(false);
  const [isViewMenu, setIsViewMenu] = useState(false);
  const [isMaintained, setMaintained] = useState(null);
  const [profile, setProfile] = useState({});
  const classes = useStyles();
  const { dataStore } = rest;
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const data = Settings.getItem('profile');
    setProfile(data);
    const sessionKey = Settings.getItem('sessionKey');

    APIService.getLiteSettings((success, json) => {
      if (success && json.result) {
        console.log(json.result);
        setMaintained(json.result?.maintained);
        Settings.setAppstoreURL(json.result?.appstoreURL);
        Settings.setPlaystoreURL(json.result?.playstoreURL);
      } else {
        setMaintained(false);
      }
    });

    if (sessionKey) {
      setIsAuth(true);
      APIService.verifyUserToken(sessionKey, (success, json) => {
        if (success && json.result) {
          const { result } = json;
          Settings.setSessionKey(result.token);
          Settings.setRole(result.role);
          Settings.setProfile({
            ...result
          });
          dataStore.setUser(result);
          setProfile(result);
          setIsAuth(true);
          if (result.role === 'ADMIN') {
            if (!dynamicList.includes(location.pathname)) {
              navigate('/app', { replace: true });
            }
          } else if (result.role === 'TRUCK_OWNER') {
            navigate('/agent/profile', { replace: true });
            Settings.setItem('sessionKey', result.token);
            Settings.setItem('role', result.role);
            Settings.setItem('profile', result);
          }
        } else {
          if (AUTH_LOCATION.includes(location.pathname)) {
            navigate('/', { replace: true });
          }
          Settings.setItem('sessionKey', null);
          Settings.setItem('role', null);
          Settings.setItem('profile', null);
          setIsAuth(false);
          setProfile({});
        }
      });
    } else if (AUTH_LOCATION.includes(location.pathname)) {
      navigate('/', { replace: true });
    }
  }, []);

  const onLogin = (values, actions) => {
    APIService.signIn(values.email, values.password, (success, json) => {
      actions.setSubmitting(false);
      if (success && json.result) {
        const { result } = json;
        const admin = result.role && result.role === Roles.ADMIN;
        if (admin) {
          actions.setErrors({ email: 'Unauthorized access' });
          return;
        }
        Settings.setSessionKey(result.token);
        Settings.setRole(result.role);
        Settings.setProfile({
          ...result
        });
        setProfile(result);
        dataStore.setUser(result);
        dataStore.setRole(result.role);
        setIsAuth(true);
        setIsViewMenu(false);
        if (admin) {
          navigate('/app/dashboard', { replace: true });
        } else {
          window.location.reload();
        }
      } else {
        actions.setErrors({ email: json.error, password: ' ' });
      }
    });
  };
  const onLogout = () => {
    Settings.setItem('sessionKey', null);
    Settings.setItem('role', null);
    Settings.setItem('profile', null);
    setIsAuth(false);
    dataStore.setUser({});
    dataStore.setRole('');
    setProfile({});
    navigate('/', { replace: 'true' });
    window.location.reload();
  };

  if (isMaintained === null) {
    return (
      <Box className={classes.root}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.root}>
        <a name="top"> </a>
        <Header
          onViewMenuMobile={() => setIsViewMenu(true)}
          navList={isAuth ? authList : navList}
          isAuth={isAuth}
          userData={profile}
          onLogin={!isAdminSite && onLogin}
          onLogout={onLogout}
        />
        <Box className={classes.contentWrapper}>
          {!isMaintained ? <Outlet className={classes.contentWrapper} /> : <Maintenance />}
        </Box>
        {!isMaintained && (
          <DrawerMenu
            isAuth={isAuth}
            items={isAuth ? authList : navList}
            open={isViewMenu}
            onLogin={!isAdminSite && onLogin}
            onLogout={onLogout}
            onClose={() => setIsViewMenu(false)}
          />
        )}
        <FlyTop to="#top" />
        <Footer />
      </Box>
    </>
  );
};

export default inject('dataStore')(observer(CustomerLayout));
