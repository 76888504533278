import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const validateToken = () => {
    const sessionKey = Settings.sessionKey();
    if (!sessionKey) {
      navigate('/app/login', { replace: true });
    }
    APIService.verifyUserToken(sessionKey, (success, json) => {
      if (success && json.result) {
        const { result } = json;
        Settings.setSessionKey(result.token);
      } else {
        Settings.setSessionKey(null);
        navigate('/app/login', { replace: true });
      }
    });
  };

  const fetchLanguages = () => {
    APIService.getTranslations((success, json) => {
      if (success && json.result) {
        Settings.setTranslations(json.result);
      }
    });
  };

  useEffect(() => {
    validateToken();
    fetchLanguages();
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
