export default class StringUtils {
  static upperCaseFirstLetter(text) {
    if (typeof text !== 'string') return '';
    return text
      .replace(/_/g, ' ')
      .replace(/(\B)[^ ]*/g, (match) => (match.toLowerCase()))
      .replace(/^[^ ]/g, (match) => (match.toUpperCase()));
  }

  static convertBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '';

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;

    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  }
}
