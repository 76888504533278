import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Dialog
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10
  }
}));

const ChangePassword = ({
  className, show, onClose, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
    >

      <form
        autoComplete="off"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="Change Admin Password"
            title="Change Password"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  type="password"
                  helperText=""
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  required
                  value={values?.password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  name="newPassword"
                  onChange={handleChange}
                  required
                  value={values?.newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  type="password"
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  onChange={handleChange}
                  required
                  value={values?.confirmNewPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => rest.onSubmit(values)}
            >
              Change Password
            </Button>
          </Box>
        </Card>
      </form>
    </Dialog>
  );
};

ChangePassword.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangePassword;
