import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Dialog,
  DialogContent,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TruckItem from 'src/components/TruckItem';
import TruckProfile from 'src/components/TruckProfile';
import APIService from 'src/utils/APIService';
import FilterTruck from '../components/FilterTruck';

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
    minHeight: '80vh'
  },
  filterContainer: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  filterTitle: {
    fontSize: 18,
    color: '#191A1E'
  },
  stickArea: {
    position: 'sticky',
    top: 100,
    height: '75vh',
  },
  scrollArea: {
    overflow: 'scroll',
    overflowX: 'hidden'
  }
}));

const CustomerTruckList = () => {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(-1);
  const [trucks, setTrucks] = useState([]);
  const [truckProfile, setTruckProfile] = useState();
  const [viewProfile, setViewProfile] = useState(false);

  const keyword = query.get('keyword');
  const cuisineId = query.get('cuisineId');
  const pricePoint = query.get('pricePoint');
  const distance = query.get('distanceLevel');
  const sortType = query.get('sortType');

  const getLocation = (callback) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        localStorage.setItem('location', JSON.stringify(location));
        if (callback) {
          callback(position.coords.latitude, position.coords.longitude);
        }
      },
      (err) => {
        console.log(err);
        if (callback) {
          callback();
        }
      }
    );
  };
  const getTrucks = (options) => {
    getLocation((latitude, longitude) => {
      let queries = { ...options };
      if (latitude && longitude) {
        queries = {
          ...queries,
          latitude,
          longitude
        };
      }
      APIService.getTrucks(
        { ...queries },
        (success, json) => {
          if (success && json) {
            if (queries.keyword && json.result.length <= 0) navigate('/404', true);
            else setTrucks(json.result);
          }
        }
      );
    });
  };

  const getTruckProfile = (id) => {
    setSelectedId(id);
    APIService.getTruckById(id, (success, json) => {
      if (success && json) {
        setTruckProfile(json.result);
        setViewProfile(true);
      }
    });
  };
  return (
    <Box className={classes.container}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} className={classes.filterContainer}>
            <Box>
              <FilterTruck
                index={0}
                onFilter={getTrucks}
                values={{
                  keyword,
                  cuisineId,
                  pricePoint,
                  distance,
                  sortType
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box>
              {
                trucks.map((truck, index) => (
                  <TruckItem
                    key={`${index.toString()}`}
                    title={truck.owner?.businessName}
                    cuisine={truck.owner?.cuisine.title}
                    imageURL={truck.owner?.operation.logoURL}
                    pricePoint={truck.owner?.operation.pricePoint}
                    isOpen={truck.status === 'ACTIVE'}
                    onClick={() => getTruckProfile(truck.id)}
                    isSelected={selectedId === truck.id}
                  />
                ))
              }
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={4} style={{ paddingLeft: 30, paddingRight: 30 }}>
              {truckProfile
                && (
                  <Box className={clsx(classes.scrollArea, classes.stickArea)}>
                    <TruckProfile
                      user={truckProfile.owner}
                      data={truckProfile.owner?.operation}
                    />
                  </Box>
                )}
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Dialog open={viewProfile} onClose={() => setViewProfile(false)}>
            <DialogContent>
              {truckProfile
                && (
                  <TruckProfile
                    user={truckProfile.owner}
                    data={truckProfile.owner?.operation}
                  />
                )}
            </DialogContent>
          </Dialog>
        </Hidden>
      </Container>
    </Box>
  );
};

export default CustomerTruckList;
