import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  searchText,
  onSelected,
  selectedIds,
  onPuzzle,
  onPhrase,
  onPreView,
  onDelete,
  onViewDetails,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedItemIds;

    if (event.target.checked) {
      newSelectedItemIds = data.map((item) => item.id);
    } else {
      newSelectedItemIds = [];
    }
    onSelected(newSelectedItemIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItemIds = newSelectedItemIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }
    onSelected(newSelectedItemIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((item) => {
    return item.name.toLowerCase().indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === data.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {
                  ['Id', 'Name', 'Description', 'Menu Group', 'Truck Owner Name', 'BussinessName', 'Created At'].map((title) => <TableCell key={title}>{title}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.indexOf(item.id) !== -1}
                        onChange={(event) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      {item.id}
                    </TableCell>
                    <TableCell>
                      {item.name}
                    </TableCell>
                    <TableCell>
                      {item.description}
                    </TableCell>
                    <TableCell>
                      {item.menuGroup.name}
                    </TableCell>
                    <TableCell>
                      {`${item.menuGroup.operation.users[0].firstName} ${item.menuGroup.operation.users[0].firstName}`}
                    </TableCell>
                    <TableCell>
                      {item.menuGroup.operation.users[0].businessName}
                    </TableCell>
                    <TableCell>
                      {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
  onPuzzle: PropTypes.func,
  onPhrase: PropTypes.func,
  onPreView: PropTypes.func,
  onDelete: PropTypes.func,
  onViewDetails: PropTypes.func,
};

export default Results;
