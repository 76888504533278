import { Formik } from 'formik';
import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import RoundButton from 'src/components/RoundButton';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import InputSC from 'src/views/menu/InputSC';
import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    maxWidth: 350,
    paddingLeft: 15,
    paddingRight: 15,
  },
  email: {
    width: '100%',
    height: 'auto',
    minWidth: 150,
  },
  password: {
    width: '100%',
    height: 'auto',
    minWidth: 150,
    margin: 0
  },
  forgotPassword: {
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      color: colors.tmGreen,
    }
  },
  button: {
    textTransform: 'capitalize',
    fontWeight: '600'
  }
}));

const LoginForm = ({ onLogin, onClose, className }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        password: Yup.string().max(80).required('Password is required'),
      })}
      onSubmit={(values, actions) => {
        onLogin({ ...values, email: values.email.toLowerCase() }, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form className={clsx(classes.formContent, className)} onSubmit={handleSubmit}>
          <InputSC
            className={classes.email}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={errors.email}
          />
          <InputSC
            className={classes.password}
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            helperText={errors.password}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Typography
              className={classes.forgotPassword}
              onClick={() => {
                navigate('/forgot-password');
                if (onClose) onClose();
              }}
            >
              Forgot password?
            </Typography>
          </Box>
          <Box mb={2}>
            <RoundButton
              type="submit"
              disabled={isSubmitting}
            >
              <Typography className={classes.button}>Login</Typography>
            </RoundButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string
};

export default LoginForm;
