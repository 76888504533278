import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import Content from './Content';
import Uploader from './Uploader';
// import ReportDetail from './ReportDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const MenuGroups = () => {
  const classes = useStyles();
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleForm, showForm] = useState(false);
  // const [detailReport, setDetailReport] = useState();
  const [visibleContent, showContent] = useState(false);
  // const [viewDetail, setViewDetail] = useState(false);

  const [searchText, setSearchText] = useState('');

  const getContents = () => APIService.getMenuGroups((success, json) => {
    if (success && json.result) {
      console.log(json.result);
      setContents(json.result);
    }
  });

  const deleteItems = () => APIService.deleteManyMenuGroups(selectedItems, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getContents();
    }
  });

  // const deletePost = (id) => APIService.deleteMenu(id, (success, json) => {
  //   if (success && json.result) {
  //     setSelectedItems([]);
  //     getContents();
  //   }
  // });

  const onShowContent = (item) => {
    showContent(true);
    setContent(item.body);
  };

  useEffect(() => {
    getContents();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Menu Groups"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => deleteItems() : null}
          onAdd={() => showForm(true)}
        />
        <Box mt={3}>
          <Results
            data={contents}
            searchText={searchText}
            selectedIds={selectedItems}
            onPreView={(item) => onShowContent(item)}
            onSelected={(ids) => setSelectedItems(ids)}
          // onDelete={(id) => deletePost(id)}
          // onViewDetails={(item) => {
          //   setDetailReport(item);
          //   setViewDetail(true);
          // }}
          />
        </Box>
        <Uploader
          visible={visibleForm}
          onClose={() => showForm(false)}
          onSuccess={() => getContents()}
        />
        <Content
          content={content}
          visible={visibleContent}
          onClose={() => showContent(false)}
          onSuccess={() => getContents()}
        />
        {/* {viewDetail
          && (
            <ReportDetail
              visible={viewDetail}
              details={detailReport?.report}
              onClose={() => setViewDetail(false)}
            />
          )} */}
      </Container>
    </Page>
  );
};

MenuGroups.propTypes = {
};
export default MenuGroups;
