import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Codesandbox } from 'react-feather';
import APIService from 'src/utils/APIService';
import { TemplateType } from 'src/utils/Settings';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SunREInput from '../dynamic-contents/Editor';

const Uploader = ({
  type, visible, onClose, onSuccess, data
}) => {
  const [expert, setExpert] = useState(true);
  const onCloseModal = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={visible} onClose={onCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Template</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            title: data?.title ?? '',
            key: data?.key ?? '',
            content: data?.content ?? ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            key: Yup.string().required('Key is required'),
            content: Yup.string().required('Content is required'),
          })}
          onSubmit={(values, actions) => {
            if (!data) {
              APIService.addDynamicContent(
                type,
                values.key,
                values.title,
                values.content,
                (success, json) => {
                  actions.setSubmitting(false);
                  if (success && json.result) {
                    actions.setStatus('Success');
                    onCloseModal();
                    if (onSuccess) onSuccess(json);
                  } else {
                    actions.setErrors({ error: 'Failed to upload!' });
                  }
                }
              );
            } else {
              APIService.updateDynamicContent(
                data.id,
                type,
                values.key,
                values.title,
                values.content,
                null,
                (success, json) => {
                  actions.setSubmitting(false);
                  if (success && json.result) {
                    actions.setStatus('Success');
                    onCloseModal();
                    if (onSuccess) onSuccess(json);
                  } else {
                    actions.setErrors({ error: 'Failed to upload!' });
                  }
                }
              );
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                fullWidth
                autoFocus
                label="Title"
                margin="dense"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.key && errors.key)}
                helperText={touched.key && errors.key}
                fullWidth
                autoFocus
                label="Key"
                margin="dense"
                name="key"
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                value={values.key}
                variant="outlined"
              />
              {
                type === TemplateType.EMAIL
                && (
                  <SunREInput
                    expertMode={expert}
                    label="Content"
                    name="content"
                    content={values.content}
                    placeholder="Content"
                    onChange={(text) => setFieldValue('content', text)}
                  />
                )
              }
              {
                type === TemplateType.NOTIFICATION
                && (
                  <TextField
                    error={Boolean(touched.content && errors.content)}
                    helperText={touched.content && errors.content}
                    fullWidth
                    autoFocus
                    label="Content"
                    margin="dense"
                    name="content"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="string"
                    value={values.content}
                    variant="outlined"
                  />
                )
              }
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width={400}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
                <Box ml={4} />
                <Button
                  color="secondary"
                  size="large"
                  variant="outlined"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </Button>
                {
                  type === TemplateType.EMAIL
                  && (
                    <Box position="absolute" right={20} bottom={10}>
                      <IconButton onClick={() => setExpert(!expert)}><Codesandbox /></IconButton>
                    </Box>
                  )
                }
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

Uploader.propTypes = {
  type: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  data: PropTypes.object,
};
export default Uploader;
