import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  colors,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  title: {
    color: colors.blueGrey[700],
    width: 120,
  },
  content: {
    fontWeight: '500'
  }
}));

const InfoItem = ({ className, title, content }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      flexDirection="row"
    >
      <Typography
        className={classes.title}
        color="textSecondary"
      >
        {`${title || ''}`}
      </Typography>
      <Typography
        className={classes.content}
        color="textPrimary"
      >
        {`${content || ''}`}
      </Typography>
    </Box>
  );
};

InfoItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default InfoItem;
