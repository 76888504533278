import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TextField
} from '@material-ui/core';
import colors from 'src/common/colors';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    borderRadius: 25,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.inputGreyBorder,
    margin: 0.6,
  },
  input: {
  },
  inputBox: {
    backgroundColor: colors.inputGrey,
  },
  root: {
    borderRadius: 25,
    overflow: 'hidden'
  },
}));

const InputSC = ({
  error,
  label,
  name,
  type,
  onBlur,
  onChange,
  value,
  multiline,
  helperText,
  inputClass,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      error={!!error}
      fullWidth
      helperText={helperText}
      label={label}
      margin="normal"
      name={name}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      variant="outlined"
      multiline={multiline}
      InputProps={{
        className: classes.inputBox,
        classes: {
          notchedOutline: classes.notchedOutline,
          input: clsx(classes.input, inputClass),
          root: classes.root,
        }
      }}
      {...props}
    />
  );
};

InputSC.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  helperText: PropTypes.string,
  inputClass: PropTypes.string
};

export default InputSC;
