import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, TextField
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-error': {
      borderColor: '#f44336'
    },
    marginBottom: 10
  },
  select: {
    width: '100%',
    minWidth: 150,
    border: 'dashed 0.2px rgba(0,0,0,0.43)',
    background: '#fafbfb',
    padding: '6px 20px',
    borderRadius: '30px',
    '& .MuiSelect-select:focus': {
      background: '#fafbfb',
    },
    '& .MuiSelect-icon': {
      right: '10px'
    },
  },
  helperText: {
    padding: '0 15px 5px 15px'
  },
}));

const RoundSelect = ({
  value, height, className, ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      select
      className={clsx(classes.root, className)}
      value={value}
      {...props}
      SelectProps={
        {
          disableUnderline: true,
          className: classes.select,
          displayEmpty: true,
          style: { color: value === '' ? '#8e8e8e' : 'currentColor', height }
        }
      }
      FormHelperTextProps={{ className: classes.helperText }}
    />
  );
};

RoundSelect.propTypes = {
  value: PropTypes.string,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default RoundSelect;
