const CryptoJS = require('crypto-js');

const CryptoSecret = 'YHv8BmyJ432up6zU';

export default class CryptoHelper {
  static encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), CryptoSecret).toString();
  }

  static decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, CryptoSecret).toString(CryptoJS.enc.Utf8));
  }
}
