import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { CheckSharp } from '@material-ui/icons';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  enabled: {
    color: '#0f0',
  },
  disabled: {
    color: '#ccc',
  }
}));

const Results = ({
  className,
  data,
  searchText,
  onSelected,
  selectedIds,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const updateLocalization = (item) => {
    APIService.updateLocalization(item.id, true, item.enableLanguage, item.enableDial,
      (success, json) => {
        setUpdate(update + 1);
        if (success && json.result) {
          const { enableDial, enableLanguage } = json.result;
          item.enableLanguage = enableLanguage;
          item.enableDial = enableDial;
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      });
  };

  const toggleDial = (item) => {
    item.enableDial = !item.enableDial;
    updateLocalization(item);
  };

  const toggleLanguage = (item) => {
    item.enableLanguage = !item.enableLanguage;
    updateLocalization(item);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((item) => {
    return item.country.toLowerCase().indexOf(search) >= 0
      || item.countryCode.toLowerCase().indexOf(search) >= 0
      || item.dialCode.toLowerCase().indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  ['', 'Dial Code', 'Country', 'Use dialing', 'Enable language'].map((title) => <TableCell key={title}>{title}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell>
                      {item.flag}
                    </TableCell>
                    <TableCell>
                      {item.dialCode}
                    </TableCell>
                    <TableCell>
                      {item.country}
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="toggle dial" onClick={() => toggleDial(item)}>
                        <CheckSharp className={item.enableDial
                          ? classes.enabled : classes.disabled}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="toggle language" onClick={() => toggleLanguage(item)}>
                        <CheckSharp className={item.enableLanguage
                          ? classes.enabled : classes.disabled}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
};

export default Results;
