import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  IconButton,
  Box, makeStyles, Typography,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import colors from 'src/common/colors';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import Food from './Food';
import AddDash from './AddDash';
import FoodEditor from './FoodEditor';
import AddFood from './AddFood';

const useStyles = makeStyles(() => ({
  name: {
    textAlign: 'center',
    color: colors.primary,
  },
  desc: {
    textAlign: 'center',
    color: colors.holoBlack,
  },
  foods: {
    padding: 10,
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#eee'
    },
    borderRadius: 15,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  button: {
    '&:hover': {
      backgroundColor: '#ff6666',
    },
  }
}));

const Menu = ({
  data, editable, onDelete, onUpdate, ...props
}) => {
  const classes = useStyles();
  const [add, setAddFlag] = useState(false);
  const [updateFood, setUpdateFood] = useState();
  const { name, description } = data || {};
  const [menuFoods, setMenuFoods] = useState(data?.menuFoods ?? []);
  const [role, setRole] = useState('');
  useEffect(() => {
    setRole(Settings.role());
  }, []);
  const addFood = () => {
    setAddFlag(true);
  };

  const closeEditor = (food) => {
    if (food && food.groupId) {
      const { dataStore } = props;
      const group = dataStore.menu.find((g) => g.id === food.groupId);
      if (group) {
        if (updateFood?.id) {
          group.menuFoods = group.menuFoods.map((item) => {
            if (item.id === food.id) {
              return {
                ...item, name: food.name, price: food.price, description: food.description
              };
            }
            return item;
          });
        } else {
          if (!menuFoods) group.menuFoods = [];
          group.menuFoods.push(food);
        }
        setMenuFoods(group.menuFoods);
      }
    }
    setAddFlag(false);
    setUpdateFood();
  };

  const deleteFood = (menuFood) => {
    APIService.deleteMenuFood(menuFood.id, (success, json) => {
      if (success && json.result) {
        const { dataStore } = props;
        const group = dataStore.menu.find((g) => g.id === menuFood.groupId);
        if (group) {
          if (!menuFoods) group.menuFoods = [];
          group.menuFoods = group.menuFoods.filter((e) => e.id !== menuFood.id);
          setMenuFoods(group.menuFoods);
        }
      }
    });
    setAddFlag((pre) => pre && !pre);
  };
  const empty = !menuFoods || menuFoods.length === 0;

  return (
    <Box {...props} mb={2}>
      <Box className={classes.header}>
        <Box className={classes.titleWrapper} onClick={onUpdate}>
          <Typography className={classes.name} variant="h4">{name || ''}</Typography>
          <Typography className={classes.desc}>{description || ''}</Typography>
        </Box>
        {role === 'ADMIN' && (
        <IconButton onClick={onDelete} className={classes.button}>
          <Delete color="inherit" />
        </IconButton>
        )}
      </Box>

      {empty
        ? <AddDash onClick={addFood} editable={editable} />
        : (
          <Box className={classes.foods}>
            {
              menuFoods.map((e) => (
                <Food
                  key={e.id}
                  name={e.name}
                  price={e.price}
                  desc={e.description}
                  onDelete={() => deleteFood(e)}
                  onUpdate={() => { if (role === 'ADMIN') { setUpdateFood(e); setAddFlag(true); } }}
                />
              ))
            }
            {editable && <AddFood onClick={addFood} />}
          </Box>
        )}
      {add && <FoodEditor food={updateFood} data={data} onClose={closeEditor} />}
    </Box>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  dataStore: PropTypes.any,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default inject('dataStore')(observer(Menu));
