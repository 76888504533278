const CountryCodes = [{
  dialCode: '+1', flag: '🇨🇦', countryCode: 'CA', name: 'Canada'
}, {
  dialCode: '+1', flag: '🇺🇸', countryCode: 'US', name: 'United States'
}, {
  dialCode: '+1242', flag: '🇧🇸', countryCode: 'BS', name: 'Bahamas'
}, {
  dialCode: '+1246', flag: '🇧🇧', countryCode: 'BB', name: 'Barbados'
}, {
  dialCode: '+1264', flag: '🇦🇮', countryCode: 'AI', name: 'Anguilla'
}, {
  dialCode: '+1268', flag: '🇦🇬', countryCode: 'AG', name: 'Antigua and Barbuda'
}, {
  dialCode: '+1284', flag: '🇻🇬', countryCode: 'VG', name: 'Virgin Islands, British'
}, {
  dialCode: '+1340', flag: '🇻🇮', countryCode: 'VI', name: 'Virgin Islands, U.S.'
}, {
  dialCode: '+1441', flag: '🇧🇲', countryCode: 'BM', name: 'Bermuda'
}, {
  dialCode: '+1473', flag: '🇬🇩', countryCode: 'GD', name: 'Grenada'
}, {
  dialCode: '+1649', flag: '🇹🇨', countryCode: 'TC', name: 'Turks and Caicos Islands'
}, {
  dialCode: '+1664', flag: '🇲🇸', countryCode: 'MS', name: 'Montserrat'
}, {
  dialCode: '+1670', flag: '🇲🇵', countryCode: 'MP', name: 'Northern Mariana Islands'
}, {
  dialCode: '+1671', flag: '🇬🇺', countryCode: 'GU', name: 'Guam'
}, {
  dialCode: '+1684', flag: '🇦🇸', countryCode: 'AS', name: 'American Samoa'
}, {
  dialCode: '+1758', flag: '🇱🇨', countryCode: 'LC', name: 'Saint Lucia'
}, {
  dialCode: '+1767', flag: '🇩🇲', countryCode: 'DM', name: 'Dominica'
}, {
  dialCode: '+1784', flag: '🇻🇨', countryCode: 'VC', name: 'Saint Vincent and the Grenadines'
}, {
  dialCode: '+1849', flag: '🇩🇴', countryCode: 'DO', name: 'Dominican Republic'
}, {
  dialCode: '+1868', flag: '🇹🇹', countryCode: 'TT', name: 'Trinidad and Tobago'
}, {
  dialCode: '+1869', flag: '🇰🇳', countryCode: 'KN', name: 'Saint Kitts and Nevis'
}, {
  dialCode: '+1876', flag: '🇯🇲', countryCode: 'JM', name: 'Jamaica'
}, {
  dialCode: '+1939', flag: '🇵🇷', countryCode: 'PR', name: 'Puerto Rico'
}, {
  dialCode: '+20', flag: '🇪🇬', countryCode: 'EG', name: 'Egypt'
}, {
  dialCode: '+211', flag: '🇸🇸', countryCode: 'SS', name: 'South Sudan'
}, {
  dialCode: '+212', flag: '🇲🇦', countryCode: 'MA', name: 'Morocco'
}, {
  dialCode: '+213', flag: '🇩🇿', countryCode: 'DZ', name: 'Algeria'
}, {
  dialCode: '+216', flag: '🇹🇳', countryCode: 'TN', name: 'Tunisia'
}, {
  dialCode: '+218', flag: '🇱🇾', countryCode: 'LY', name: 'Libyan Arab Jamahiriya'
}, {
  dialCode: '+220', flag: '🇬🇲', countryCode: 'GM', name: 'Gambia'
}, {
  dialCode: '+221', flag: '🇸🇳', countryCode: 'SN', name: 'Senegal'
}, {
  dialCode: '+222', flag: '🇲🇷', countryCode: 'MR', name: 'Mauritania'
}, {
  dialCode: '+223', flag: '🇲🇱', countryCode: 'ML', name: 'Mali'
}, {
  dialCode: '+224', flag: '🇬🇳', countryCode: 'GN', name: 'Guinea'
}, {
  dialCode: '+225', flag: '🇨🇮', countryCode: 'CI', name: "Cote d'Ivoire"
}, {
  dialCode: '+226', flag: '🇧🇫', countryCode: 'BF', name: 'Burkina Faso'
}, {
  dialCode: '+227', flag: '🇳🇪', countryCode: 'NE', name: 'Niger'
}, {
  dialCode: '+228', flag: '🇹🇬', countryCode: 'TG', name: 'Togo'
}, {
  dialCode: '+229', flag: '🇧🇯', countryCode: 'BJ', name: 'Benin'
}, {
  dialCode: '+230', flag: '🇲🇺', countryCode: 'MU', name: 'Mauritius'
}, {
  dialCode: '+231', flag: '🇱🇷', countryCode: 'LR', name: 'Liberia'
}, {
  dialCode: '+232', flag: '🇸🇱', countryCode: 'SL', name: 'Sierra Leone'
}, {
  dialCode: '+233', flag: '🇬🇭', countryCode: 'GH', name: 'Ghana'
}, {
  dialCode: '+234', flag: '🇳🇬', countryCode: 'NG', name: 'Nigeria'
}, {
  dialCode: '+235', flag: '🇹🇩', countryCode: 'TD', name: 'Chad'
}, {
  dialCode: '+236', flag: '🇨🇫', countryCode: 'CF', name: 'Central African Republic'
}, {
  dialCode: '+237', flag: '🇨🇲', countryCode: 'CM', name: 'Cameroon'
}, {
  dialCode: '+238', flag: '🇨🇻', countryCode: 'CV', name: 'Cape Verde'
}, {
  dialCode: '+239', flag: '🇸🇹', countryCode: 'ST', name: 'Sao Tome and Principe'
}, {
  dialCode: '+240', flag: '🇬🇶', countryCode: 'GQ', name: 'Equatorial Guinea'
}, {
  dialCode: '+241', flag: '🇬🇦', countryCode: 'GA', name: 'Gabon'
}, {
  dialCode: '+242', flag: '🇨🇬', countryCode: 'CG', name: 'Congo'
}, {
  dialCode: '+243', flag: '🇨🇩', countryCode: 'CD', name: 'Congo, The Democratic Republic of the Congo'
}, {
  dialCode: '+244', flag: '🇦🇴', countryCode: 'AO', name: 'Angola'
}, {
  dialCode: '+245', flag: '🇬🇼', countryCode: 'GW', name: 'Guinea-Bissau'
}, {
  dialCode: '+246', flag: '🇮🇴', countryCode: 'IO', name: 'British Indian Ocean Territory'
}, {
  dialCode: '+248', flag: '🇸🇨', countryCode: 'SC', name: 'Seychelles'
}, {
  dialCode: '+249', flag: '🇸🇩', countryCode: 'SD', name: 'Sudan'
}, {
  dialCode: '+250', flag: '🇷🇼', countryCode: 'RW', name: 'Rwanda'
}, {
  dialCode: '+251', flag: '🇪🇹', countryCode: 'ET', name: 'Ethiopia'
}, {
  dialCode: '+252', flag: '🇸🇴', countryCode: 'SO', name: 'Somalia'
}, {
  dialCode: '+253', flag: '🇩🇯', countryCode: 'DJ', name: 'Djibouti'
}, {
  dialCode: '+254', flag: '🇰🇪', countryCode: 'KE', name: 'Kenya'
}, {
  dialCode: '+255', flag: '🇹🇿', countryCode: 'TZ', name: 'Tanzania, United Republic of Tanzania'
}, {
  dialCode: '+256', flag: '🇺🇬', countryCode: 'UG', name: 'Uganda'
}, {
  dialCode: '+257', flag: '🇧🇮', countryCode: 'BI', name: 'Burundi'
}, {
  dialCode: '+258', flag: '🇲🇿', countryCode: 'MZ', name: 'Mozambique'
}, {
  dialCode: '+260', flag: '🇿🇲', countryCode: 'ZM', name: 'Zambia'
}, {
  dialCode: '+261', flag: '🇲🇬', countryCode: 'MG', name: 'Madagascar'
}, {
  dialCode: '+262', flag: '🇹🇫', countryCode: 'TF', name: 'French Southern Territories'
}, {
  dialCode: '+262', flag: '🇾🇹', countryCode: 'YT', name: 'Mayotte'
}, {
  dialCode: '+262', flag: '🇷🇪', countryCode: 'RE', name: 'Reunion'
}, {
  dialCode: '+263', flag: '🇿🇼', countryCode: 'ZW', name: 'Zimbabwe'
}, {
  dialCode: '+264', flag: '🇳🇦', countryCode: 'NA', name: 'Namibia'
}, {
  dialCode: '+265', flag: '🇲🇼', countryCode: 'MW', name: 'Malawi'
}, {
  dialCode: '+266', flag: '🇱🇸', countryCode: 'LS', name: 'Lesotho'
}, {
  dialCode: '+267', flag: '🇧🇼', countryCode: 'BW', name: 'Botswana'
}, {
  dialCode: '+268', flag: '🇸🇿', countryCode: 'SZ', name: 'Swaziland'
}, {
  dialCode: '+269', flag: '🇰🇲', countryCode: 'KM', name: 'Comoros'
}, {
  dialCode: '+27', flag: '🇿🇦', countryCode: 'ZA', name: 'South Africa'
}, {
  dialCode: '+290', flag: '🇸🇭', countryCode: 'SH', name: 'Saint Helena, Ascension and Tristan Da Cunha'
}, {
  dialCode: '+291', flag: '🇪🇷', countryCode: 'ER', name: 'Eritrea'
}, {
  dialCode: '+297', flag: '🇦🇼', countryCode: 'AW', name: 'Aruba'
}, {
  dialCode: '+298', flag: '🇫🇴', countryCode: 'FO', name: 'Faroe Islands'
}, {
  dialCode: '+299', flag: '🇬🇱', countryCode: 'GL', name: 'Greenland'
}, {
  dialCode: '+30', flag: '🇬🇷', countryCode: 'GR', name: 'Greece'
}, {
  dialCode: '+31', flag: '🇳🇱', countryCode: 'NL', name: 'Netherlands'
}, {
  dialCode: '+32', flag: '🇧🇪', countryCode: 'BE', name: 'Belgium'
}, {
  dialCode: '+33', flag: '🇫🇷', countryCode: 'FR', name: 'France'
}, {
  dialCode: '+34', flag: '🇪🇸', countryCode: 'ES', name: 'Spain'
}, {
  dialCode: '+345', flag: '🇰🇾', countryCode: 'KY', name: 'Cayman Islands'
}, {
  dialCode: '+350', flag: '🇬🇮', countryCode: 'GI', name: 'Gibraltar'
}, {
  dialCode: '+351', flag: '🇵🇹', countryCode: 'PT', name: 'Portugal'
}, {
  dialCode: '+352', flag: '🇱🇺', countryCode: 'LU', name: 'Luxembourg'
}, {
  dialCode: '+353', flag: '🇮🇪', countryCode: 'IE', name: 'Ireland'
}, {
  dialCode: '+354', flag: '🇮🇸', countryCode: 'IS', name: 'Iceland'
}, {
  dialCode: '+355', flag: '🇦🇱', countryCode: 'AL', name: 'Albania'
}, {
  dialCode: '+356', flag: '🇲🇹', countryCode: 'MT', name: 'Malta'
}, {
  dialCode: '+357', flag: '🇨🇾', countryCode: 'CY', name: 'Cyprus'
}, {
  dialCode: '+358', flag: '🇦🇽', countryCode: 'AX', name: 'Åland Islands'
}, {
  dialCode: '+358', flag: '🇫🇮', countryCode: 'FI', name: 'Finland'
}, {
  dialCode: '+359', flag: '🇧🇬', countryCode: 'BG', name: 'Bulgaria'
}, {
  dialCode: '+36', flag: '🇭🇺', countryCode: 'HU', name: 'Hungary'
}, {
  dialCode: '+370', flag: '🇱🇹', countryCode: 'LT', name: 'Lithuania'
}, {
  dialCode: '+371', flag: '🇱🇻', countryCode: 'LV', name: 'Latvia'
}, {
  dialCode: '+372', flag: '🇪🇪', countryCode: 'EE', name: 'Estonia'
}, {
  dialCode: '+373', flag: '🇲🇩', countryCode: 'MD', name: 'Moldova'
}, {
  dialCode: '+374', flag: '🇦🇲', countryCode: 'AM', name: 'Armenia'
}, {
  dialCode: '+375', flag: '🇧🇾', countryCode: 'BY', name: 'Belarus'
}, {
  dialCode: '+376', flag: '🇦🇩', countryCode: 'AD', name: 'Andorra'
}, {
  dialCode: '+377', flag: '🇲🇨', countryCode: 'MC', name: 'Monaco'
}, {
  dialCode: '+378', flag: '🇸🇲', countryCode: 'SM', name: 'San Marino'
}, {
  dialCode: '+379', flag: '🇻🇦', countryCode: 'VA', name: 'Holy See (Vatican City State)'
}, {
  dialCode: '+380', flag: '🇺🇦', countryCode: 'UA', name: 'Ukraine'
}, {
  dialCode: '+381', flag: '🇷🇸', countryCode: 'RS', name: 'Serbia'
}, {
  dialCode: '+382', flag: '🇲🇪', countryCode: 'ME', name: 'Montenegro'
}, {
  dialCode: '+383', flag: '🇽🇰', countryCode: 'XK', name: 'Kosovo'
}, {
  dialCode: '+385', flag: '🇭🇷', countryCode: 'HR', name: 'Croatia'
}, {
  dialCode: '+386', flag: '🇸🇮', countryCode: 'SI', name: 'Slovenia'
}, {
  dialCode: '+387', flag: '🇧🇦', countryCode: 'BA', name: 'Bosnia and Herzegovina'
}, {
  dialCode: '+389', flag: '🇲🇰', countryCode: 'MK', name: 'Macedonia'
}, {
  dialCode: '+39', flag: '🇮🇹', countryCode: 'IT', name: 'Italy'
}, {
  dialCode: '+40', flag: '🇷🇴', countryCode: 'RO', name: 'Romania'
}, {
  dialCode: '+41', flag: '🇨🇭', countryCode: 'CH', name: 'Switzerland'
}, {
  dialCode: '+420', flag: '🇨🇿', countryCode: 'CZ', name: 'Czech Republic'
}, {
  dialCode: '+421', flag: '🇸🇰', countryCode: 'SK', name: 'Slovakia'
}, {
  dialCode: '+423', flag: '🇱🇮', countryCode: 'LI', name: 'Liechtenstein'
}, {
  dialCode: '+43', flag: '🇦🇹', countryCode: 'AT', name: 'Austria'
}, {
  dialCode: '+44', flag: '🇬🇬', countryCode: 'GG', name: 'Guernsey'
}, {
  dialCode: '+44', flag: '🇮🇲', countryCode: 'IM', name: 'Isle of Man'
}, {
  dialCode: '+44', flag: '🇯🇪', countryCode: 'JE', name: 'Jersey'
}, {
  dialCode: '+44', flag: '🇬🇧', countryCode: 'GB', name: 'United Kingdom'
}, {
  dialCode: '+45', flag: '🇩🇰', countryCode: 'DK', name: 'Denmark'
}, {
  dialCode: '+46', flag: '🇸🇪', countryCode: 'SE', name: 'Sweden'
}, {
  dialCode: '+47', flag: '🇧🇻', countryCode: 'BV', name: 'Bouvet Island'
}, {
  dialCode: '+47', flag: '🇳🇴', countryCode: 'NO', name: 'Norway'
}, {
  dialCode: '+47', flag: '🇸🇯', countryCode: 'SJ', name: 'Svalbard and Jan Mayen'
}, {
  dialCode: '+48', flag: '🇵🇱', countryCode: 'PL', name: 'Poland'
}, {
  dialCode: '+49', flag: '🇩🇪', countryCode: 'DE', name: 'Germany'
}, {
  dialCode: '+500', flag: '🇫🇰', countryCode: 'FK', name: 'Falkland Islands (Malvinas)'
}, {
  dialCode: '+500', flag: '🇬🇸', countryCode: 'GS', name: 'South Georgia and the South Sandwich Islands'
}, {
  dialCode: '+501', flag: '🇧🇿', countryCode: 'BZ', name: 'Belize'
}, {
  dialCode: '+502', flag: '🇬🇹', countryCode: 'GT', name: 'Guatemala'
}, {
  dialCode: '+503', flag: '🇸🇻', countryCode: 'SV', name: 'El Salvador'
}, {
  dialCode: '+504', flag: '🇭🇳', countryCode: 'HN', name: 'Honduras'
}, {
  dialCode: '+505', flag: '🇳🇮', countryCode: 'NI', name: 'Nicaragua'
}, {
  dialCode: '+506', flag: '🇨🇷', countryCode: 'CR', name: 'Costa Rica'
}, {
  dialCode: '+507', flag: '🇵🇦', countryCode: 'PA', name: 'Panama'
}, {
  dialCode: '+508', flag: '🇵🇲', countryCode: 'PM', name: 'Saint Pierre and Miquelon'
}, {
  dialCode: '+509', flag: '🇭🇹', countryCode: 'HT', name: 'Haiti'
}, {
  dialCode: '+51', flag: '🇵🇪', countryCode: 'PE', name: 'Peru'
}, {
  dialCode: '+52', flag: '🇲🇽', countryCode: 'MX', name: 'Mexico'
}, {
  dialCode: '+53', flag: '🇨🇺', countryCode: 'CU', name: 'Cuba'
}, {
  dialCode: '+54', flag: '🇦🇷', countryCode: 'AR', name: 'Argentina'
}, {
  dialCode: '+55', flag: '🇧🇷', countryCode: 'BR', name: 'Brazil'
}, {
  dialCode: '+56', flag: '🇨🇱', countryCode: 'CL', name: 'Chile'
}, {
  dialCode: '+57', flag: '🇨🇴', countryCode: 'CO', name: 'Colombia'
}, {
  dialCode: '+58', flag: '🇻🇪', countryCode: 'VE', name: 'Venezuela, Bolivarian Republic of Venezuela'
}, {
  dialCode: '+590', flag: '🇬🇵', countryCode: 'GP', name: 'Guadeloupe'
}, {
  dialCode: '+590', flag: '🇧🇱', countryCode: 'BL', name: 'Saint Barthelemy'
}, {
  dialCode: '+590', flag: '🇲🇫', countryCode: 'MF', name: 'Saint Martin'
}, {
  dialCode: '+591', flag: '🇧🇴', countryCode: 'BO', name: 'Bolivia, Plurinational State of bolivia'
}, {
  dialCode: '+592', flag: '🇬🇾', countryCode: 'GY', name: 'Guyana'
}, {
  dialCode: '+593', flag: '🇪🇨', countryCode: 'EC', name: 'Ecuador'
}, {
  dialCode: '+594', flag: '🇬🇫', countryCode: 'GF', name: 'French Guiana'
}, {
  dialCode: '+595', flag: '🇵🇾', countryCode: 'PY', name: 'Paraguay'
}, {
  dialCode: '+596', flag: '🇲🇶', countryCode: 'MQ', name: 'Martinique'
}, {
  dialCode: '+597', flag: '🇸🇷', countryCode: 'SR', name: 'Suriname'
}, {
  dialCode: '+598', flag: '🇺🇾', countryCode: 'UY', name: 'Uruguay'
}, {
  dialCode: '+599', flag: '', countryCode: 'AN', name: 'Netherlands Antilles'
}, {
  dialCode: '+60', flag: '🇲🇾', countryCode: 'MY', name: 'Malaysia'
}, {
  dialCode: '+61', flag: '🇦🇺', countryCode: 'AU', name: 'Australia'
}, {
  dialCode: '+61', flag: '🇨🇽', countryCode: 'CX', name: 'Christmas Island'
}, {
  dialCode: '+61', flag: '🇨🇨', countryCode: 'CC', name: 'Cocos (Keeling) Islands'
}, {
  dialCode: '+62', flag: '🇮🇩', countryCode: 'ID', name: 'Indonesia'
}, {
  dialCode: '+63', flag: '🇵🇭', countryCode: 'PH', name: 'Philippines'
}, {
  dialCode: '+64', flag: '🇳🇿', countryCode: 'NZ', name: 'New Zealand'
}, {
  dialCode: '+64', flag: '🇵🇳', countryCode: 'PN', name: 'Pitcairn'
}, {
  dialCode: '+65', flag: '🇸🇬', countryCode: 'SG', name: 'Singapore'
}, {
  dialCode: '+66', flag: '🇹🇭', countryCode: 'TH', name: 'Thailand'
}, {
  dialCode: '+670', flag: '🇹🇱', countryCode: 'TL', name: 'Timor-Leste'
}, {
  dialCode: '+672', flag: '🇦🇶', countryCode: 'AQ', name: 'Antarctica'
}, {
  dialCode: '+672', flag: '🇭🇲', countryCode: 'HM', name: 'Heard Island and Mcdonald Islands'
}, {
  dialCode: '+672', flag: '🇳🇫', countryCode: 'NF', name: 'Norfolk Island'
}, {
  dialCode: '+673', flag: '🇧🇳', countryCode: 'BN', name: 'Brunei Darussalam'
}, {
  dialCode: '+674', flag: '🇳🇷', countryCode: 'NR', name: 'Nauru'
}, {
  dialCode: '+675', flag: '🇵🇬', countryCode: 'PG', name: 'Papua New Guinea'
}, {
  dialCode: '+676', flag: '🇹🇴', countryCode: 'TO', name: 'Tonga'
}, {
  dialCode: '+677', flag: '🇸🇧', countryCode: 'SB', name: 'Solomon Islands'
}, {
  dialCode: '+678', flag: '🇻🇺', countryCode: 'VU', name: 'Vanuatu'
}, {
  dialCode: '+679', flag: '🇫🇯', countryCode: 'FJ', name: 'Fiji'
}, {
  dialCode: '+680', flag: '🇵🇼', countryCode: 'PW', name: 'Palau'
}, {
  dialCode: '+681', flag: '🇼🇫', countryCode: 'WF', name: 'Wallis and Futuna'
}, {
  dialCode: '+682', flag: '🇨🇰', countryCode: 'CK', name: 'Cook Islands'
}, {
  dialCode: '+683', flag: '🇳🇺', countryCode: 'NU', name: 'Niue'
}, {
  dialCode: '+685', flag: '🇼🇸', countryCode: 'WS', name: 'Samoa'
}, {
  dialCode: '+686', flag: '🇰🇮', countryCode: 'KI', name: 'Kiribati'
}, {
  dialCode: '+687', flag: '🇳🇨', countryCode: 'NC', name: 'New Caledonia'
}, {
  dialCode: '+688', flag: '🇹🇻', countryCode: 'TV', name: 'Tuvalu'
}, {
  dialCode: '+689', flag: '🇵🇫', countryCode: 'PF', name: 'French Polynesia'
}, {
  dialCode: '+690', flag: '🇹🇰', countryCode: 'TK', name: 'Tokelau'
}, {
  dialCode: '+691', flag: '🇫🇲', countryCode: 'FM', name: 'Micronesia, Federated States of Micronesia'
}, {
  dialCode: '+692', flag: '🇲🇭', countryCode: 'MH', name: 'Marshall Islands'
}, {
  dialCode: '+7', flag: '🇰🇿', countryCode: 'KZ', name: 'Kazakhstan'
}, {
  dialCode: '+7', flag: '🇷🇺', countryCode: 'RU', name: 'Russia'
}, {
  dialCode: '+81', flag: '🇯🇵', countryCode: 'JP', name: 'Japan'
}, {
  dialCode: '+82', flag: '🇰🇷', countryCode: 'KR', name: 'Korea, Republic of South Korea'
}, {
  dialCode: '+84', flag: '🇻🇳', countryCode: 'VN', name: 'Vietnam'
}, {
  dialCode: '+850', flag: '🇰🇵', countryCode: 'KP', name: "Korea, Democratic People's Republic of Korea"
}, {
  dialCode: '+852', flag: '🇭🇰', countryCode: 'HK', name: 'Hong Kong'
}, {
  dialCode: '+853', flag: '🇲🇴', countryCode: 'MO', name: 'Macao'
}, {
  dialCode: '+855', flag: '🇰🇭', countryCode: 'KH', name: 'Cambodia'
}, {
  dialCode: '+856', flag: '🇱🇦', countryCode: 'LA', name: 'Laos'
}, {
  dialCode: '+86', flag: '🇨🇳', countryCode: 'CN', name: 'China'
}, {
  dialCode: '+880', flag: '🇧🇩', countryCode: 'BD', name: 'Bangladesh'
}, {
  dialCode: '+886', flag: '🇹🇼', countryCode: 'TW', name: 'Taiwan'
}, {
  dialCode: '+90', flag: '🇹🇷', countryCode: 'TR', name: 'Turkey'
}, {
  dialCode: '+91', flag: '🇮🇳', countryCode: 'IN', name: 'India'
}, {
  dialCode: '+92', flag: '🇵🇰', countryCode: 'PK', name: 'Pakistan'
}, {
  dialCode: '+93', flag: '🇦🇫', countryCode: 'AF', name: 'Afghanistan'
}, {
  dialCode: '+94', flag: '🇱🇰', countryCode: 'LK', name: 'Sri Lanka'
}, {
  dialCode: '+95', flag: '🇲🇲', countryCode: 'MM', name: 'Myanmar'
}, {
  dialCode: '+960', flag: '🇲🇻', countryCode: 'MV', name: 'Maldives'
}, {
  dialCode: '+961', flag: '🇱🇧', countryCode: 'LB', name: 'Lebanon'
}, {
  dialCode: '+962', flag: '🇯🇴', countryCode: 'JO', name: 'Jordan'
}, {
  dialCode: '+963', flag: '🇸🇾', countryCode: 'SY', name: 'Syrian Arab Republic'
}, {
  dialCode: '+964', flag: '🇮🇶', countryCode: 'IQ', name: 'Iraq'
}, {
  dialCode: '+965', flag: '🇰🇼', countryCode: 'KW', name: 'Kuwait'
}, {
  dialCode: '+966', flag: '🇸🇦', countryCode: 'SA', name: 'Saudi Arabia'
}, {
  dialCode: '+967', flag: '🇾🇪', countryCode: 'YE', name: 'Yemen'
}, {
  dialCode: '+968', flag: '🇴🇲', countryCode: 'OM', name: 'Oman'
}, {
  dialCode: '+970', flag: '🇵🇸', countryCode: 'PS', name: 'Palestinian Territory, Occupied'
}, {
  dialCode: '+971', flag: '🇦🇪', countryCode: 'AE', name: 'United Arab Emirates'
}, {
  dialCode: '+972', flag: '🇮🇱', countryCode: 'IL', name: 'Israel'
}, {
  dialCode: '+973', flag: '🇧🇭', countryCode: 'BH', name: 'Bahrain'
}, {
  dialCode: '+974', flag: '🇶🇦', countryCode: 'QA', name: 'Qatar'
}, {
  dialCode: '+975', flag: '🇧🇹', countryCode: 'BT', name: 'Bhutan'
}, {
  dialCode: '+976', flag: '🇲🇳', countryCode: 'MN', name: 'Mongolia'
}, {
  dialCode: '+977', flag: '🇳🇵', countryCode: 'NP', name: 'Nepal'
}, {
  dialCode: '+98', flag: '🇮🇷', countryCode: 'IR', name: 'Iran, Islamic Republic of Persian Gulf'
}, {
  dialCode: '+992', flag: '🇹🇯', countryCode: 'TJ', name: 'Tajikistan'
}, {
  dialCode: '+993', flag: '🇹🇲', countryCode: 'TM', name: 'Turkmenistan'
}, {
  dialCode: '+994', flag: '🇦🇿', countryCode: 'AZ', name: 'Azerbaijan'
}, {
  dialCode: '+995', flag: '🇬🇪', countryCode: 'GE', name: 'Georgia'
}, {
  dialCode: '+996', flag: '🇰🇬', countryCode: 'KG', name: 'Kyrgyzstan'
}, {
  dialCode: '+998', flag: '🇺🇿', countryCode: 'UZ', name: 'Uzbekistan'
}];
export default CountryCodes;
