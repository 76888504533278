import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';
import RoundButton from 'src/components/RoundButton';
import InputSC from 'src/views/menu/InputSC';

const useStyles = makeStyles(() => ({
  formContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  boxButton: {
    marginTop: 30,
  },
  submit: {
    marginTop: 50,
    width: 300,
    alignSelf: 'center',
  },
  submitLabel: {
    fontWeight: '600',
    textTransform: 'none',
  },
}));

const SetPassword = ({ data, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Confirm password is required'),
      })}
      onSubmit={(values, actions) => {
        const { password, confirmPassword } = values;
        if (password !== confirmPassword) {
          actions.setErrors({ confirmPassword: 'Confirm password mismatched' });
          return;
        }
        if (onSubmit) onSubmit({ password: values.password, email: data.email });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form className={classes.formContent} onSubmit={handleSubmit}>
          <InputSC
            className={classes.input}
            label="Email"
            value={`${data.email}`}
            disabled
          />
          <InputSC
            className={classes.input}
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            helperText={errors.password}
          />
          <InputSC
            className={classes.input}
            label="Confirm password"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <Box className={classes.boxButton}>
            <RoundButton type="submit">
              <Typography className={classes.submitLabel}>Continue</Typography>
            </RoundButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

SetPassword.propTypes = {
  data: PropTypes.any,
  onSubmit: PropTypes.func
};

export default SetPassword;
