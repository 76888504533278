import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import colors from 'src/common/colors';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: 297,
    minHeight: 78,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 5,
  },
  title: {
    color: colors.darkGrey,
    fontSize: 15,
    fontWeight: '700'
  },
  pricePoint: {
    fontWeight: '500',
    fontSize: 13,
    lineHeight: '16px'
  },
  desc: {
    color: colors.grey,
    lineHeight: '14px',
    fontSize: 12,
  },
  subDesc: {
    fontSize: 10
  },
  triangle: {
    display: 'block',
    height: 16,
    width: 16,
    backgroundColor: 'inherit',
    border: 'inherit',
    position: 'absolute',
    right: 'calc(50% - 8px)',
    bottom: -8,
    clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
    transform: 'rotate(-45deg)',
    // borderRadius: '0 0 0 0.25em'
  },
  buttonForward: {
    cursor: 'pointer',
  },
  iconForward: {
    '&:hover': {
      filter: 'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)'
    }
  }
}));

const InfoWindow = ({ data, onClickForward, style }) => {
  const classes = useStyles();
  const logoURL = data?.owner?.operation?.logoURL;
  const address = data?.address ?? '';
  return (
    <Box className={classes.container} style={style} align="center" justifyContent="center">
      <table width="100%">
        <tbody>
          <tr>
            <td>
              {logoURL && <img alt="" width="55" height="55" src={logoURL} />}
            </td>
            <td width="99%">
              <table width="100%">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Typography className={classes.title}>{data?.owner?.businessName}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Typography className={classes.desc}>{address}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" align="center">
                      <Typography className={classes.subDesc}>
                        {data.owner?.cuisine?.title}
                      </Typography>
                    </td>
                    <td width="50%" align="center">
                      <span className={classes.pricePoint}>
                        {'$'.repeat(data.owner?.operation.pricePoint)}
                      </span>
                      <span className={classes.pricePoint} style={{ color: colors.grey }}>
                        {'$'.repeat(4 - data?.owner?.operation?.pricePoint)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <Box onClick={onClickForward} className={classes.buttonForward}>
                <img
                  alt=""
                  width="25"
                  height="25"
                  src="/static/images/icon_forward.png"
                  className={classes.iconForward}
                />
              </Box>
            </td>
          </tr>
        </tbody>
      </table>
      <span className={classes.triangle} />
    </Box>
  );
};

InfoWindow.propTypes = {
  style: PropTypes.object,
  data: PropTypes.any,
  onClickForward: PropTypes.func
};

export default InfoWindow;
