import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  name: {
    display: 'flex',
    flexGrow: 1,
    fontSize: 15,
    fontWeight: '700',
  },
  price: {
    marginLeft: 8,
  },
  desc: {
    color: colors.holoBlack,
    fontSize: 14,
    textAlign: 'left',
  }
}));

const Food = ({
  name,
  price,
  desc,
}) => {
  const classes = useStyles();
  return (
    <Box m={1} ml={1} style={{ maxWidth: 400 }}>
      <Box display="flex" flexDirection="row">
        <Typography className={classes.name}>{name || ''}</Typography>
        <Typography className={classes.price}>{price ? `$${Number(price).toFixed(2)}` : ''}</Typography>
      </Box>
      <Typography className={classes.desc}>{desc || ''}</Typography>
    </Box>
  );
};

Food.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  desc: PropTypes.string,
};

export default Food;
