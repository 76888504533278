import React from 'react';

const LogoTreadMeal = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/images/treadmeal_logo.png"
      width={181}
      {...props}
    />
  );
};

export default LogoTreadMeal;
