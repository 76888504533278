import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import Uploader from './Uploader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Promotions = () => {
  const classes = useStyles();
  const location = useLocation();
  const [promotions, setPromotions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleForm, showForm] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getPromotions = () => APIService.getPromotions((success, json) => {
    if (success && json.result) {
      setPromotions(json.result);
    }
  });

  const deleteItems = () => APIService.deletePromotions(selectedItems, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getPromotions();
    }
  });

  useEffect(() => {
    getPromotions();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Promotions"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onAdd={() => showForm(true)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => deleteItems() : null}
        />
        <Box mt={3}>
          <Results
            data={promotions}
            searchText={searchText}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
          />
        </Box>
        <Uploader
          visible={visibleForm}
          onClose={() => showForm(false)}
          onSuccess={() => getPromotions()}
        />
      </Container>
    </Page>
  );
};

export default Promotions;
