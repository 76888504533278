/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    display: 'none',
  },
  label: {
    cursor: 'pointer',
  }
}));

export default function UploadButton({ onChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onChange} />
      <label htmlFor="icon-button-file" className={classes.label}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
          <Typography>Choose your image</Typography>
        </Box>
      </label>
    </Box>
  );
}

UploadButton.propTypes = {
  onChange: PropTypes.func,
};
