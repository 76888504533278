import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import CardTitle from 'src/components/CardTitle';
import { Storefront } from '@material-ui/icons';
import colors from 'src/common/colors';

const useStyles = makeStyles(({
  root: {}
}));

const StoreSetting = ({
  className, data, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    APP_STORE_URL: '',
    PLAY_STORE_URL: '',
  });
  const dispatch = useDispatch();

  const bindData = (e) => {
    setValues({
      APP_STORE_URL: e.APP_STORE_URL?.value || '',
      PLAY_STORE_URL: e.PLAY_STORE_URL?.value || '',
    });
  };

  useEffect(() => {
    bindData(data);
  }, []);

  // const getWebhookList = () => {
  //   APIService.
  // }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const keys = ['APP_STORE_URL', 'PLAY_STORE_URL'];
    keys.forEach((k) => {
      if (!data[k]) {
        data[k] = {
          key: k,
          title: k,
          value: values[k],
        };
      } else {
        data[k].value = values[k];
      }
    });
    const redata = keys.map((k) => data[k]);

    APIService.saveSettings(redata, (success, json) => {
      if (success && json.result) {
        bindData(data);
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Saved successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
    });
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title={(<CardTitle title="STORE" icon={<Storefront style={{ color: colors.headingTeal }} />} />)}
          subheader="Android and iOS store"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="AppStore URL"
            margin="normal"
            name="APP_STORE_URL"
            onChange={handleChange}
            value={values.APP_STORE_URL}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="PlayStore URL"
            margin="normal"
            name="PLAY_STORE_URL"
            onChange={handleChange}
            value={values.PLAY_STORE_URL}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

StoreSetting.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default StoreSetting;
