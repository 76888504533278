/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyB6h63JE_f4GQJagtN6F0s3Z9Zb-ySLOQE',
  authDomain: 'mobilehybriddev.firebaseapp.com',
  databaseURL: 'https://mobilehybriddev.firebaseio.com',
  projectId: 'mobilehybriddev',
  storageBucket: 'mobilehybriddev.appspot.com',
  messagingSenderId: '895905560361',
  appId: '1:895905560361:web:0b5fb65882452185302938',
  measurementId: 'G-KCV3EJE2KR',
};

firebase.initializeApp(firebaseConfig);

export const getToken = (setToken) => {
  return firebase.messaging().getToken({ vapidKey: 'BE1GgQNnAChvFGKXXL8GndVMWs5ZMCnr2gjHkyl9tfh-GWpj3WhFDVuT_buKHZgnz28lceYWEwp5vCCmLC2bMnw' }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setToken(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setToken();
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
};

export const onMessageListener = () => new Promise((resolve) => {
  firebase.messaging().onMessage((payload) => {
    resolve(payload);
  });
});
