import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import colors from 'src/common/colors';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import { Cancel, Payment } from '@material-ui/icons';
import APIService from 'src/utils/APIService';
import moment from 'moment';
import TextHeading from 'src/components/TextHeading';
import { SubscriptionStatus } from 'src/utils/Settings';
import ProfileDialog from './ProfileDialog';

const useStyles = makeStyles(() => ({
  root: {},
  heading: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.headingTeal,
  }
}));

const SubscriptionInfo = ({
  className, user, ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const getSubscriptionInfo = () => {
    APIService.getTruckOwnerSubscriptionDetail(user.id, (success, json) => {
      if (success && json.result) {
        setSubscription(json.result);
      }
    });
  };

  const reSubscription = () => {
    APIService.adminReSubscription(user.id, (success, json) => {
      if (success && json.result) {
        setSubscription({ ...subscription, status: 'ACTIVE' });
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Resubscribed successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
      setDialogVisible(false);
    });
  };

  const cancelSubscription = () => {
    APIService.adminCancelSubscription(subscription.subscriptionId, (success, json) => {
      if (success && json.result) {
        setSubscription({ ...subscription, status: 'CANCELLED' });
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Canceled subscription successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: 'Failed to cancel subscription!' }));
      }
      setDialogVisible(false);
    });
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, [user]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        avatar={<Box width={20} />}
        title={<TextHeading variant="h6" className={classes.heading}>Subscription</TextHeading>}
        className={classes.heading}
        action={subscription.status !== SubscriptionStatus.CANCELLED ? (
          <Tooltip title="Cancel subscription">
            <IconButton onClick={() => { setDialogVisible(true); }}>
              <Cancel style={{ color: colors.red }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Resubscribe">
            <IconButton onClick={() => { setDialogVisible(true); }}>
              <Payment style={{ color: colors.blue }} />
            </IconButton>
          </Tooltip>
        )}
      />
      <CardContent style={{ justifyContent: 'center' }}>
        <Grid
          container
          align="center"
          spacing={3}
        >
          <Grid item md={12}>
            <Typography>
              {`Id: ${subscription.subscriptionId}`}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography>
              {`Payment type: ${subscription.paymentType}`}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography>
              {`Status: ${subscription.status}`}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography>
              {`Start at: ${moment(subscription.periodStart).format('DD/MM/YYYY HH:mm')}`}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography>
              {`End at: ${moment(subscription.periodEnd).format('DD/MM/YYYY HH:mm')}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <ProfileDialog
        visible={dialogVisible}
        onClose={() => { setDialogVisible(false); }}
        onSubmit={
          subscription.status !== SubscriptionStatus.CANCELLED
            ? cancelSubscription
            : reSubscription
        }
      >
        {subscription.status !== SubscriptionStatus.CANCELLED
          ? <Typography>Do you want to cancel this subscription?</Typography>
          : (
            <>
              <Typography>
                The last subscription is valid until
                {` ${moment(subscription.periodEnd).format('DD/MM/YYYY - hh:mm a')}`}
              </Typography>
              <Typography>
                If you resubscribe now, the remaining subscription will be removed
                and the customer will be charged immediately for new subscription.
              </Typography>
              <Typography>
                Do you want to proceed?
              </Typography>
            </>
          )}
        <Grid spacing={1} container justify="flex-end">
          <Grid item>
            <Button
              style={{ backgroundColor: '#e53935', color: 'white' }}
              onClick={subscription.status !== SubscriptionStatus.CANCELLED
                ? cancelSubscription
                : reSubscription}
            >
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: '#E6E6E6', color: 'black' }}
              onClick={() => { setDialogVisible(false); }}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </ProfileDialog>
    </Card>
  );
};

SubscriptionInfo.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

export default inject('dataStore')(observer(SubscriptionInfo));
