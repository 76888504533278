import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import Page from 'src/components/Page';
import UserStatsChart from './UserStatsChart';
import TotalCustomers from './TotalCustomers';
import PlatformStats from './PlatformStats';
import ActiveUserStats from './ActiveUserStats';
import CacheStats from './CacheStats';
import TrafficHits from './TrafficHits';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [cache, setCache] = useState({});

  const validateToken = () => {
    const sessionKey = Settings.sessionKey();
    if (!sessionKey) {
      navigate('/', { replace: true });
    }
    APIService.verifyUserToken(sessionKey, (success, json) => {
      if (success && json.result) {
        const { result } = json;
        Settings.setSessionKey(result.token);
      } else {
        Settings.setSessionKey(null);
        navigate('/', { replace: true });
      }
    });
  };

  const fetchCacheStats = () => {
    APIService.getCacheStats((success, json) => {
      if (success && json.result) {
        setCache(json.result);
      }
    });
  };

  const fetchLanguages = () => {
    APIService.getTranslations((success, json) => {
      if (success && json.result) {
        Settings.setTranslations(json.result);
      }
    });
  };

  const fetchStatistics = () => {
    APIService.getDashboard((success, json) => {
      if (success && json.result) {
        setData(json.result);
      }
    });
  };

  useEffect(() => {
    validateToken();
    fetchLanguages();
    fetchStatistics();
    fetchCacheStats();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers data={data} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ActiveUserStats data={data} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CacheStats data={cache} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TrafficHits data={data} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <UserStatsChart data={data} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <PlatformStats data={data} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
