import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, makeStyles, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import colors from 'src/common/colors';
import { Roles } from 'src/utils/Settings';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    paddingTop: 25,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  buttonSignup: {
    // width: '30vw',
    maxWidth: 305,
    minWidth: 150,
    height: 52,
    borderRadius: 16,
    marginLeft: 15,
    marginRight: 15,
    color: colors.white,
    backgroundColor: colors.action,
    '&:hover': {
      backgroundColor: colors.tmGreen,
    }
  },
  buttonTO: {
    marginBottom: 22
  },
  buttonCustomer: {
    marginBottom: 15
  },
  buttonText: {
    textTransform: 'capitalize',
    fontWeight: '600',
  }
}));

const SignUpButton = ({ onSignUp, buttonStyle }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSignUp = (role) => {
    if (onSignUp) onSignUp();
    if (role === Roles.CUSTOMER) {
      navigate('/sign-up/customer');
    } else {
      navigate('/sign-up/truck-owner');
    }
    window.scrollTo(0, 0);
  };

  return (
    <Box className={classes.buttonWrapper}>
      <Button
        className={clsx(classes.buttonSignup, classes.buttonTO, buttonStyle)}
        onClick={() => handleSignUp(Roles.TRUCK_OWNER)}
      >
        <Typography className={classes.buttonText}>
          Food Truck Owner
        </Typography>
      </Button>
      <Button
        className={clsx(classes.buttonSignup, classes.buttonCustomer, buttonStyle)}
        onClick={() => handleSignUp(Roles.CUSTOMER)}
      >
        <Typography className={classes.buttonText}>
          Customer
        </Typography>
      </Button>
    </Box>
  );
};

SignUpButton.propTypes = {
  onSignUp: PropTypes.func,
  buttonStyle: PropTypes.string,
};

export default SignUpButton;
