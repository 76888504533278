import {
  Box,
  // Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  image: {
    backgroundColor: 'white',
    width: 102,
    height: 102,
    minWidth: 102
  },
  container: (props) => ({
    height: 120,
    padding: 5,
    paddingRight: 10,
    width: 420,
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: props.isSelected ? '1px solid #30bc10' : 'none',
  }),
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'

  },
  contentFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  title: {
    display: 'block',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    color: colors.action,
    fontSize: 20,
    maxHeight: 60,
    lineHeight: '24px'
  },
  cuisine: {
    color: colors.action,
    fontSize: 15,
    minWidth: 110,
  },
  openText: (props) => ({
    WebkitTextStrokeColor: '#08605f',
    WebkitTextStrokeWidth: '.1px',
    textShadow: props.isOpen ? '0px 0px 4px #39e214' : 'none',
    color: props.isOpen ? '#30bc10' : colors.grey,
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '18px',
    float: 'right',
  }),
  pricePointWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  pricePoint: {
    fontSize: 15,
    fontWeight: '500',
    lineHeight: '18px'
  },
  triangle: {
    display: 'block',
    height: 20,
    width: 20,
    backgroundColor: 'white',
    border: 'inherit',
    position: 'absolute',
    right: -9.8,
    top: 'calc(50% - 10px)',
    clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
    transform: 'rotate(-135deg)',
    borderRadius: '0 0 0 0.25em',
  }
}));

const TruckItem = ({
  title = 'No title',
  imageURL = '/static/images/sample_avatar.png',
  cuisine,
  pricePoint = 2,
  isOpen = false,
  onClick,
  isSelected = false
}) => {
  const classes = useStyles({ isOpen, isSelected });
  return (
    <Box className={classes.container} onClick={onClick}>
      <Box width="100%" className={classes.wrapper}>
        <img alt="" src={imageURL} className={classes.image} />
        <Box className={classes.content}>
          <Box className={classes.contentWrapper}>
            <Typography className={classes.title}>{title}</Typography>
            <Box className={classes.contentFooter}>
              <Typography className={classes.cuisine}>{cuisine}</Typography>
              <Box className={classes.pricePointWrapper}>
                <Typography className={classes.pricePoint}>{'$'.repeat(pricePoint)}</Typography>
                <Typography className={classes.pricePoint} style={{ color: colors.grey }}>{'$'.repeat(4 - pricePoint)}</Typography>
              </Box>
              <Typography className={classes.openText}>Open</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.triangle} />
    </Box>
  );
};

TruckItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  imageURL: PropTypes.string,
  cuisine: PropTypes.string,
  pricePoint: PropTypes.number,
  isOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default TruckItem;
