import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Container, Typography, makeStyles
} from '@material-ui/core';
import Settings from 'src/utils/Settings';
import APIService from 'src/utils/APIService';
import '../../theme/styles/dynamic.viewer.css';

const useStyles = makeStyles(({
  root: {
    margin: 30,
    minHeight: '70vh'
  },
  title: {
    textAlign: 'center',
    fontSize: 56,
    fontWeight: '600',
  },
  content: {
    marginTop: 30,
  }
}));

const Viewer = ({ type, content }) => {
  const classes = useStyles();
  const { state } = useLocation();

  if (state && state.data) {
    content = state.data.content || '';
  }
  const [html, setContent] = useState(content);
  const [title, setTitle] = useState(state && state.data ? state.data.title : '');

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'privacy':
          APIService.getPrivacyPolicy((success, json) => {
            if (success && json.result) {
              const item = json.result;
              setTitle(item.title);
              setContent(item.content);
            }
          });
          break;
        case 'terms':
          APIService.getTermsAndConditions((success, json) => {
            if (success && json.result) {
              const item = json.result;
              setTitle(item.title);
              setContent(item.content);
            }
          });
          break;
        case 'faqs':
          APIService.getBaseFAQs((success, json) => {
            if (success && json.result) {
              const items = json.result;
              setTitle('FAQs');
              setContent(items.map((item) => `<center><b><p style="font-family: Arial">${item.title}</p></b></center><br/> ${item.content}`).join('\n'));
            }
          });
          break;
        case 'dynamic':
        default:
          content = Settings.dynamicContent();
      }
    }
  });

  return (
    <Box className={classes.root} id="dynamic-viewer">
      <Container maxWidth="md" id="container">
        <Typography className={classes.title}>{title}</Typography>
        <Box
          id="body"
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
    </Box>
  );
};
Viewer.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string
};
export default Viewer;
