import {
  Grid, makeStyles, MenuItem, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import colors from 'src/common/colors';
import CustomSlider from 'src/components/CustomSlider';
import RoundSelect from 'src/components/RoundSelect';
import SearchInput from 'src/components/SearchInput';
import APIService from 'src/utils/APIService';
import { useLocation, useNavigate } from 'react-router-dom';
import RoundButton from 'src/components/RoundButton';
import CustomRadio from './CustomRadio';

const useStyles = makeStyles(() => ({
  searchContainer: {
    width: '100%'
  },
  cuisineContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
  title: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: 18,
    color: '#191A1E'
  },
  cuisineSelecter: {
    width: '100%',
    marginTop: 12,
  },
  selectEmpty: {
    borderBottomWidth: 0

  },
  sortItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    alignSelf: 'center',
    marginTop: 6,
    alignItems: 'center',
    marginBottom: 6,
  },
  sortTitle: {
    flex: 1,
    fontSize: 14,
    color: '#231F20'
  },
  pricePointContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 25,
  },
  distanceContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
  tabContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tabButton: {
    cursor: 'pointer',
    fontSize: 14
  },
  ruler: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15
  },
  button: {
    marginTop: 10,
    textTransform: 'none',
  },
  buttonText: {
    fontWeight: '600'
  }
}));

const sortData = [
  {
    key: 'NAME',
    title: 'Alphabetical',
  },
  {
    key: 'PRICE',
    title: 'Price',
  },
  {
    key: 'DISTANCE',
    title: 'Distance',
  },
];

const tabs = [
  {
    id: 0,
    title: 'List View'
  },
  {
    id: 1,
    title: 'Map View'
  },
];

const FilterTruck = ({ index, onFilter, values }) => {
  const [searchText, setSearchText] = useState(values?.keyword ?? '');
  const [flagSearchText, setflagSearchText] = useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [cuisine, setCuisine] = useState(values?.cuisineId ?? 0);
  const [sortType, setSortType] = useState(values?.sortType ?? 'NAME');
  const [pricePoint, setPricePoint] = useState(values?.pricePoint ?? 4);
  const [distance, setDistance] = useState(values?.distance ?? 4);
  const [tabIndex] = useState(index);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event) => {
    setCuisine(event.target.value);
  };

  const getQueryString = () => {
    return new URLSearchParams({
      keyword: searchText,
      cuisineId: cuisine,
      pricePoint,
      distance,
      sortType
    }).toString();
  };

  const getCuisines = () => APIService.getCuisines((success, json) => {
    if (success && json.result) {
      setCuisines(json.result);
    }
  });

  const onChangeTab = (id) => {
    const queries = getQueryString();
    if (id === 0) {
      navigate('/food-truck');
    } else {
      navigate(`/map?${queries}`);
    }
    window.scrollTo(0, 0);
  };

  const params = new URLSearchParams(location.search);
  useEffect(() => {
    getCuisines();
  }, []);

  useEffect(() => {
    if (params.get('search')) {
      setSearchText(params.get('search'));
      setflagSearchText(true);
    }
  }, [location.search]);

  const getFilterData = () => {
    let filterData = {
      sortBy: sortType,
      pricePoint,
    };
    if (distance < 5) {
      filterData = { ...filterData, distance: [1, 5, 10, 15][distance - 1] };
    }
    if (searchText) filterData = { ...filterData, keyword: searchText };
    if (cuisine !== 0) filterData = { ...filterData, cuisineId: cuisine };

    return filterData;
  };

  useEffect(() => {
    if ((params.get('search') === null || flagSearchText) && tabIndex === 0) {
      const filterData = getFilterData();
      onFilter(filterData);
    }
  }, []);

  useEffect(() => {
    if ((params.get('search') === null || flagSearchText) && tabIndex === 1) {
      const filterData = getFilterData();
      onFilter(filterData);
    }
  }, [sortType, pricePoint, cuisine, distance, searchText, location.search]);

  const handleFilter = () => {
    const filterData = getFilterData();
    onFilter(filterData);
  };

  const handleReset = () => {
    setSearchText('');
    setCuisine(0);
    setPricePoint(4);
    setDistance(4);
  };

  const RenderSort = () => {
    return (
      <Grid className={classes.cuisineContainer}>
        <Typography className={classes.title}>Sort</Typography>
        {sortData.map((item) => {
          return (
            <Grid key={item.key} className={classes.sortItem}>
              <Typography className={classes.sortTitle}>{item.title}</Typography>
              <CustomRadio
                isSelected={sortType === item.key}
                onClick={() => setSortType(item.key)}
                key={item.key}
              />
            </Grid>
          );
        })}

      </Grid>
    );
  };

  const RenderCuisine = () => {
    return (
      <Grid className={classes.cuisineContainer}>
        <Typography className={classes.title}>Cuisine Type</Typography>
        <RoundSelect
          name="cuisineId"
          onChange={handleChange}
          value={cuisine.toString()}
          defaultValue={0}
          className={classes.cuisineSelecter}
        >
          <MenuItem key={0} value={0}>
            No Reference
          </MenuItem>
          {cuisines && cuisines.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </RoundSelect>
      </Grid>
    );
  };

  const RenderPricePoint = () => {
    return (
      <Grid className={classes.pricePointContainer}>
        <Typography className={classes.title}>Price Point</Typography>
        <CustomSlider
          marks={['$', '$', '$', '$']}
          value={pricePoint}
          onChange={(value) => setPricePoint(value)}
        />
      </Grid>
    );
  };

  const RenderDistance = () => {
    return (
      <Grid className={classes.distanceContainer}>
        <Typography className={classes.title}>Distance from Me (miles)</Typography>
        <CustomSlider
          marks={[1, 5, 10, 15, 'All']}
          value={distance}
          onChange={(value) => setDistance(value)}
        />
      </Grid>
    );
  };

  return (
    <Grid container>
      <SearchInput
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleFilter();
          }
        }}
      />
      {tabIndex === 0 && <RenderSort />}
      <RenderCuisine />
      <RenderPricePoint />
      {tabIndex === 0 && <RenderDistance />}
      {tabIndex === 0 && (
      <>
        <RoundButton className={classes.button} onClick={handleFilter}>
          <Typography className={classes.buttonText}>Save</Typography>
        </RoundButton>
        <RoundButton className={classes.button} onClick={handleReset}>
          <Typography className={classes.buttonText}>Reset</Typography>
        </RoundButton>
      </>
      )}
      <Grid className={classes.tabContainer}>
        {tabs.map((tab) => {
          return (
            <Grid key={tab.id} item xs={12} sm={6} md={6}>
              <Typography
                className={classes.tabButton}
                onClick={() => onChangeTab(tab.id)}
                style={{
                  float: tab.id % 2 === 0 ? 'left' : 'right',
                  color: tab.id === tabIndex ? colors.action : colors.inaction
                }}
              >
                {tab.title}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

FilterTruck.propTypes = {
  index: PropTypes.number,
  onFilter: PropTypes.func,
  values: PropTypes.any,
};

export default FilterTruck;
