import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Grid,
  Checkbox,
  Typography
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import CardTitle from 'src/components/CardTitle';
import { Language } from '@material-ui/icons';
import colors from 'src/common/colors';

const useStyles = makeStyles(({
  root: {}
}));

const SiteInfo = ({
  className, data, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    SITE_NAME: '',
    HOST_DNS: '',
    PORT: '',
    BASE_URL: '',
    APP_NAME: '',
    MAINTENANCE_MODE: 'false',
  });
  const dispatch = useDispatch();

  const bindData = (e) => {
    setValues({
      SITE_NAME: e.SITE_NAME?.value || '',
      HOST_DNS: e.HOST_DNS?.value || '',
      PORT: e.PORT?.value || '',
      BASE_URL: e.BASE_URL?.value || '',
      APP_NAME: e.APP_NAME?.value || '',
      MAINTENANCE_MODE: e.MAINTENANCE_MODE?.value,
    });
  };

  useEffect(() => {
    bindData(data);
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const keys = ['SITE_NAME', 'HOST_DNS', 'PORT', 'BASE_URL', 'APP_NAME', 'MAINTENANCE_MODE'];
    keys.forEach((k) => {
      if (!data[k]) {
        data[k] = {
          key: k,
          title: k,
          value: values[k],
        };
      } else {
        data[k].value = values[k];
      }
    });
    const redata = keys.map((k) => data[k]);

    APIService.saveSettings(redata, (success, json) => {
      if (success && json.result) {
        bindData(data);
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Saved successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
    });
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title={(<CardTitle title="Site & Domain" icon={<Language style={{ color: colors.tmGreen }} />} />)}
          subheader="General information about site"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Site name"
            margin="normal"
            name="SITE_NAME"
            onChange={handleChange}
            value={values.SITE_NAME}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Domain"
            margin="normal"
            name="HOST_DNS"
            onChange={handleChange}
            value={values.HOST_DNS}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Port"
            margin="normal"
            name="PORT"
            onChange={handleChange}
            value={values.PORT}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="API URL"
            margin="normal"
            name="BASE_URL"
            onChange={handleChange}
            value={values.BASE_URL}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Application name"
            margin="normal"
            name="APP_NAME"
            onChange={handleChange}
            value={values.APP_NAME}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Grid container justify="space-between">
          <Grid item>
            <Box
              display="flex"
              p={2}
            >
              <Checkbox
                onChange={(e) => {
                  console.log(e.target.checked);
                  setValues({ ...values, MAINTENANCE_MODE: e.target.checked.toString() });
                }}
                checked={values.MAINTENANCE_MODE === 'true'}
              />
              <Typography style={{ lineHeight: '42px' }}>Maintenance mode</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

SiteInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default SiteInfo;
