import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  MenuItem,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RoundUpload from 'src/components/RoundUpload';
import RoundSelect from 'src/components/RoundSelect';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import RoundButton from 'src/components/RoundButton';
import colors from 'src/common/colors';
import InputSC from 'src/views/menu/InputSC';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles(() => ({
  formContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  submit: {
    marginTop: 30,
    alignSelf: 'center',
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  submitLabel: {
    fontWeight: '600',
    textTransform: 'none',
  },
  progress: {
    color: colors.white,
    alignSelf: 'center',
  }
}));

const SignUpBusinessInfo = ({ data, onSubmit }) => {
  const classes = useStyles();
  const [cuisines, setCuisines] = useState([]);
  const dispatch = useDispatch();

  const submitForm = (values, actions) => {
    const {
      logo, firstName, lastName,
      businessName, cuisineId,
      phoneNumber, email,
      address, city, zipCode,
    } = { ...data, ...values };

    APIService.signUpTruckOwner(
      logo, firstName, lastName,
      businessName, cuisineId,
      phoneNumber, email.toLowerCase(),
      address, city, zipCode,
      (success, json) => {
        actions.setSubmitting(false);
        if (success && json.result) {
          if (onSubmit) onSubmit(values);
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      }
    );
  };

  const getCuisines = () => APIService.getCuisines((success, json) => {
    if (success && json.result) {
      setCuisines(json.result);
    }
  });

  useEffect(() => {
    getCuisines();
  }, []);

  return (
    <Formik
      initialValues={{
        logo: '',
        businessName: '',
        cuisineId: '',
        phoneNumber: '',
        address: '',
        city: '',
        zipCode: ''
      }}
      validationSchema={Yup.object().shape({
        logo: Yup.string().required('Logo is required'),
        businessName: Yup.string().max(100).required('Name of Company is required'),
        cuisineId: Yup.number().required('Cuisine Type is required'),
        phoneNumber: Yup.string()
          .matches(/^[0-9-+]{1,12}$/, 'Phone Number must be a valid phone number')
          .min(10, 'Phone Number must have at least 10 digits')
          .max(12, 'Phone Number must have less than 13 digits')
          .required('Phone Number is required'),
        address: Yup.string().max(255).required('Address is required'),
        city: Yup.string().max(255).required('City is required'),
        zipCode: Yup.string()
          .min(5, 'Zip code must have 5 digits')
          .max(5, 'Zip code must have 5 digits')
          .required('Zip Code is required'),
      })}
      onSubmit={(values, actions) => {
        submitForm(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setErrors,
        setTouched,
        touched,
        values,
        isSubmitting
      }) => (
        <form className={classes.formContent} onSubmit={handleSubmit}>
          <RoundUpload
            name="logo"
            bg="/static/images/upload_logo.svg"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue('logo', e.currentTarget.files[0]);
            }}
            value={values.logo}
            error={touched.logo && errors.logo}
            accept="image/*"
            helperText={
                    (touched.logo && errors.logo) ? errors.logo : ''
                  }
          />
          <InputSC
            className={classes.input}
            label="Name of Company"
            name="businessName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.businessName}
            error={Boolean(touched.businessName && errors.businessName)}
            helperText={
                    (touched.businessName && errors.businessName) ? errors.businessName : ''
                  }
          />
          <RoundSelect
            className={classes.input}
            name="cuisineId"
            onBlur={handleBlur}
            onChange={handleChange}
            height={56}
            value={values.cuisineId.toString()}
            defaultValue=""
            error={Boolean(touched.cuisineId && errors.cuisineId)}
            helperText={
                    (touched.cuisineId && errors.cuisineId) ? errors.cuisineId : ''
                  }
          >
            <MenuItem disabled value="">
              Cuisine
            </MenuItem>
            {
                    cuisines
                    && cuisines.map((cuisine) => (
                      <MenuItem key={cuisine.id} value={cuisine.id}>
                        {cuisine.title}
                      </MenuItem>
                    ))
                  }
          </RoundSelect>
          <InputSC
            className={classes.input}
            label="Phone Number"
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={(e) => {
              if (e.target.value?.length <= 12) {
                setTouched({ phoneNumber: true });
                setFieldValue('phoneNumber', e.target.value);
              } else {
                setErrors({ phoneNumber: 'Phone Number must have less than 13 digits' });
              }
            }}
            value={values.phoneNumber}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={
                    (touched.phoneNumber && errors.phoneNumber) ? errors.phoneNumber : ''
                  }
          />
          <InputSC
            className={classes.input}
            label="Address"
            name="address"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.address}
            error={Boolean(touched.address && errors.address)}
            helperText={
                    (touched.address && errors.address) ? errors.address : ''
                  }
          />
          <InputSC
            className={classes.input}
            label="City"
            name="city"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.city}
            error={Boolean(touched.city && errors.city)}
            helperText={
                    (touched.city && errors.city) ? errors.city : ''
                  }
          />
          <InputSC
            className={classes.input}
            label="Zip Code"
            name="zipCode"
            type="number"
            onBlur={handleBlur}
            onChange={(e) => {
              if (e.target.value?.length < 6) {
                setTouched({ zipCode: true });
                setFieldValue('zipCode', e.target.value);
              } else {
                setErrors({ zipCode: 'Zip code must have 5 digits' });
              }
            }}
            value={values.zipCode}
            error={Boolean(touched.zipCode && errors.zipCode)}
            helperText={
                    (touched.zipCode && errors.zipCode) ? errors.zipCode : ''
                  }
          />
          <RoundButton className={classes.submit} type="submit">
            {isSubmitting
              ? <CircularProgress size={24} className={classes.progress} />
              : <Typography className={classes.submitLabel}>Submit Application</Typography>}
          </RoundButton>
        </form>
      )}
    </Formik>
  );
};

SignUpBusinessInfo.propTypes = {
  data: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default SignUpBusinessInfo;
