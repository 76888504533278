import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import TextSearch from 'src/views/widgets/TextSearch';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import { Email } from '@material-ui/icons';
import CardTitle from 'src/components/CardTitle';
import colors from 'src/common/colors';

const useStyles = makeStyles(({
  root: {}
}));

const keys = [
  'TEMPL_VERIFY_CUSTOMER_EMAIL',
  'TEMPL_TRUCKOWNER_VERIFY_UPDATE_INFO',
  'TEMPL_TRUCKOWNER_ACCOUNT_APPROVAL',
  'TEMPL_TRUCKOWNER_ACCOUNT_DENIED',
  'TEMPL_CONFIRM_CHANGE_EMAIL',
  'TEMPL_RESET_PASSWORD',
  'TEMPL_EMAIL_NOTIFY_CHANGE_PASSWORD',
  'TEMPL_EMAIL_CHANGED_IN_PREVIOUS',
  'TEMPL_ADMIN_CHANGE_EMAIL_IN_PREVIOUS',
  'TEMPL_ADMIN_CHANGE_EMAIL_CONFIRM',
  'TEMPL_CUSTOMER_INIT_PASSWORD',
  'TEMPL_ACCOUNT_SUSPENSION',
  'TEMPL_PAYMENT_SUCCEEDED',
  'TEMPL_PAYMENT_FAILED',
];

const EmailTemplates = ({
  className, data, templates, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    ...keys.reduce((result, item) => {
      result[item] = '';
      return result;
    }, {})
  });
  const dispatch = useDispatch();

  const options = templates ? templates.map((e) => ({
    id: e.id,
    name: e.title,
    key: e.key,
    type: e.type
  })) : [];

  const bindData = (e) => {
    const obj = {};
    keys.forEach((key) => {
      (obj[key] = e[key]?.value || '');
    });
    setValues(obj);
  };

  const parseOption = (key) => options.find((e) => e.key === key) || {};

  useEffect(() => {
    bindData(data);
  }, []);

  const handleSubmit = () => {
    keys.forEach((k) => {
      if (!data[k]) {
        data[k] = {
          key: k,
          title: k,
          value: values[k],
        };
      } else {
        data[k].value = values[k];
      }
    });
    const redata = keys.map((k) => data[k]);

    APIService.saveSettings(redata, (success, json) => {
      if (success && json.result) {
        bindData(data);
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Saved successfully!' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
    });
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title={(<CardTitle title="Email templates" icon={<Email style={{ color: colors.blue }} />} />)}
          subheader="Configure templates for email"
        />
        <Divider />
        <CardContent>
          <Box mt={3} />
          {
            keys.map((key) => (
              <Box key={key} mt={3}>
                <TextSearch
                  label={key}
                  options={options}
                  value={parseOption(values[key])}
                  onSelect={(item) => {
                    if (item) {
                      values[key] = item.key;
                    }
                  }}
                />
              </Box>
            ))
          }
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EmailTemplates.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  templates: PropTypes.array,
};

export default EmailTemplates;
