import APIService from 'src/utils/APIService';
import {
  Box, Dialog, DialogContent, Hidden, Typography,
  useMediaQuery, useTheme
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import TruckProfile from 'src/components/TruckProfile';
import sampleTruckProfile from 'src/fake-data/sample-truck-profile';
import FilterTruck from '../components/FilterTruck';
import InfoWindow from '../components/InfoWindow';
import MapStyle from '../../../theme/google-map';

const CustomerMap = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [trucks, setTrucks] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState();
  const [truckProfile, setTruckProfile] = useState(sampleTruckProfile);
  const [viewProfile, setViewProfile] = useState(false);
  const [viewFilter, setViewFilter] = useState(false);
  const customerLocation = localStorage.getItem('location');
  const [center, setCenter] = useState({
    lat: JSON.parse(customerLocation)?.lat ?? 37.5940967,
    lng: JSON.parse(customerLocation)?.lng ?? -122.0216195
  });

  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.down('md'));

  const keyword = query.get('keyword');
  const cuisineId = query.get('cuisineId');
  const pricePoint = query.get('pricePoint');
  const distance = query.get('distanceLevel');
  const sortType = query.get('sortType');

  const googleApiKey = 'AIzaSyBwWtIcvLYPE31zIwosDTcaZnCVoJN-eSI';

  Geocode.setApiKey(googleApiKey);
  Geocode.setLocationType('ROOFTOP');

  useEffect(() => {
    if (isMediumSize) setViewFilter(false);
  }, [isMediumSize]);

  const getLocation = (callback) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        const checkLocation = localStorage.getItem('location');
        if (!checkLocation) {
          setCenter(location);
        }
        localStorage.setItem('location', JSON.stringify(location));
        if (callback) {
          callback(position.coords.latitude, position.coords.longitude);
        }
      },
      (err) => {
        console.log(err);
        if (callback) {
          callback();
        }
      }
    );
  };

  const getTrucks = (options) => {
    getLocation((lat, lng) => {
      console.log('curr:', lat, lng);
      let queries = { ...options };
      if (lat && lng) {
        queries = {
          ...queries,
          lat,
          lng,
        };
      }
      APIService.getTrucks(
        {
          ...queries,
          status: 'ACTIVE'
        },
        (success, json) => {
          if (success && json) {
            if (queries.keyword && json.result.length <= 0) navigate('/404', true);
            setSelectedTruck(null);
            setTrucks(json.result);
            // const hasSelectedTruck = json.result.find((truck) => truck.id === selectedTruck.id);
            // if (!hasSelectedTruck) {
            //   setSelectedTruck(null);
            // }
            if (json.result[0]) {
              setCenter({ lat: +json.result[0].latitude, lng: +json.result[0].longitude });
              setSelectedTruck(json.result[0]);
            }
          }
        }
      );
    });
  };

  const getTruckProfile = (id) => {
    APIService.getTruckById(id, (success, json) => {
      if (success && json) {
        setTruckProfile(json.result);
        setViewProfile(true);
      }
    });
  };

  return (
    <Box style={{ height: '85vh', width: '100%', position: 'relative' }}>
      <GoogleMapReact
        center={center}
        bootstrapURLKeys={{ key: googleApiKey }}
        zoom={17}
        options={(maps) => ({
          zoomControl: true,
          styles: MapStyle,
          zoomControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT
          },
        })}
        onClick={() => isMediumSize && setViewFilter(false)}
      >
        {trucks.map((truck, index) => (
          <div
            key={`${index.toString()}`}
            lat={truck.latitude}
            lng={truck.longitude}
            text={truck.owner?.businessName}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedTruck(truck);
            }}
          >
            <img
              alt=""
              src="/static/images/icon_mini_truck.png"
              width={30}
              style={{
                transform: 'translate(-50%, -50%)'
              }}

            />
          </div>
        ))}
        {selectedTruck
          && (
            <InfoWindow
              lat={selectedTruck?.latitude}
              lng={selectedTruck?.longitude}
              onClickForward={() => getTruckProfile(selectedTruck.id)}
              data={selectedTruck}
              style={{
                transform: 'translate(-50%, -135%)'
              }}
            />
          )}
      </GoogleMapReact>

      {((viewFilter && isMediumSize) || !isMediumSize) && (
        <Box
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            padding: 25,
            paddingBottom: 16,
            width: 332,
            top: 0,
            left: 0,
          }}
        >
          <FilterTruck
            index={1}
            onFilter={getTrucks}
            values={{
              keyword,
              cuisineId,
              pricePoint,
              distance,
              sortType
            }}
          />
        </Box>
      )}

      <Hidden lgUp>
        {!viewFilter && (
          <Box
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              padding: 10,
              top: 20,
              left: 20,
              borderRadius: 10,
            }}
            onClick={() => setViewFilter(true)}
          >
            <Typography>
              Show Filter
            </Typography>
          </Box>
        )}
      </Hidden>

      <Dialog open={viewProfile} onClose={() => setViewProfile(false)}>
        <DialogContent>
          {truckProfile
            && (
              <TruckProfile
                user={truckProfile.owner}
                data={truckProfile.owner?.operation}
              />
            )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomerMap;
