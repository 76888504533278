import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import SunREInput from '../../dynamic-contents/Editor';

const TermsAndConditionsSetting = ({ onSave }) => {
  const location = useLocation();
  const [terms, setTerms] = useState({ id: 0 });
  const dispatch = useDispatch();

  const fetchTerms = () => {
    APIService.getTermsAndConditions((success, json) => {
      if (success && json.result) {
        const item = json.result;
        setTerms(item);
      }
    });
  };

  useEffect(() => {
    fetchTerms();
  }, [location.pathname]);

  return (
    <Formik
      initialValues={{
        title: 'Terms And Conditions',
        content: ''
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('Content is required'),
      })}
      onSubmit={(values, actions) => {
        console.log(terms);
        APIService.setTermsAndConditions(
          terms.id,
          values.title,
          values.content,
          (success, json) => {
            actions.setSubmitting(false);
            if (success && json.result) {
              dispatch(notiActions.viewNoti({ type: 'success', content: 'Saved successfully!' }));
              fetchTerms();
              if (onSave) onSave(json);
            } else {
              dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
            }
          }
        );
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Card>
          <CardHeader
            title="Terms And Conditions"
            subheader="Edit Terms & Conditions"
            action={(
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </Box>
            )}
          />
          <Divider />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <SunREInput
                name="content"
                content={terms.content}
                height={600}
                placeholder="Content"
                onChange={(text) => setFieldValue('content', text)}
              />
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

TermsAndConditionsSetting.propTypes = {
  onSave: PropTypes.func,
};
export default TermsAndConditionsSetting;
