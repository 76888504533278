import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 25,
  }
}));

const RoundButton = ({
  className,
  label,
  type,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Box mt={2} className={className}>
      <Button
        className={classes.button}
        color="primary"
        disabled={disabled}
        fullWidth
        size="large"
        type={type || 'button'}
        onClick={onClick}
        variant="contained"
      >
        {label}
      </Button>
    </Box>
  );
};

RoundButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RoundButton;
