import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profile = Settings.profile();
  const [user, setUser] = useState(profile);
  const [isViewChangePassword, setIsViewChangePassword] = useState(false);

  const handleSave = (values) => APIService.updateProfile(values, (success, json) => {
    if (success && json.result) {
      const data = { ...profile, ...json.result };
      Settings.setProfile(data);
      setUser(data);
    }
  });

  const handleChangePassword = (values) => {
    if (!values.password
      || !values.newPassword
      || !values.confirmNewPassword
      || values.newPassword !== values.confirmNewPassword
    ) {
      let content = '';
      if (!values.password) {
        content = 'Please enter password';
      } else if (!values.newPassword) {
        content = 'Please enter new password';
      } else if (!values.confirmNewPassword) {
        content = 'Please enter confirm new password';
      } else {
        content = 'Confirm new password must be the same as new password';
      }
      dispatch(notiActions.viewNoti({
        type: 'error',
        content
      }));
    } else {
      APIService.adminChangePassword(values.password, values.newPassword, (success, json) => {
        if (success && json.result) {
          dispatch(notiActions.viewNoti({
            type: 'success',
            content: 'Changed password successfully!'
          }));
          setIsViewChangePassword(false);
        } else {
          dispatch(notiActions.viewNoti({
            type: 'error',
            content: json.error
          }));
        }
      });
    }
  };

  const viewChangePassword = () => {
    setIsViewChangePassword(true);
  };

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile user={user} />
            {isViewChangePassword && (
            <ChangePassword
              show={isViewChangePassword}
              onClose={() => setIsViewChangePassword(false)}
              onSubmit={handleChangePassword}
            />
            )}
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails
              onSubmit={handleSave}
              viewChangePassword={viewChangePassword}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
