import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
} from '@material-ui/core';

const IconTextButton = ({
  className, label, onClick, ...rest
}) => {
  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton onClick={onClick}>
        {rest.children}
      </IconButton>
      {!!label && (
      <Button variant="text" size="small" onClick={onClick}>
        {label}
      </Button>
      )}
    </Box>
  );
};

IconTextButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconTextButton;
