import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  button: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'dashed 0.2px rgba(0,0,0,0.43)',
    opacity: 0.35,
    padding: '15px 20px',
    borderRadius: '30px'
  },
  buttonActive: {
    opacity: 1,
    border: 'dashed 0.5px #231f20',
  }
}));

const SwitchButtonGroup = ({ items, onChange, selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        items?.map((item) => (
          <button
            key={item.key}
            className={
              clsx({ [classes.button]: true, [classes.buttonActive]: (selected === item.key) })
            }
            type="button"
            onClick={() => onChange(item)}
          >
            {item.value}
          </button>
        ))
      }
    </div>
  );
};

SwitchButtonGroup.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.any
};

export default SwitchButtonGroup;
