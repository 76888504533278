import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  Box, makeStyles, Typography
} from '@material-ui/core';
import colors from 'src/common/colors';
import Food from './Food';

const useStyles = makeStyles(() => ({
  name: {
    textAlign: 'center',
    color: colors.primary,
    fontSize: 16,
    fontWeight: '700'
  },
  desc: {
    textAlign: 'center',
    color: colors.holoBlack,
  },
  foods: {
    padding: 10,
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  }
}));

const MenuGroup = ({ data, editable, ...props }) => {
  const classes = useStyles();
  const { name, description, menuFoods } = data || {};

  return (
    <Box {...props} mb={2}>
      <Typography className={classes.name}>{name || ''}</Typography>
      <Typography className={classes.desc}>{description || ''}</Typography>
      <Box className={classes.foods}>
        {
          menuFoods.map((e) => (
            <Food name={e.name} price={e.price} desc={e.description} />
          ))
        }
      </Box>
    </Box>
  );
};

MenuGroup.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  dataStore: PropTypes.any,
  editable: PropTypes.bool,
};

export default inject('dataStore')(observer(MenuGroup));
