import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, IconButton,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

const AddFood = ({ onClick, className }) => {
  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton onClick={onClick}>
        <AddCircleOutline />
      </IconButton>
    </Box>
  );
};

AddFood.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default AddFood;
