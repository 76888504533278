import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import InfoItem from './InfoItem';
import TextHeading from '../profile/TextHeading';

const useStyles = makeStyles(() => ({
  root: {},
}));

const PaymentCard = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const { user } = data || {};
  const { stripeAccount } = user || {};
  const { subscription } = user || {};
  const valid = stripeAccount && stripeAccount.customerId;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box mt={1} mb={3}>
            <TextHeading variant="h5" align="center">
              Payment Information
            </TextHeading>
          </Box>
          {
            valid
              ? (
                <Box>
                  <InfoItem title="Stripe" content="Provided" />
                  <InfoItem title="Email" content={stripeAccount.email || ''} />
                  {/* <InfoItem title="Source" content={stripeAccount.source || ''} /> */}
                </Box>
              )
              : <InfoItem title="Stripe" content="Missing" />
          }
          {subscription && <InfoItem title="Subcription Status" content={subscription?.status ?? ''} />}
        </Box>
      </CardContent>
    </Card>
  );
};

PaymentCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default PaymentCard;
