import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import StringUtils from 'src/utils/StringUtils';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import colors from 'src/common/colors';
import InfoItem from './InfoItem';
import TextHeading from './TextHeading';

const useStyles = makeStyles(() => ({
  root: {},
  heading: {
    color: colors.headingTeal,
    textTransform: 'uppercase',
  }
}));

const OperationHours = ({
  className,
  data,
  useCard,
  ...rest
}) => {
  const classes = useStyles();
  const body = () => (
    <Box
      mt={1}
      className={clsx(classes.root, className)}
      display="flex"
      flexDirection="column"
      alignItems={rest.center ? 'center' : ''}
    >
      <Box mt={1} mb={1}>
        <TextHeading variant="h5">
          Hours of Operation
        </TextHeading>
      </Box>
      {
        data && data.map((e) => {
          const startTime = moment(e.startTime).format('hh:mm a');
          const endTime = moment(e.endTime).format('hh:mm a');
          return (
            <InfoItem
              title={StringUtils.upperCaseFirstLetter(e.day)}
              content={`${startTime} - ${endTime}`}
            />
          );
        })
      }
    </Box>
  );

  return (
    useCard ? <Card><CardContent>{body()}</CardContent></Card> : body()
  );
};

OperationHours.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  useCard: PropTypes.bool,
};

export default OperationHours;
