import {
  AppBar,
  Avatar,
  Box,
  Container,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Link,
  Typography,
  InputBase
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import colors from 'src/common/colors';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Thumb from 'src/views/widgets/Thumb';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import DropdownButton from './DropdownButton';
import LoginForm from './LoginForm';
import SignUpButton from './SignUpButton';
import HeaderLogo from './HeaderLogo';

const MaxHeight = 64;
const useStyles = makeStyles(() => ({
  root: {
    height: 105,
  },
  appBar: {
    paddingTop: 22,
    paddingBottom: 22,
    position: 'fixed',
    backgroundColor: colors.white
  },
  toolbar: {
    minHeight: 22,
    maxHeight: 64,
  },
  navlink: {
    color: colors.black,
    '&:hover': {
      color: colors.tmGreen,
      textDecoration: 'none',
    }
  },
  button: {
    paddingLeft: 25,
    paddingRight: 25,
    display: 'flex',
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
  },
  loginTag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    cursor: 'pointer',
    maxWidth: 110,
  },
  name: {
    marginLeft: 10,
    minWidth: 50,
    maxWidth: 150
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 26,
    flexGrow: 1,
    borderRadius: 13,
    enabled: true,
    border: '1px solid #000',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    maxWidth: 220
  },
  buttonSearch: {
    width: 20,
    height: 20,
    maxWidth: 20,
    marginRight: 10
  },
  iconSearch: {
    width: 20,
    height: 'auto'
  },
  input: {
    fontSize: 16,
  },
  loginForm: {
    width: 300
  },
  buttonStyle: {
    width: '30vw'
  }
}));
const Header = ({
  onViewMenuMobile, navList, isAuth, userData, onLogin, onLogout, ...rest
}) => {
  const [viewDropdown, setIsViewDropdown] = useState('');
  const [barHeight, setBarHeight] = useState(60);
  const [searchText, setSearchText] = useState('');
  const [polling, setPolling] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const { dataStore } = rest;
  const user = dataStore.user?.id ? dataStore.user : userData;
  const location = useLocation();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setBarHeight(MaxHeight - window.scrollY);
    }, { passive: true });
  }, [window.scrollY]);

  useEffect(() => {
    setTimeout(() => { setPolling((prePolling) => prePolling + 1); }, 1000);
  }, [polling, dataStore]);

  useEffect(() => {
    setIsViewDropdown('');
  }, [location.pathname]);

  const buttonClickHandler = (key) => {
    if (key === viewDropdown) {
      setIsViewDropdown('');
    } else {
      setIsViewDropdown(key);
    }
  };

  const onTapAvatar = () => {
    navigate('/profile');
  };

  const logout = () => {
    setIsViewDropdown(false);
    if (onLogout) onLogout();
  };

  const onSearch = () => {
    navigate(`/map?keyword=${searchText}`, true);
    window.location.reload();
    setSearchText('');
  };

  const onSignUp = () => {
    setIsViewDropdown(false);
  };

  return (
    <Box className={classes.root}>
      <AppBar
        position="sticky"
        color="transparent"
        className={classes.appBar}
      >
        <Container>
          <Toolbar
            elevation={3}
            className={classes.toolbar}
            style={{ height: barHeight }}
          >
            <Box display="flex" alignItems="center">
              <Link href="/">
                <HeaderLogo isAuth={isAuth} />
              </Link>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Hidden smDown>
                {
                  navList.map((item) => (
                    <Box
                      key={item.key}
                      className={classes.button}
                      style={location.hash === '#searching' && item.key === 'AUTH_SEARCH' ? { flexGrow: 1, maxWidth: 240 } : {}}
                    >
                      {(location.hash !== '#searching' || item.key !== 'AUTH_SEARCH') && (
                        <Typography className={classes.buttonText}>
                          {
                            isAuth
                              ? (
                                <RouterLink
                                  color={colors.black}
                                  className={classes.navlink}
                                  // eslint-disable-next-line no-nested-ternary
                                  to={item.key === 'AUTH_SEARCH'
                                    ? dataStore?.user?.role === 'CUSTOMER'
                                      ? `${location.pathname === '/' ? location.pathname : `${location.pathname}/`}${item.path}`
                                      : location.pathname
                                    : item.path}
                                >
                                  {item.title}
                                </RouterLink>
                              )
                              : (
                                <Link
                                  className={classes.navlink}
                                  href={item.path}
                                >
                                  {item.title}
                                </Link>
                              )
                          }

                        </Typography>
                      )}
                      {location.hash === '#searching' && item.key === 'AUTH_SEARCH' && (
                        <Box className={classes.searchWrapper}>
                          <IconButton
                            className={classes.buttonSearch}
                            width={20}
                            onClick={onSearch}
                          >
                            <img
                              src="/static/images/search_icon.png"
                              alt="search"
                              className={classes.iconSearch}
                            />
                          </IconButton>
                          <InputBase
                            id="outlined-basic"
                            className={classes.input}
                            placeholder="Search"
                            value={searchText}
                            onKeyDown={(e) => { if (e.keyCode === 13) onSearch(); }}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))
                }
              </Hidden>
            </Box>
            <Hidden smDown>
              {!isAuth && (
                <Box display="flex" flexDirection="row">
                  {onLogin && (
                    <DropdownButton
                      isViewDropdown={viewDropdown === 'LOGIN'}
                      title="Login"
                      setIsViewDropdown={() => buttonClickHandler('LOGIN')}
                    >
                      <LoginForm className={classes.loginForm} onLogin={onLogin} />
                    </DropdownButton>
                  )}
                  <DropdownButton
                    isViewDropdown={viewDropdown === 'SIGN_UP'}
                    title="Sign up"
                    setIsViewDropdown={() => buttonClickHandler('SIGN_UP')}
                  >
                    <SignUpButton onSignUp={onSignUp} buttonStyle={classes.buttonStyle} />
                  </DropdownButton>
                </Box>
              )}
            </Hidden>
            {isAuth && (
              <Box className={classes.loginTag}>
                <IconButton size="small" onClick={onTapAvatar}>
                  <Avatar
                    width={50}
                    height={50}
                    src={user.avatarURL ?? '/static/images/img_no_avatar.png'}
                    className={classes.loginImg}
                  />
                </IconButton>
                <Box className={classes.username} onClick={onTapAvatar}>
                  <Typography className={classes.name}>{`${user.firstName} ${user.lastName}`}</Typography>
                </Box>
                <Hidden xsDown>
                  <IconButton onClick={() => logout()}>
                    <Thumb uri="/static/images/logout_icon.png" width={24} height={24} />
                  </IconButton>
                </Hidden>
              </Box>
            )}
            <Hidden mdUp>
              <IconButton
                color="primary"
                onClick={onViewMenuMobile}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
Header.propTypes = {
  onViewMenuMobile: PropTypes.func,
  navList: PropTypes.array,
  isAuth: PropTypes.bool,
  userData: PropTypes.object,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
};
export default inject('dataStore')(observer(Header));
