import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Chip,
  Dialog,
  DialogContent,
  // Typography,
} from '@material-ui/core';
import moment from 'moment';
import { HttpMethod } from 'src/utils/Settings';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  filter,
  searchText,
  onSelected,
  selectedIds,
  onPuzzle,
  onPhrase,
  onPreView,
  onDelete,
  onViewDetails,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [logContent, setLogContent] = useState('');
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedItemIds;

    if (event.target.checked) {
      newSelectedItemIds = data.map((item) => item.id);
    } else {
      newSelectedItemIds = [];
    }
    onSelected(newSelectedItemIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItemIds = newSelectedItemIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    onSelected(newSelectedItemIds);
  };

  const sliceString = (content) => {
    return `${content.slice(0, 80)}${content.length > 80 ? '...' : ''}`;
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((item) => {
    if (filter) {
      let not = false;
      Object.keys(filter)
        .forEach((key) => {
          if (!filter[key]) return;
          if (item[key] !== filter[key]) not = true;
        });
      if (not) return false;
    }

    return item.url.toLowerCase().indexOf(search) >= 0
      || item.errorMessage.indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  const renderChip = (method) => {
    let backgroundColor = 'grey';
    switch (method) {
      case HttpMethod.GET: backgroundColor = '#00acc1'; break;
      case HttpMethod.POST: backgroundColor = '#43a047'; break;
      case HttpMethod.PATCH:
      case HttpMethod.PUT: backgroundColor = '#fb8c00'; break;
      case HttpMethod.DELETE: backgroundColor = '#e53935'; break;
      default:
    }
    return <Chip size="small" label={method} style={{ color: 'white', backgroundColor }} />;
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.length === data.length}
                      color="primary"
                      indeterminate={
                        selectedIds.length > 0
                        && selectedIds.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {
                    ['Id', 'User Id', 'Method', 'URL', 'Error Message', 'Content', 'Created At'].map((title) => <TableCell key={title}>{title}</TableCell>)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {records.slice(page * limit, page * limit + limit).map((item) => {
                  return (
                    <TableRow
                      hover
                      key={item.id}
                      selected={selectedIds.indexOf(item.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedIds.indexOf(item.id) !== -1}
                          onChange={(event) => handleSelectOne(event, item.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        {item.id}
                      </TableCell>
                      <TableCell>
                        {item.userId}
                      </TableCell>
                      <TableCell>
                        {renderChip(item.method)}
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer', maxWidth: '20vw', overflow: 'clip' }}
                        onClick={() => {
                          setLogContent(`${process.env.REACT_APP_API_SERVER}${item.url}`);
                        }}
                      >
                        {sliceString(item.url)}
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setLogContent(item.errorMessage.toString().replace(/\\/g, ''));
                        }}
                      >
                        {sliceString(item.errorMessage.replace(/\\/g, ''))}
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer', maxWidth: '20vw', overflow: 'clip' }}
                        onClick={() => {
                          setLogContent(JSON.stringify(item.content, null, 2));
                        }}
                      >
                        {sliceString(JSON.stringify(item.content))}
                      </TableCell>
                      <TableCell>
                        {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={records.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />

      </Card>
      <Dialog
        open={!!logContent}
        onClose={() => {
          setLogContent('');
        }}
      >
        <DialogContent>
          <Editor
            value={logContent}
            onValueChange={setLogContent}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 16,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
  onPuzzle: PropTypes.func,
  onPhrase: PropTypes.func,
  onPreView: PropTypes.func,
  onDelete: PropTypes.func,
  onViewDetails: PropTypes.func,
};

export default Results;
