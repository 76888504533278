import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Settings from 'src/utils/Settings';
import Results from '../dynamic-contents/Results';
import Toolbar from '../widgets/Toolbar';
import Uploader from './Uploader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Templates = ({ type }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleForm, showForm] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState();

  const getContents = () => APIService.getDynamicContents(type, (success, json) => {
    if (success && json.result) {
      setContents(json.result);
    }
  });

  const deleteItems = () => APIService.deleteManyDynamicContents(selectedItems, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getContents();
    }
  });

  useEffect(() => {
    getContents();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title={`Templates - ${type}`}
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onAdd={() => showForm(true)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => deleteItems() : null}
        />
        <Box mt={3}>
          <Results
            data={contents}
            searchText={searchText}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
            onView={(item) => {
              Settings.setDynamicContent(item.content);
              navigate('/dynamic', { state: { data: item } });
            }}
            onEdit={(item) => {
              setSelectedTemplate(item);
              showForm(true);
            }}
          />
        </Box>
        <Uploader
          data={selectedTemplate}
          type={type}
          visible={visibleForm}
          onClose={() => {
            showForm(false);
            setSelectedTemplate(null);
          }}
          onSuccess={() => getContents()}
        />
      </Container>
    </Page>
  );
};

Templates.propTypes = {
  type: PropTypes.string,
};
export default Templates;
