import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import { CancelOutlined, CheckCircleOutlined } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {},
}));

const PaymentMethod = ({
  className, name, enabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {enabled
        ? <CheckCircleOutlined style={{ color: green[500] }} />
        : <CancelOutlined style={{ color: 'red' }} />}
      <Box width={10} />
      <Typography>{name}</Typography>
    </Box>
  );
};

PaymentMethod.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  enabled: PropTypes.bool,
};

export default PaymentMethod;
