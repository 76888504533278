import 'date-fns';
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Checkbox,
  makeStyles,
  Box, Typography,
  Grid,
  colors,

} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CustomSlider from 'src/components/CustomSlider';
import RoundUpload from 'src/components/RoundUpload';
import RoundButton from 'src/components/RoundButton';
import RoundTimePicker from './RoundTimePicker';

const useStyles = makeStyles(() => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
  },
  ruler: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15
  },
  error: {
    fontSize: 12,
    color: colors.red[500],
  },
  upload: {
    marginTop: 10,
    marginRight: 10,
  },
  submit: {
    marginTop: 50,
    width: 300,
    alignSelf: 'center',
  },
  submitLabel: {
    fontWeight: '600',
    textTransform: 'none',
  },
}));
export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const SetHourOperation = ({ onSubmit }) => {
  const [days, setDays] = useState(daysOfWeek.map((day) => ({ day, isChecked: false })));
  const [methods, setMethods] = useState([]);

  const onToggleDay = (value, onUpdate) => {
    const selectedDay = days.find((e) => e.day === value.day);
    selectedDay.isChecked = !selectedDay.isChecked;

    if (!selectedDay.startTime) {
      selectedDay.startTime = new Date();
    }
    if (!selectedDay.endTime) {
      selectedDay.endTime = new Date();
    }

    if (onUpdate) onUpdate();
  };

  const onToggleMethod = (value, onUpdate) => {
    if (methods.includes(value)) {
      setMethods(methods.filter((m) => m !== value));
    } else {
      setMethods([...methods, value]);
    }

    if (onUpdate) onUpdate();
  };

  const onTimeChange = (date, type, hour) => {
    const selectedDay = days.find((e) => e.day === hour.day);
    if (type === 'start') {
      selectedDay.startTime = date;
    } else {
      selectedDay.endTime = date;
    }
    setDays([...days]);
  };

  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        opeHours: [],
        paymentMethods: [],
        menuImgs: [],
        pricePoint: 1,
      }}
      validationSchema={Yup.object().shape({
        opeHours: Yup.array().min(1, 'Hours of operation is required'),
        menuImgs: Yup.array().min(1, 'Menu image is required'),
      })}
      onSubmit={(values) => {
        const enabledDays = days.filter((e) => e.isChecked);

        if (onSubmit) {
          onSubmit({
            operation: enabledDays,
            paymentMethods: methods,
            pricePoint: values.pricePoint,
            menuImgs: values.menuImgs,
          });
        }
      }}
    >
      {({
        setFieldValue,
        handleSubmit,
        values,
        touched,
        errors,
      }) => (
        <form className={classes.formContent} onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography align="center" className={classes.sectionTitle}>
              Hours of Operation
            </Typography>
            {days && days.map((hour) => (
              <Box
                key={hour.day}
                display="flex"
                flexDirection="row"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="10px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="20%"
                >
                  <FormControlLabel
                    key={hour.day}
                    control={(
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleChecked />}
                        onClick={() => onToggleDay(hour, () => {
                          const enabledDays = days.filter((e) => e.isChecked).map((e) => e.day);
                          setFieldValue('opeHours', enabledDays);
                        })}
                      />
                    )}
                    label={hour.day}
                  />
                </Box>
                {
                  hour.isChecked && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                      width="60%"
                    >
                      <RoundTimePicker
                        initialValue={hour.startTime}
                        onTimeChange={(date) => onTimeChange(date, 'start', hour)}
                      />
                      <Typography>-</Typography>
                      <RoundTimePicker
                        initialValue={hour.endTime}
                        onTimeChange={(date) => onTimeChange(date, 'end', hour)}
                      />
                    </Box>
                  )
                }
              </Box>
            ))}
            <Typography className={classes.error}>
              {touched.opeHours && errors.opeHours}
            </Typography>
          </Box>
          <Typography align="center" className={classes.sectionTitle}>
            Price Point
          </Typography>
          <CustomSlider
            marks={['$', '$', '$', '$']}
            value={values.pricePoint}
            onChange={(value) => setFieldValue('pricePoint', value)}
          />
          <Typography align="center" className={classes.sectionTitle}>
            Accepted Payment Methods
          </Typography>
          <Grid container spacing={3} justify="center">
            <Grid item xs={5} md={5}>
              <FormControlLabel
                key="cash"
                control={(
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked />}
                    onClick={() => onToggleMethod('CASH')}
                  />
                )}
                label="Cash"
              />
            </Grid>
            <Grid item xs={5} md={5}>
              <FormControlLabel
                key="credit"
                control={(
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked />}
                    onClick={() => onToggleMethod('CREDIT')}
                  />
                )}
                label="Credit"
              />
            </Grid>
          </Grid>
          <Typography align="center" className={classes.sectionTitle}>
            Menu
          </Typography>
          <Grid container spacing={2}>
            {
              values.menuImgs.map((img, index) => (
                <Grid key={`${index.toString()}`} item>
                  <RoundUpload
                    square
                    className={classes.upload}
                    accept="image/*"
                    onChange={({ currentTarget }) => {
                      const clone = [...values.menuImgs];
                      const newImg = currentTarget.files[0];
                      clone[index] = newImg;
                      setFieldValue('menuImgs', clone);
                    }}
                    value={img}
                  />
                </Grid>
              ))
            }
            <Grid item align="center">
              <RoundUpload
                square
                className={classes.upload}
                accept="image/*"
                onChange={(e) => {
                  if (e.currentTarget.files[0]) {
                    const clone = [...values.menuImgs, e.currentTarget.files[0]];
                    setFieldValue('menuImgs', clone);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Typography className={classes.error}>
            {touched.menuImgs && errors.menuImgs}
          </Typography>
          <RoundButton className={classes.submit} type="submit">
            <Typography className={classes.submitLabel}>Continue</Typography>
          </RoundButton>
        </form>
      )}
    </Formik>
  );
};

SetHourOperation.propTypes = {
  onSubmit: PropTypes.func,
};

export default SetHourOperation;
