import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CardTitle = ({ title, icon }) => {
  return (
    <Grid container justify="space-between">
      <Grid item>
        {title}
      </Grid>
      {
        icon && (
          <Grid item>
            {icon}
          </Grid>
        )
      }
    </Grid>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default CardTitle;
