import { makeAutoObservable } from 'mobx';

class DataStore {
  visibleNotif = false;

  newNotification = null;

  role=''

  constructor() {
    makeAutoObservable(this);
    this.user = {};
    this.application = {};
    this.menu = [];
    this.notifications = [];
    this.applications = [];
    this.pollIntervalNotif = null;
  }

  setUser(u) {
    this.user = u;
  }

  setRole(r) {
    this.role = r;
  }

  setApplication(a) {
    this.application = a;
  }

  setApplications(a) {
    this.applications = a || [];
  }

  setMenuList(m) {
    this.menu = m;
  }

  setNotifications(notifs) {
    this.notifications = notifs || [];
  }

  showNotif(visible) {
    this.visibleNotif = visible;
  }

  setNotifPollingInterval(recurring) {
    this.pollIntervalNotif = recurring;
  }

  setNewNotification(notification) {
    this.newNotification = notification;
  }
}

export default new DataStore();
