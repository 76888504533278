import { Box, InputBase, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%'
  },
  searchBox: {
    backgroundColor: '#EFF0F2',
    borderRadius: 25,
    paddingLeft: 40,
    width: '100%'
  },
  image: {
    position: 'absolute',
    left: 10,
    top: 7
  }
}));

const SearchInput = ({ value, onChange, onKeyPress }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <InputBase placeholder="Search" className={classes.searchBox} value={value} onChange={onChange} onKeyPress={onKeyPress} />
      <img alt="" src="/static/images/search.svg" className={classes.image} />
    </Box>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
};

export default SearchInput;
