import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blue[600],
    height: 56,
    width: 56
  },
  upIcon: {
    color: colors.green[900]
  },
  downIcon: {
    color: colors.red[600]
  },
  differenceValue: {
    marginRight: theme.spacing(1)
  }
}));

const ActiveUserStats = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const monthStats = data?.activeUserInMonths?.slice().reverse() || [];
  const delta = (100 * (monthStats[0] - monthStats[1]));
  const lastMonth = monthStats[1] || 1;
  const percent = delta / lastMonth;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              ACTIVE USERS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {data?.userTotal || 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          {delta >= 0
            ? <ArrowUpwardIcon className={classes.upIcon} />
            : <ArrowDownIcon className={classes.downIcon} />}
          <Typography
            className={clsx(
              classes.differenceValue,
              delta >= 0 ? classes.upIcon : classes.downIcon
            )}
            variant="body2"
          >
            {`${percent}%`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ActiveUserStats.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default ActiveUserStats;
