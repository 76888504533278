import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Thumb = ({ image, uri, ...rest }) => {
  const [thumb, setThumb] = useState('');

  if (image && !uri) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumb(reader.result);
    };
    reader.readAsDataURL(image);
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      className={rest.className}
    >
      <img
        src={uri || thumb}
        alt={uri ? '' : image?.name}
        className="img-thumbnail"
        width={rest.width || 120}
        height={rest.height || 120}
      />
    </Box>
  );
};

Thumb.propTypes = {
  image: PropTypes.object,
  uri: PropTypes.string,
};

export default Thumb;
