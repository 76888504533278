import { configureStore } from '@reduxjs/toolkit';
import notiSlice from './noti-slice';

const store = configureStore({
  reducer: {
    noti: notiSlice.reducer,
  }
});

export default store;
