import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  makeStyles,
} from '@material-ui/core';
import { Roles } from 'src/utils/Settings';
import SelectUserRoleForm from './SelectUserRoleForm';
import TruckOwnerForm from './TruckOwnerForm';
import CustomerForm from './CustomerForm';

const useStyles = makeStyles(() => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0px 20px',
    height: '100%'
  },
}));

const AddUser = ({
  visible,
  onClose,
  onFetch
}) => {
  const classes = useStyles();
  const [currentForm, setCurrentForm] = useState('SelectUserRoleForm');
  const [userRole, setUserRole] = useState(Roles.CUSTOMER);

  const handleChangeUserRole = (item) => {
    setUserRole(item.key);
  };

  const handleClose = (result) => {
    onClose(result);
    setUserRole(Roles.CUSTOMER);
    setCurrentForm('SelectUserRoleForm');
  };

  return (
    <Drawer anchor="right" open={visible} onClose={handleClose}>
      {
        currentForm === 'SelectUserRoleForm'
        && (
          <SelectUserRoleForm
            className={classes.root}
            onChangeRole={handleChangeUserRole}
            selectedRole={userRole}
            onChangeStep={() => setCurrentForm(userRole === Roles.TRUCK_OWNER ? 'TruckOwnerForm' : 'CustomerForm')}
            onClose={handleClose}
          />
        )
      }
      {
        currentForm === 'TruckOwnerForm'
        && (
          <TruckOwnerForm
            className={classes.root}
            onClose={handleClose}
          />
        )
      }
      {
        currentForm === 'CustomerForm'
        && (
          <CustomerForm
            onFetch={onFetch}
            className={classes.root}
          />
        )
      }
    </Drawer>
  );
};

AddUser.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onFetch: PropTypes.func
};

export default AddUser;
