import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  Avatar,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import colors from 'src/common/colors';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    padding: 10,
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='grey' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  name: {
    flex: 1,
    fontWeight: '600',
  },
  time: {
    color: colors.green,
    fontWeight: '600',
  },
  dot: {
    position: 'absolute',
    right: 10,
    bottom: 8,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.orange,
  }
}));

const NotificationItem = ({
  className,
  data,
  onClick,
  seen,
}) => {
  const classes = useStyles();
  const {
    avatar, name, content, createdAt
  } = data;
  const time = moment(createdAt).format('MMM DD');

  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      mx={2}
      mb={1}
      onClick={onClick}
    >
      <Avatar
        className={classes.avatar}
        src={avatar}
      />
      <Box ml={1} flex={1}>
        <Box display="flex" flex={1}>
          <Typography className={classes.name} variant="body1">{name}</Typography>
          <Typography className={classes.time} variant="body2">{time}</Typography>
        </Box>
        <Typography className={classes.content} variant="body1">{content}</Typography>
      </Box>
      {!seen && <Box className={classes.dot} />}
    </Box>
  );
};

NotificationItem.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  seen: PropTypes.bool,
};

export default inject('dataStore')(observer(NotificationItem));
