import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import StringUtils from 'src/utils/StringUtils';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography
} from '@material-ui/core';
import colors from 'src/common/colors';
import InfoItem from './InfoItem';
import { daysOfWeek } from '../customer/components/SetHourOperation';

const useStyles = makeStyles(() => ({
  root: {},
  heading: {
    color: colors.headingTeal,
    textTransform: 'uppercase',
  }
}));

const OperationHours = ({
  className,
  data,
  useCard,
  ...rest
}) => {
  const classes = useStyles();
  const body = () => (
    <Box
      mt={2}
      className={clsx(classes.root, className)}
      display="flex"
      flexDirection="column"
      alignItems={rest.center ? 'center' : ''}
    >
      <Box mt={1} mb={3}>
        <Typography className={classes.heading} variant="h6" align="center">
          Hours of Operation
        </Typography>
      </Box>
      {
        data && data.sort((a, b) => {
          const days = daysOfWeek.map((e) => e.toUpperCase());
          if (days.indexOf(a.day) > days.indexOf(b.day)) return 1;
          if (days.indexOf(a.day) < days.indexOf(b.day)) return -1;
          return 0;
        }).map((e) => {
          const startTime = moment(e.startTime).format('hh:mm a');
          const endTime = moment(e.endTime).format('hh:mm a');
          return (
            <InfoItem
              key={e.id}
              title={StringUtils.upperCaseFirstLetter(e.day)}
              content={`${startTime} - ${endTime}`}
            />
          );
        })
      }
    </Box>
  );

  return (
    useCard ? <Card><CardContent>{body()}</CardContent></Card> : body()
  );
};

OperationHours.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  useCard: PropTypes.bool,
};

export default OperationHours;
