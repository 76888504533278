import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Settings, { Roles } from 'src/utils/Settings';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import RoundButton from 'src/components/RoundButton';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import AddUser from './AddUser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    backgroundColor: '#d00',
    textTransform: 'none',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#f00',
    },
    marginRight: 10
  },
  cancelButton: {
    backgroundColor: '#aaa',
    textTransform: 'none',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#999',
    },
    marginLeft: 10
  },
  deleteTitle: {
    textAlign: 'center'
  },
  deleteContent: {
    textAlign: 'center',
    marginBottom: 20
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row'
  },
}));

const Users = ({ ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { dataStore } = props;
  const [users, setUsers] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = useState('');
  const [visibleForm, showForm] = useState(false);
  const dispatch = useDispatch();
  const getUsers = () => APIService.getUsers((success, json) => {
    if (success && json.result) {
      setUsers(json.result.filter((e) => !e.deleted));
    }
  });

  const deleteItems = () => APIService.deleteUsers(selectedItems, (success, json) => {
    setConfirmDelete(false);
    if (success && json.result) {
      setSelectedItems([]);
      getUsers();
      dispatch(notiActions.viewNoti({ type: 'success', content: 'Deleted user successfully' }));
    } else {
      dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
    }
  });

  const suspendUser = (id, suspend) => {
    APIService.suspendUser(id, suspend, (success, json) => {
      if (success && json.result) {
        setUsers((preUsers) => preUsers.map((user) => {
          if (user.id === id) {
            return { ...user, suspended: suspend };
          }
          return user;
        }));
        dispatch(notiActions.viewNoti({ content: `${suspend ? 'Suspended' : 'Activated'} successfully!`, type: 'success' }));
      } else {
        dispatch(notiActions.viewNoti({ content: json.error, type: 'error' }));
      }
    });
  };

  const viewProfile = (user) => {
    dataStore.setApplication({});
    dataStore.setUser(user);
    Settings.setApplication({});
    Settings.setOtherProfile(user);
    navigate(`/app/profile/${user.id}`);
  };

  const verifyItem = (id) => APIService.verifyUser(id, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getUsers();
    }
  });

  const handleCloseAddUser = (result) => {
    showForm(false);
    if (result) {
      getUsers();
    }
  };

  useEffect(() => {
    getUsers();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Users"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => setConfirmDelete(true) : null}
          onAdd={() => showForm(true)}
          addText="Add User"
          filterBy={[
            {
              key: { name: 'Role', value: 'role' },
              options: [
                { name: 'Admin', value: Roles.ADMIN },
                { name: 'Truck owner', value: Roles.TRUCK_OWNER },
                { name: 'Customer', value: Roles.CUSTOMER },
              ]
            },
          ]}
          onFilter={(value) => { setFilter(value); }}
        />
        <Box mt={3}>
          <Results
            data={users}
            searchText={searchText}
            filter={filter}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
            onView={viewProfile}
            onVerify={verifyItem}
            onSuspend={suspendUser}
          />
        </Box>
        <AddUser
          visible={visibleForm}
          onClose={handleCloseAddUser}
          onFetch={getUsers}
        />
        <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
          <DialogTitle className={classes.deleteTitle}>
            Are you sure you want to delete users?
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.deleteContent}>
              These accounts will be permanently deleted from your
              system and you cannot restore them!
            </Typography>
            <Box className={classes.buttonBox}>
              <RoundButton
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={deleteItems}
                className={classes.deleteButton}
              >
                Delete
              </RoundButton>
              <RoundButton
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setConfirmDelete(false)}
                className={classes.cancelButton}
              >
                Cancel
              </RoundButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
};

Users.propTypes = {
  dataStore: PropTypes.object,
};

export default inject('dataStore')(observer(Users));
