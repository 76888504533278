import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Localizations = () => {
  const classes = useStyles();
  const location = useLocation();
  const [contents, setContents] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState('');

  const getContents = () => APIService.getLocalizations((success, json) => {
    if (success && json.result) {
      setContents(json.result);
    }
  });

  useEffect(() => {
    getContents();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Localizations"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
        />
        <Box mt={3}>
          <Results
            data={contents}
            searchText={searchText}
            selectedIds={selectedItems}
            onSelected={(ids) => setSelectedItems(ids)}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Localizations;
