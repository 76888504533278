import {
  Container, makeStyles, Typography, Grid, Box
} from '@material-ui/core';
import React from 'react';
import LogoTreadMeal from 'src/components/LogoTreadMeal';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: '60vh',
    padding: 30,
    backgroundColor: colors.skin,
  },
  contentWrapper: {
    backgroundColor: '#fff',
    borderRadius: 15,
    paddingTop: 250,
    paddingBottom: 250,
  },
  title: {
    fontSize: 21,
    fontWeight: '600',
  },
  message: {
    fontSize: 15,
    fontWeight: '600',
  },
  image: {
    width: '100%',
    maxWidth: 375,
    maxHeight: 51.8,
    marginTop: 40,
    marginBottom: 40,
  },
}));

const Maintenance = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container className={classes.contentWrapper} maxWidth="sm">
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Typography className={classes.title} align="center">We’ll be back soon!</Typography>
          <LogoTreadMeal className={classes.image} />
          <Typography className={classes.message} align="center">
            Sorry for the inconvenience.
          </Typography>
          <Typography className={classes.message} align="center">
            We’re performing some maintenance at the moment.
          </Typography>
        </Grid>
      </Container>
    </Box>
  );
};

export default Maintenance;
