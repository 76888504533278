import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
  Box,
  Avatar
} from '@material-ui/core';
import TextSearch from 'src/views/widgets/TextSearch';
import APIService from 'src/utils/APIService';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 40,
    width: 40
  }
}));

const CacheInspector = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [keys, setKeys] = useState([]);
  const [object, setObject] = useState({});

  const getCacheValueByKey = (key) => {
    APIService.getCacheValueByKey(key, (success, json) => {
      if (success && json.result) {
        setObject(json.result);
      }
    });
  };

  const getCacheKeys = () => {
    APIService.getCacheKeys((success, json) => {
      if (success && json.result) {
        setKeys(json.result);
      }
    });
  };

  const deleteCacheKey = (key) => {
    APIService.deleteCache(key, (success, json) => {
      if (success && json.result) {
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Deleted successfully' }));
        getCacheKeys();
      }
    });
  };

  useEffect(() => { getCacheKeys(); }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid item xs={12} md={10}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Key Inspector
            </Typography>
          </Grid>
          {object?.key && (
            <Grid item>
              <Box onClick={() => deleteCacheKey(object?.key)}>
                <Avatar className={classes.avatar}>
                  <DeleteIcon />
                </Avatar>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <TextSearch
              size="small"
              label="Key"
              options={keys.map((key) => ({
                id: key,
                name: key,
                key,
                // type: e.type
              }))}
              // value={parseOption(values[key])}
              onSelect={(item) => {
                if (item) {
                  getCacheValueByKey(item.key);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>{`Value: ${object?.value || ''}`}</Typography>
          </Grid>
        </Grid>
        {/* <Box mt={3}>
          <LinearProgress
            value={percent}
            variant="determinate"
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

CacheInspector.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default CacheInspector;
