import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ChevronRight } from '@material-ui/icons';
import colors from 'src/common/colors';
import Thumb from 'src/views/widgets/Thumb';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 495,
    borderRadius: 20,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
    marginLeft: 70,
    marginRight: 70,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 40,
      marginRight: 40,
    },
    [theme.breakpoints.down('xm')]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  title: {
    marginTop: 35,
    textAlign: 'center',
    color: colors.tmGreen,
    fontSize: 22,
  },
  desc: {
    marginTop: 30,
    width: 253,
    textAlign: 'center',
    color: colors.headingTeal,
    fontSize: 16,
    fontWeight: '500',
  },
  line: {
    marginTop: 32,
    width: 178,
    height: 3,
    backgroundColor: colors.lightGrey,
  },
  price: {
    marginTop: 30,
    textAlign: 'center',
    color: colors.action,
    fontSize: 45
  },
  frequency: {
    marginTop: 20,
    textAlign: 'center',
    height: 20,
    color: colors.action,
    fontSize: 12,
    fontWeight: '600',
  },
  button: {
    marginTop: 32,
    backgroundColor: colors.lightGreen,
    color: colors.action,
    borderRadius: 22,
    height: 62,
    width: 320,
    '&:hover': {
      backgroundColor: colors.action,
      color: colors.lightGreen,
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginRight: 20,
  }
}));

export const PricePlan = ({
  className,
  image,
  title,
  desc,
  price,
  frequency,
  buttonText,
  onClick,
}) => {
  const classes = useStyle();

  return (
    <Box className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent className={classes.body}>
          <Thumb height={195} width="auto" uri={image} />
          {!!title && <Typography className={classes.title}>{title}</Typography>}
          {!!desc && <Typography className={classes.desc}>{desc}</Typography>}
          <Box className={classes.line} />
          {!!price && <Typography className={classes.price}>{price}</Typography>}
          {!!frequency && <Typography className={classes.frequency}>{frequency}</Typography>}
          <Button
            className={classes.button}
            endIcon={<ChevronRight style={{ fontSize: 28 }} />}
            variant="contained"
            onClick={() => onClick && onClick()}
          >
            <Typography className={classes.buttonText}>{buttonText || ''}</Typography>
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

PricePlan.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  price: PropTypes.string,
  frequency: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default PricePlan;
