import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      borderRadius: 10,
      '& span': {
        fontWeight: '600',
        color: '#eee'

      }
    }
  },
  button: {
    // color: theme.palette.text.secondary,
    color: '#BBBBBB',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: 'white',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: 'theme.palette.primary.main'
    }
  },
  itemActive: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: 10,
    '& span': {
      color: '#eee',
      fontWeight: '600'
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)',
      borderRadius: 10,
      '& span': {
        color: '#fff',
        fontWeight: '600'
      }
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  toggle,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <ListItem
      className={location.pathname === href
        ? clsx(classes.item, classes.itemActive, className)
        : clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
        {toggle}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  toggle: PropTypes.object,
  title: PropTypes.string
};

export default NavItem;
