import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Button,
  TableSortLabel,
} from '@material-ui/core';
import moment from 'moment';
import { EditOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  searchText,
  onSelected,
  selectedIds,
  onPuzzle,
  onPhrase,
  onPreView,
  onDelete,
  onViewDetails,
  editItem,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState([]);
  const [sortItem, setSortItem] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('asc');

  const handleSelectAll = (event) => {
    let newSelectedItemIds;

    if (event.target.checked) {
      newSelectedItemIds = data.map((item) => item.id);
    } else {
      newSelectedItemIds = [];
    }
    onSelected(newSelectedItemIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItemIds = newSelectedItemIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    onSelected(newSelectedItemIds);
  };

  const onSort = (a, b, type) => {
    if (typeof (a) === 'number' && typeof (b) === 'number') {
      return type === 'asc' ? (a - b) : (b - a);
    }
    if (typeof (a) === 'string' && typeof (b) === 'string') {
      return type === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    }
    if (typeof (a) === 'undefined' || !a) return 1;
    if (typeof (b) === 'undefined' || !b) return -1;
    return 0;
  };

  const handleSort = (item) => {
    setSortItem(item);
    if (!item.getItem) return;
    if (orderBy !== item.title) {
      setOrderBy(item.title);
      setOrderType('asc');
      setRecords(data.sort((a, b) => onSort(item.getItem(a), item.getItem(b), 'asc')));
      console.log(item.getItem(data[0]));
    } else {
      setRecords(data.sort((a, b) => onSort(item.getItem(a), item.getItem(b), orderType === 'asc' ? 'desc' : 'asc')));
      setOrderType((preState) => (preState === 'asc' ? 'desc' : 'asc'));
    }
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onFilter = (searchKeyword) => {
    return data.filter((item) => {
      return item.title.toLowerCase().indexOf(searchKeyword) >= 0;
    });
  };

  const search = searchText ? searchText.toLowerCase() : '';
  useEffect(() => {
    if (sortItem) {
      setRecords(onFilter(search).sort((a, b) => onSort(
        sortItem.getItem(a), sortItem.getItem(b), orderType
      )));
    } else {
      setRecords(onFilter(search));
    }
  }, [data, search]);
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === data.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {
                  [
                    {
                      title: 'Id',
                      sortable: true,
                      getItem: (item) => item?.id,
                    },
                    {
                      title: 'Key',
                      sortable: true,
                      getItem: (item) => item?.key,
                    },
                    {
                      title: 'Title',
                      sortable: true,
                      getItem: (item) => item?.title,
                    },
                    {
                      title: 'Index',
                      sortable: true,
                      getItem: (item) => item?.sortOrder,
                    },
                    {
                      title: 'Number of Truck Owners',
                      sortable: true,
                      getItem: (item) => item?._count?.users,
                    },
                    {
                      title: 'Created At',
                      sortable: false,
                    },
                    {
                      title: '',
                      sortable: false,
                    },

                  ].map((item) => (
                    <TableCell key={item.title}>
                      {item.sortable ? (
                        <TableSortLabel
                          active={orderBy === item.title}
                          direction={orderBy === item.title ? orderType : 'asc'}
                          onClick={() => handleSort(item)}
                          style={{ display: 'flex' }}
                        >
                          {item.title}
                        </TableSortLabel>
                      ) : item.title}

                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.indexOf(item.id) !== -1}
                        onChange={(event) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      {item.id}
                    </TableCell>
                    <TableCell>
                      {item.key}
                    </TableCell>
                    <TableCell>
                      {item.title}
                    </TableCell>
                    <TableCell>
                      {item.sortOrder}
                    </TableCell>
                    <TableCell>
                      {item?._count?.users}
                    </TableCell>
                    <TableCell>
                      {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => editItem(item)}>
                        <EditOutlined />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
  onPuzzle: PropTypes.func,
  onPhrase: PropTypes.func,
  onPreView: PropTypes.func,
  onDelete: PropTypes.func,
  onViewDetails: PropTypes.func,
  editItem: PropTypes.func,
};

export default Results;
