/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Grid,
  makeStyles,
  Avatar,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  colors,
  InputBase
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UploadButton from 'src/components/UploadButton';
import RoundButton from 'src/components/RoundButton';
import APIService from 'src/utils/APIService';
import CustomSlider from 'src/components/CustomSlider';
import RoundSelect from 'src/components/RoundSelect';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import Thumb from '../widgets/Thumb';
import InputSC from '../menu/InputSC';
import RoundTimePicker from '../customer/components/RoundTimePicker';
import { daysOfWeek } from '../customer/components/SetHourOperation';
import CustomRadio from '../customer/components/CustomRadio';

const useStyle = makeStyles(() => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 30,
  },
  thumb: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    width: 88,
    height: 88,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  dash: {
    padding: 10,
    borderRadius: 55,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
  },
  sectionTitle: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
  },
  error: {
    fontSize: 12,
    color: colors.red[500],
  },
  input: {
    width: '100%',
    marginTop: 22,
  },
  paymentWrapper: {
  },
  paymentItem: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 15,
  },
  descriptionWrapper: {
    padding: 10,
    width: '100%',
    borderRadius: 20,
    height: 190,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  descriptionInputWrapper: {
    height: 150,
    overflowY: 'auto',
  },
  descriptionInput: {
    width: '100%',
  },
  descriptionCountWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  descriptionCount: {
  }
}));

const PAYMENT_METHOD = [
  {
    key: 'CASH',
    title: 'Cash'
  },
  {
    key: 'CREDIT',
    title: 'Credit'
  },
];
const EditProfileTO = ({
  profile, open, onClose, ...rest
}) => {
  const classes = useStyle();
  const [cuisines, setCuisines] = useState([]);
  const [isChangeDay, setIsChangeDay] = useState(false);
  const [days, setDays] = useState(daysOfWeek.map((day) => {
    if (Array.isArray(profile?.operation?.openingHours)) {
      for (const i of profile?.operation?.openingHours) {
        if (day.toUpperCase() === i.day) {
          return {
            day,
            isChecked: true,
            startTime: i.startTime,
            endTime: i.endTime
          };
        }
      }
    }
    return { day, isChecked: false };
  }));
  const dispatch = useDispatch();
  let descriptionRef = useRef();
  useEffect(() => {
    APIService.getCuisines((success, json) => {
      if (success && json.result) {
        setCuisines(json.result);
      }
    });
  }, []);
  const { dataStore } = rest;

  const onTimeChange = (date, type, hour) => {
    const selectedDay = days.find((e) => e.day === hour.day);
    if (type === 'start') {
      selectedDay.startTime = date;
    } else {
      selectedDay.endTime = date;
    }
    setDays([...days]);
  };

  console.log(profile);

  const onToggleDay = (value, onUpdate) => {
    setIsChangeDay(true);
    const selectedDay = days.find((e) => e.day === value.day);
    selectedDay.isChecked = !selectedDay.isChecked;

    if (!selectedDay.startTime) {
      selectedDay.startTime = new Date();
    }
    if (!selectedDay.endTime) {
      selectedDay.endTime = new Date();
    }

    if (onUpdate) onUpdate();
  };

  const onEdit = (values, actions) => {
    let params = {
      id: profile?.id,
      firstName: values.firstName,
      lastName: values.lastName,
      cuisineId: values.cuisineId,
      businessName: values.businessName,
      phoneNumber: values.phoneNumber,
      pricePoint: values.pricePoint,
      paymentMethod: values.paymentMethods,
      description: values.description,
      email: values.email,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode
    };
    if (values.tempAvatar) {
      params = {
        ...params,
        avatar: values.tempAvatar
      };
    }
    if (isChangeDay) {
      let operationHours = [];
      for (const day of days) {
        if (day.isChecked) {
          operationHours = [
            ...operationHours,
            { day: day.day.toUpperCase(), startTime: day.startTime, endTime: day.endTime }
          ];
        }
      }
      params = {
        ...params,
        operationHours
      };
    }
    APIService.updateTOProfile(params, (success, json) => {
      if (success && json.result) {
        const data = { ...profile, ...json.result };
        dataStore.setUser(data);
        if (onClose) onClose();
        dispatch(notiActions.viewNoti({ type: 'success', content: 'Updated Truck Owner profile successfully' }));
      } else {
        dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
      }
    });
    actions.setSubmitting(false);
  };
  return (
    <Drawer anchor="right" open={open} onClose={() => onClose(false)}>
      <Grid className={classes.root} {...rest}>
        <Grid item xs={12} sm={12} md={12}>
          <Formik
            initialValues={{
              firstName: profile.firstName,
              lastName: profile.lastName,
              businessName: profile.businessName,
              email: profile.email,
              city: profile?.contact?.city ?? '',
              state: profile?.contact?.state ?? '',
              zipCode: profile?.contact?.zipCode,
              phoneNumber: profile.phoneNumber,
              pricePoint: profile?.operation?.pricePoint ?? 1,
              cuisineId: profile?.cuisineId,
              paymentMethods: profile?.operation?.paymentMethods ?? [],
              description: profile?.operation?.description ?? '',
              avatarUrl: profile.avatarURL
              ?? profile?.operation?.logoURL
                ? profile?.operation?.logoURL
                : null,
              tempAvatar: null,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().max(100).required('First Name is required'),
              lastName: Yup.string().max(80).required('Last Name is required'),
              description: Yup.string().max(300, 'Description limit 300 words'),
              email: Yup.string()
                .email('Email must be a valid email')
                .required('Email is required'),
              phoneNumber: Yup.string()
                .matches(/^[0-9-+]{1,12}$/, 'Phone Number must be a valid phone number')
                .min(10, 'Phone Number must have at least 10 digits')
                .max(12, 'Phone Number must have less than 13 digits')
                .required('Phone Number is required'),
              city: Yup.string().required('City is required'),
              state: Yup.string(),
              zipCode: Yup.string()
                .min(5, 'Zip code must have 5 digits')
                .max(5, 'Zip code must have 5 digits')
                .required('Zip Code is required')
            })}
            onSubmit={(values, actions) => {
              onEdit(values, actions);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setErrors,
              setTouched,
              touched,
              values
            }) => (
              <form className={classes.formContent} onSubmit={handleSubmit}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box className={classes.dash}>
                    {values.tempAvatar == null ? (
                      <Avatar
                        className={classes.thumb}
                        variant="circular"
                        width={88}
                        height={88}
                        src={values.avatarUrl ?? '/static/images/img_no_avatar.png'}
                      />
                    )
                      : (
                        <Thumb
                          className={classes.thumb}
                          image={values.tempAvatar}
                          width={88}
                          height={88}
                          uri={!values.tempAvatar ? '/static/images/img_no_avatar.png' : null}
                        />
                      )}
                  </Box>
                </Box>
                <UploadButton
                  onChange={(e) => { setFieldValue('tempAvatar', e.currentTarget.files[0]); }}
                />
                <InputSC
                  label="First Name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={errors.firstName}
                />
                <InputSC
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={errors.lastName}
                />
                <InputSC
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={errors.email}
                />
                <InputSC
                  label="Business Name"
                  name="businessName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.businessName}
                  error={Boolean(touched.businessName && errors.businessName)}
                  helperText={errors.businessName}
                />
                <InputSC
                  label="Phone Number"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (e.target.value?.length <= 12) {
                      setTouched({ phoneNumber: true });
                      setFieldValue('phoneNumber', e.target.value);
                    } else {
                      setErrors({ phoneNumber: 'Phone Number must have less than 13 digits' });
                    }
                  }}
                  value={values.phoneNumber}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={errors.phoneNumber}
                />
                <Typography align="center" className={classes.sectionTitle}>
                  Price Point
                </Typography>
                <CustomSlider
                  marks={['$', '$', '$', '$']}
                  value={values.pricePoint}
                  onChange={(value) => setFieldValue('pricePoint', value)}
                />
                <Typography align="center" className={classes.sectionTitle}>
                  Cuisine
                </Typography>
                <RoundSelect
                  className={classes.input}
                  name="cuisineId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  height={56}
                  value={values?.cuisineId?.toString()}
                  defaultValue=""
                  error={Boolean(touched.cuisineId && errors.cuisineId)}
                  helperText={
                    (touched.cuisineId && errors.cuisineId) ? errors.cuisineId : ''
                  }
                >
                  <MenuItem disabled value="">
                    Cuisine
                  </MenuItem>
                  {
                    cuisines
                    && cuisines.map((cuisine) => (
                      <MenuItem key={cuisine.id} value={cuisine.id}>
                        {cuisine.title}
                      </MenuItem>
                    ))
                  }
                </RoundSelect>
                <Typography align="center" className={classes.sectionTitle}>
                  Hours of Operation
                </Typography>
                {days && days.map((hour) => (
                  <Box
                    key={hour.day}
                    display="flex"
                    flexDirection="row"
                    alignItems="space-between"
                    justifyContent="space-between"
                    paddingBottom="10px"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="20%"
                    >
                      <FormControlLabel
                        key={hour.day}
                        control={(
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleChecked />}
                            onClick={() => onToggleDay(hour, () => {
                              const enabledDays = days.filter((e) => e.isChecked).map((e) => e.day);
                              setFieldValue('opeHours', enabledDays);
                            })}
                            checked={hour.isChecked}
                          />
                    )}
                        label={hour.day}
                      />
                    </Box>
                    {
                  hour.isChecked && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                      width="60%"
                    >
                      <RoundTimePicker
                        initialValue={hour.startTime}
                        onTimeChange={(date) => { onTimeChange(date, 'start', hour); setIsChangeDay(true); }}
                      />
                      <Typography>-</Typography>
                      <RoundTimePicker
                        initialValue={hour.endTime}
                        onTimeChange={(date) => { onTimeChange(date, 'end', hour); setIsChangeDay(true); }}
                      />
                    </Box>
                  )
                }
                  </Box>
                ))}
                <InputSC
                  label="City"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  helperText={errors.city}
                />
                <InputSC
                  label="State"
                  name="state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.state}
                  error={Boolean(touched.state && errors.state)}
                  helperText={errors.state}
                />
                <InputSC
                  label="Zip Code"
                  name="zipCode"
                  onBlur={handleBlur}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value?.length < 6) {
                      setTouched({ zipCode: true });
                      setFieldValue('zipCode', e.target.value);
                    } else {
                      setErrors({ zipCode: 'Zip code must have 5 digits' });
                    }
                  }}
                  value={values.zipCode}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  helperText={errors.zipCode}
                />
                <Typography align="center" className={classes.sectionTitle}>
                  Payment Methods
                </Typography>
                <Box
                  className={classes.paymentWrapper}
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  {PAYMENT_METHOD.map((item) => {
                    const isSelected = values.paymentMethods.includes(item.key);
                    return (
                      <Box className={classes.paymentItem}>
                        <CustomRadio
                          isSelected={isSelected}
                          onClick={() => {
                            if (isSelected) {
                              setFieldValue('paymentMethods', values?.paymentMethods.filter((e) => e !== item.key));
                            } else {
                              setFieldValue('paymentMethods', [...values?.paymentMethods, item.key]);
                            }
                          }}
                          key={item.key}
                        />
                        <Typography>{item.title}</Typography>
                      </Box>
                    );
                  })}
                </Box>

                <Typography align="center" className={classes.sectionTitle}>
                  Description
                </Typography>
                <Box className={classes.descriptionWrapper}>
                  <Box
                    className={classes.descriptionInputWrapper}
                    onClick={() => descriptionRef.focus()}
                  >
                    <InputBase
                      error={Boolean(touched.description && errors.description)}
                      value={values.description}
                      inputRef={(ref) => { descriptionRef = ref; }}
                      name="description"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.currentTarget.value.length <= 300) {
                          setFieldValue('description', e.currentTarget.value);
                        }
                      }}
                      className={classes.descriptionInput}
                      multiline
                      helperText={errors.description}
                    />
                  </Box>
                  <Box className={classes.descriptionCountWrapper}>
                    <Typography className={classes.descriptionCount}>{`${values.description.length}/300`}</Typography>
                  </Box>
                </Box>
                <Typography className={classes.error}>
                  {touched.opeHours && errors.opeHours}
                </Typography>
                <Box padding="40px 0px">
                  <RoundButton
                    type="submit"
                    disabled={isSubmitting}
                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                  >
                    Save

                  </RoundButton>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Drawer>
  );
};

EditProfileTO.propTypes = {
  profile: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default inject('dataStore')(observer(EditProfileTO));
