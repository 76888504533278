import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Snackbar, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { Provider } from 'mobx-react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import dataStore from './common/dataStore';
import NotificationDrawer from './views/notification/NotificationDrawer';
import { notiActions } from './common/store/noti-slice';

const App = () => {
  const routing = useRoutes(routes);
  const noti = useSelector((state) => state.noti);
  const dispatch = useDispatch();
  return (
    <ThemeProvider theme={theme}>
      <Provider dataStore={dataStore}>
        <GlobalStyles />
        {routing}
        <Snackbar
          open={noti.hasNoti}
          autoHideDuration={2000}
          onClose={() => dispatch(notiActions.hideNoti())}
        >
          <Alert severity={noti.type}>{noti.content}</Alert>
        </Snackbar>
        <NotificationDrawer />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
