import React, { useEffect, useState } from 'react';
import { useNavigate, Link as Link2 } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  Drawer,
  Hidden,
  IconButton,
  InputBase,
  Link,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import colors from 'src/common/colors';
import RoundButton from 'src/components/RoundButton';
import LogoTreadMeal from 'src/components/LogoTreadMeal';
import Thumb from 'src/views/widgets/Thumb';
import { inject, observer } from 'mobx-react';
import LoginForm from './LoginForm';
import SignUpButton from './SignUpButton';

const useStyles = makeStyles(() => ({
  root: {
    width: 280,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  item: {
    display: 'flex',
    width: '100%',
    height: 52,
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    color: colors.black,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.tmGreen,
      textDecoration: 'none',
    }
  },
  signUp: {
    marginTop: 12
  },
  dismiss: {
    position: 'fixed',
    bottom: 20,
    right: 210,
    transform: 'rotate(90deg)',
  },
  logout: {
    position: 'fixed',
    bottom: 25,
    right: 20,
    filter: 'saturate(100%) brightness(70%)',
  },
  searchItem: {
    display: 'flex',
    width: '100%',
    height: 52,
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 26,
    flexGrow: 1,
    borderRadius: 13,
    enabled: true,
    border: '1px solid #000',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    maxWidth: 220
  },
  buttonSearch: {
    width: 20,
    height: 20,
    maxWidth: 20,
    marginRight: 10
  },
  iconSearch: {
    width: 20,
    height: 'auto'
  },
  input: {
    fontSize: 16,
  },
  content: {
    width: 350,
    maxWidth: '100%',
    '@media only screen and (max-width: 410px)': {
      padding: 0
    }
  }
}));

const DrawerMenu = ({
  isAuth, items, open, onLogin, onLogout, onClose, ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [viewPopup, setViewPopup] = useState('');
  const { dataStore } = rest;

  useEffect(() => {
    if (!open) {
      setViewPopup('');
    }
  }, [open]);

  const onViewPopup = (type) => {
    setViewPopup(type);
  };

  const onSignUp = () => {
    setViewPopup('');
    if (onClose) onClose();
  };

  const logout = () => {
    if (onLogout) onLogout();
    if (onClose) onClose();
  };

  const onSearch = () => {
    navigate(`/map?keyword=${searchText}`, true);
    window.location.reload();
    setSearchText('');
    setSearching(false);
    onClose();
  };

  return (
    <Hidden mdUp>
      <Drawer anchor="right" open={open} onClose={() => onClose && onClose()}>
        <Box className={classes.root} mt={2}>
          <Box display="flex" justifyContent="center">
            <LogoTreadMeal />
          </Box>
          <Box className={classes.drawerMenu}>
            <List>
              {items.map((item, index) => {
                if (!searching && item.key === 'AUTH_SEARCH') {
                  return (
                    <Link2
                      className={classes.item}
                      to="#"
                      key={item.title}
                      onClick={() => { if (dataStore?.user?.role === 'CUSTOMER') setSearching(true); }}
                    >
                      <Typography>{item.title}</Typography>
                    </Link2>
                  );
                }

                if (!searching || item.key !== 'AUTH_SEARCH') {
                  return (
                    <Link
                      className={classes.item}
                      href={item.path}
                      key={item.title}
                      tabIndex="-1"
                      onBlurCapture={() => onClose && onClose()}
                    >
                      <Typography>{item.title}</Typography>
                    </Link>
                  );
                }

                return (
                  <Box key={`${index.toString()}`} className={classes.searchItem}>
                    <Box className={classes.searchWrapper}>
                      <IconButton
                        className={classes.buttonSearch}
                        width={20}
                        onClick={() => { onSearch(); onClose(); }}
                      >
                        <img
                          src="/static/images/search_icon.png"
                          alt="search"
                          className={classes.iconSearch}
                        />
                      </IconButton>
                      <InputBase
                        id="outlined-basic"
                        className={classes.input}
                        placeholder="Search"
                        value={searchText}
                        onKeyDown={(e) => { if (e.keyCode === 13) { onSearch(); onClose(); } }}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>

                );
              })}
            </List>
          </Box>
          {!isAuth && (
            <Box display="flex" flexDirection="column" mx={3} mt={2}>
              <RoundButton onClick={() => onViewPopup('LOGIN')}>
                Login
              </RoundButton>
              <RoundButton style={{ marginTop: 15 }} onClick={() => onViewPopup('SIGN_UP')}>
                Sign up
              </RoundButton>
            </Box>
          )}
        </Box>
        <Box className={classes.dismiss}>
          <IconButton onClick={onClose}>
            <Thumb uri="/static/images/icon_slider_thumb.png" width={40} height={40} />
          </IconButton>
        </Box>
        {isAuth && (
          <Box className={classes.logout}>
            <IconButton onClick={() => logout()}>
              <Thumb uri="/static/images/logout_icon.png" width={30} height={30} />
            </IconButton>
          </Box>
        )}
      </Drawer>
      <Dialog open={open && viewPopup !== ''} onClose={() => setViewPopup('')}>
        <DialogContent className={classes.content}>
          {viewPopup === 'LOGIN' && <LoginForm onLogin={onLogin} onClose={onClose} />}
          {viewPopup === 'SIGN_UP' && (
            <SignUpButton onSignUp={onSignUp} />
          )}
        </DialogContent>
      </Dialog>
    </Hidden>
  );
};

DrawerMenu.propTypes = {
  isAuth: PropTypes.bool,
  items: PropTypes.array,
  open: PropTypes.bool,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  onClose: PropTypes.func,
};
export default inject('dataStore')(observer(DrawerMenu));
