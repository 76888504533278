import {
  Box, Button, makeStyles, Popover, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import colors from 'src/common/colors';
import Thumb from 'src/views/widgets/Thumb';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
  },
  button: {
    width: 120,
    borderRadius: 50,
    paddingTop: 18,
    paddingBottom: 18,
    backgroundColor: 'white',
    borderWidth: 0,
  },
  buttonActive: {
    backgroundColor: '#39E214'
  },
  buttonText: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
  },
  popup: {
    '& .MuiPopover-paper': {
      background: 'none',
      boxShadow: 'none',
      padding: 4,
      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
    }
  },
  body: {
    borderRadius: 20,
    backgroundColor: colors.white,
  },
  dropdown: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 50,
  },
  buttonItem: {
    width: 300
  }
}));

const DropdownButton = ({
  title, isViewDropdown, setIsViewDropdown, children
}) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);

  const clickButtonHandler = (evt) => {
    setAnchor(evt.currentTarget);
    setIsViewDropdown(evt);
  };

  const onClose = () => {
    setAnchor(null);
    setIsViewDropdown();
  };

  return (
    <Box className={classes.buttonWrapper}>
      <Button
        disableElevation
        variant="contained"
        className={`${classes.button} ${isViewDropdown ? classes.buttonActive : ''}`}
        onClick={clickButtonHandler}
      >
        <Typography className={classes.buttonText}>
          {title}
        </Typography>
      </Button>
      <Popover
        className={classes.popup}
        open={isViewDropdown && !!anchor}
        anchorEl={anchor}
        onClose={() => onClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Thumb
          className={classes.dropdown}
          uri="/static/images/drop.png"
          width={36}
          height={11}
        />
        <Box className={classes.body}>
          {children}
        </Box>
      </Popover>
    </Box>
  );
};
DropdownButton.propTypes = {
  title: PropTypes.string,
  isViewDropdown: PropTypes.bool,
  setIsViewDropdown: PropTypes.func,
  children: PropTypes.element
};
export default DropdownButton;
