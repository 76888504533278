export default {
  id: 23,
  status: 'ACTIVE',
  createdAt: '2021-08-13T10:43:13.275Z',
  updatedAt: '2021-08-13T11:03:15.321Z',
  latitude: '10.7770878',
  longitude: '106.704232',
  owner: {
    id: 21,
    businessName: 'Meal on Wheels',
    cuisine: {
      id: 2,
      key: 'American',
      title: 'American'
    },
    operation: {
      id: 14,
      name: null,
      description: 'Welcome to Gboard clipboard, any text you copy will be saved here.',
      openingHours: [
        {
          id: 38,
          day: 'MONDAY',
          startTime: '2021-08-12T23:30:27.517Z',
          endTime: '2021-08-13T11:30:27.517Z',
          operationId: 14,
          applicationId: 21,
          createdAt: '2021-08-13T10:41:28.567Z',
          updatedAt: '2021-08-13T10:41:28.570Z'
        },
        {
          id: 39,
          day: 'FRIDAY',
          startTime: '2021-08-12T23:30:27.517Z',
          endTime: '2021-08-13T11:30:27.517Z',
          operationId: 14,
          applicationId: 21,
          createdAt: '2021-08-13T10:41:28.567Z',
          updatedAt: '2021-08-13T10:41:28.570Z'
        }
      ],
      paymentMethods: [
        'CREDIT'
      ],
      menuGroups: [],
      pricePoint: 2,
      logoURL: 'http://treadmeal-staging.netpower.vn:3002/uploads/3f249faf89cf77f9b37056d402e5203b.jpg'
    },
    contact: {
      id: 12,
      phoneNumber: null,
      email: null,
      address: '123 some street',
      city: 'Some City',
      state: null,
      zipCode: 70000,
      createdAt: '2021-08-13T10:39:32.542Z',
      updatedAt: '2021-08-13T10:39:32.543Z'
    },
    phoneNumber: '123456789',
    email: 'foodtruck1@test.com'
  }
};
