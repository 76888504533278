import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import PaymentMethod from './PaymentMethod';
import TextHeading from './TextHeading';

const useStyles = makeStyles(() => ({
  root: {},
}));

const PaymentMethods = ({ data }) => {
  const classes = useStyles();
  const cash = data?.includes('CASH');
  const credit = data?.includes('CREDIT');

  return (
    <Box
      mt={2}
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <TextHeading variant="h6">Accepted Payment Methods</TextHeading>
      <Box display="flex">
        <PaymentMethod name="Cash" enabled={cash} />
        <PaymentMethod name="Credit" enabled={credit} ml={5} />
      </Box>
    </Box>
  );
};

PaymentMethods.propTypes = {
  data: PropTypes.array,
};

export default PaymentMethods;
