import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import Thumb from 'src/views/widgets/Thumb';

const useStyle = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 60,
    right: 20,
    width: 60,
    height: 60,
    borderRadius: 30,
  }
}));

const FlyTop = ({ to }) => {
  const classes = useStyle();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setHidden(window.scrollY < 150);
    }, { passive: true });
  }, [window.scrollY]);

  return (
    <Button className={classes.root} href={to} style={{ visibility: hidden && 'hidden' }}>
      <Thumb uri="/static/images/icon_slider_thumb.png" width={60} height={60} />
    </Button>
  );
};

FlyTop.propTypes = {
  to: PropTypes.string,
};

export default FlyTop;
