import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import InfoItem from './InfoItem';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 80,
    width: 80,
    alignSelf: 'center',
  }
}));

const Profile = ({
  className, data, compact, ...rest
}) => {
  const classes = useStyles();
  const { user, pricePoint } = data;
  const pricePoints = Array(pricePoint || 1).fill('$').join('');

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={user?.operation.logoURL}
          />
          <Box mt={3} />
          <InfoItem title="Company" content={user?.businessName} />
          <InfoItem title="Cuisine" content={user?.cuisine?.title} />
          <InfoItem title="Price Point" content={pricePoints} />
          <Divider />
          {!compact && <InfoItem title="Owner" content={`${user?.firstName} ${user?.lastName}`} />}
          {!compact && <InfoItem title="Email" content={user?.email} />}
          <InfoItem title="Phone #" content={user?.phoneNumber} />
          <InfoItem title="Address" content={user?.contact?.address} />
          <InfoItem title="City" content={user?.contact?.city} />
          <InfoItem title="Zip Code" content={`${user?.contact?.zipCode}`} />
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  compact: PropTypes.bool,
};

export default Profile;
