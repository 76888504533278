import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Card,
  Typography,
  Container,
  Grid,
  Box,
} from '@material-ui/core';
import ColorSection from 'src/components/ColorSection';
import colors from 'src/common/colors';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    paddingBottom: 30,
  },
  box: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    backgroundColor: colors.white,
    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
    '&:hover': {
      backgroundColor: colors.skin,
    }
  },
  link: {
    marginLeft: 10
  },
  image: {
    width: '100%',
    maxWidth: 293,
    padding: 20
  },
  text: {
    width: 275,
    fontSize: 20,
    textAlign: 'center',
    color: colors.darkBlue,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    borderRadius: 20,
    paddingBottom: 30,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  tabButton: {
    fontSize: 'calc(3vw + 3vmin)',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('lg')]: {
      fontSize: 70,
    },
  }
}));

const Tab = {
  CUSTOMER: 0,
  TRUCK_OWNER: 1
};

const Roles = [
  { id: Tab.CUSTOMER, title: 'Customers' },
  { id: Tab.TRUCK_OWNER, title: 'Food Truck Owners' }
];

const CustomerCards = [
  { url: 'customer_guide_1', desc: 'Use your location to find trucks near you' },
  { url: 'customer_guide_2', desc: 'Browse through local options' },
  { url: 'customer_guide_3', desc: 'View food truck’s profiles, menus, and more' },
];

const TruckOwnerCards = [
  { url: 'to_guide_1', desc: 'Apply and Create your business’s profile' },
  { url: 'to_guide_2', desc: 'Choose when and where to share your location with customers' },
  { url: 'to_guide_3', desc: 'Keep track of all your active trucks’ locations' },
];

const HowItWorks = (props) => {
  const [background, setBackground] = useState('white');
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/how-it-works') {
      setBackground(colors.skin);
    } else {
      setBackground('white');
    }
  }, [location.pathname]);

  const [tabIndex, setTabIndex] = useState(Tab.CUSTOMER);

  return (
    <ColorSection title="How it Works" color={background} {...props} className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={4} justifyContent="space-between">
          {
            Roles.map((item, index) => (
              <Grid key={`${index.toString()}`} item xs={6} sm={6} md={6}>
                <Box
                  className={classes.box}
                  style={{ float: index % 2 === 0 ? 'left' : 'right' }}
                >
                  <Typography
                    className={classes.tabButton}
                    onClick={() => setTabIndex(item.id)}
                    style={{
                      color: item.id === tabIndex ? colors.lightGreen : 'black',
                      fontWeight: item.id === tabIndex ? 'bold' : null,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Grid>
            ))
          }
        </Grid>
        <Grid container spacing={4}>
          {
            (tabIndex === Tab.CUSTOMER ? CustomerCards : TruckOwnerCards).map((item, index) => (
              <Grid key={`${index.toString()}`} item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <img alt="Log out" src={`/static/images/${item.url}.png`} className={classes.image} />
                  <Typography align="center" className={classes.text}>{item.desc}</Typography>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </ColorSection>

  );
};

export default HowItWorks;
