import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  TextField
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Thumb from '../widgets/Thumb';

const Uploader = ({ visible, onClose, onSuccess }) => {
  const sessionKey = localStorage.getItem('sessionKey');
  const [thumb, setThumb] = useState('');
  const onCloseModal = () => {
    setThumb(null);
    if (onClose) onClose();
  };
  return (
    <Dialog open={visible} onClose={onCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Promotion</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            price: '',
            url: '',
            image: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Name is required'),
            price: Yup.number(),
            url: Yup.string().max(512).required('URL is required'),
            image: Yup.string().required('Image is required'),
          })}
          onSubmit={(values, actions) => {
            APIService.addPromotion(
              sessionKey,
              values.name,
              values.price,
              values.url,
              values.image,
              (success, json) => {
                actions.setSubmitting(false);
                if (success && json.result) {
                  actions.setStatus('Success');
                  onCloseModal();
                  if (onSuccess) onSuccess(json);
                } else {
                  actions.setErrors({ error: 'Failed to upload!' });
                }
              }
            );
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                autoFocus
                label="Name"
                margin="dense"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                fullWidth
                label="Price"
                margin="dense"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.price}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.url && errors.url)}
                helperText={touched.url && errors.url}
                fullWidth
                label="URL"
                margin="dense"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.url}
                variant="outlined"
              />
              <Box mt={2}>
                <Button
                  variant="outlined"
                  component="label"
                >
                  Select File
                  <Input
                    style={{ display: 'none' }}
                    type="file"
                    name="image"
                    onChange={(event) => {
                      setFieldValue('image', event.target.files[0]);
                      setThumb(event.target.files[0]);
                    }}
                  />
                </Button>
                {thumb && <Thumb image={thumb} />}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width={400}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
                <Box ml={4} />
                <Button
                  color="secondary"
                  size="large"
                  variant="outlined"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

Uploader.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default Uploader;
