import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import APIService from 'src/utils/APIService';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import Results from './Results';
import Toolbar from '../widgets/Toolbar';
import Content from './Content';
import Uploader from './Uploader';
// import ReportDetail from './ReportDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Cuisines = () => {
  const classes = useStyles();
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState();
  const [cuisine, setCuisine] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleForm, showForm] = useState(false);
  // const [detailReport, setDetailReport] = useState();
  const [visibleContent, showContent] = useState(false);
  // const [viewDetail, setViewDetail] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const getContents = () => APIService.getCuisinesByAdmin((success, json) => {
    if (success && json.result) {
      setContents(json.result);
    }
  });

  const deleteItems = () => APIService.deleteManyCuisines(selectedItems, (success, json) => {
    if (success && json.result) {
      setSelectedItems([]);
      getContents();
      dispatch(notiActions.viewNoti({ content: 'Deleted cuisines successfully!', type: 'success' }));
    } else {
      dispatch(notiActions.viewNoti({ content: json.error, type: 'error' }));
    }
  });

  // const deletePost = (id) => APIService.deleteMenu(id, (success, json) => {
  //   if (success && json.result) {
  //     setSelectedItems([]);
  //     getContents();
  //   }
  // });

  const onShowContent = (item) => {
    showContent(true);
    setContent(item.body);
  };

  useEffect(() => {
    getContents();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Cuisines"
    >
      <Container maxWidth={false}>
        <Toolbar
          onSearch={(text) => setSearchText(text)}
          onDelete={selectedItems && selectedItems.length > 0 ? () => deleteItems() : null}
          onAdd={() => showForm(true)}
          addText="Add Cuisine"
        />
        <Box mt={3}>
          <Results
            data={contents}
            searchText={searchText}
            selectedIds={selectedItems}
            editItem={(item) => { setCuisine(item); showForm(true); }}
            onPreView={(item) => onShowContent(item)}
            onSelected={(ids) => setSelectedItems(ids)}
          // onDelete={(id) => deletePost(id)}
          // onViewDetails={(item) => {
          //   setDetailReport(item);
          //   setViewDetail(true);
          // }}
          />
        </Box>
        <Uploader
          visible={visibleForm}
          cuisine={cuisine}
          onClose={() => { showForm(false); setCuisine(); }}
          onSuccess={() => getContents()}
        />
        <Content
          content={content}
          visible={visibleContent}
          onClose={() => showContent(false)}
          onSuccess={() => getContents()}
        />
      </Container>
    </Page>
  );
};

Cuisines.propTypes = {
};
export default Cuisines;
