import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import Settings from 'src/utils/Settings';
import APIService from 'src/utils/APIService';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const [name, setAppName] = useState('');
  const { dataStore } = rest;

  useEffect(() => {
    APIService.getLiteSettings((success, json) => {
      if (success && json.result) {
        const { appName, siteName } = json.result;
        setAppName(appName);
        Settings.setAppName(appName);
        Settings.setSiteName(siteName);
      }
    });
  }, []);

  const logout = () => {
    dataStore.setNotifications([]);
    dataStore.setRole('');
    Settings.setRole(null);
    Settings.setSessionKey(null);
    navigate('/', { replace: true });
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={3}
      {...rest}
    >
      <Toolbar>
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Typography color="textPrimary" variant="h4" align="center">{name || ''}</Typography>
        </Box>
        <IconButton color="primary" onClick={() => dataStore.showNotif(true)}>
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton color="primary" onClick={() => logout()}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default inject('dataStore')(observer(TopBar));
