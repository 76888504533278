import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import APIService from 'src/utils/APIService';
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import colors from 'src/common/colors';
import Thumb from 'src/views/widgets/Thumb';
import RoundButton from 'src/components/RoundButton';
import ColorSection from 'src/components/ColorSection';
import StripePayment from 'src/components/StripePayment';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import SetPassword from '../components/SetPassword';
import SetHourOperation from '../components/SetHourOperation';
import SetPayment from '../components/SetPayment';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  card: {
    maxWidth: 500,
    borderRadius: 20,
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 20,
  },
  desc: {
    marginTop: 10,
  },
  thankyou: {
    marginTop: 30,
    fontSize: 36,
  },
  goHome: {
    marginTop: 50,
    width: 300,
    alignSelf: 'center',
    textTransform: 'capitalize',
    fontWeight: 600
  }
}));

const TruckOwnerSetOperation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [basicInfo, setBasicInfo] = useState({});

  const fetchUser = () => {
    const token = location.pathname.split('/').reverse()[0];
    APIService.checkToken(token, (success, json) => {
      if (success && json.result) {
        const { email, firstName, lastName } = json.result;
        setBasicInfo({
          email,
          firstName,
          lastName,
          sessionKey: token,
        });
      } else {
        navigate('/');
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, [location.pathname]);

  const onSubmit = (nextStep, data) => {
    if (nextStep < 3) {
      setStep(nextStep);
    }
    setBasicInfo({ ...basicInfo, ...data });

    if (nextStep === 3) {
      APIService.updateOperationProfile(basicInfo.sessionKey, basicInfo, (success, json) => {
        if (success && json.result) {
          setStep(nextStep);
          dispatch(notiActions.viewNoti({ type: 'success', content: 'Sent application successfully!' }));
        } else {
          dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
        }
      });
    }
  };

  const stepComp = [
    {
      title: 'Congratulations on being accepted!',
      subTitle: 'Let’s complete your application.',
      form: <SetPassword data={basicInfo} onSubmit={(data) => onSubmit(1, data)} />,
    },
    {
      title: 'Please complete your food truck profile.',
      subTitle: ' ',
      form: <SetHourOperation onSubmit={(data) => onSubmit(2, data)} />,
    },
    {
      subTitle: '',
      form: (
        <StripePayment>
          <SetPayment
            token={basicInfo.sessionKey}
            email={basicInfo.email}
            name={`${basicInfo.firstName} ${basicInfo.lastName}`}
            onSubmit={(data) => onSubmit(3, data)}
          />
        </StripePayment>
      ),
    },
    {
      title: 'Thank You!',
      subTitle: 'Thank you for completing the application.\nOur team is finalizing your account before access. Keep an eye on your email!',
      form: (
        <RoundButton className={classes.goHome} onClick={() => navigate('/')}>
          Go Home
        </RoundButton>
      ),
    },
  ];

  const finished = step === 3;
  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.body}>
                <Typography align="center" className={classes.welcomeText}>
                  {!finished && 'Welcome to'}
                </Typography>
                <Thumb uri="/static/images/treadmeal_logo.png" width="auto" height={36} />
                {stepComp[step].title && (
                  <Typography align="center" className={clsx(classes.title, finished && classes.thankyou)}>
                    {stepComp[step].title}
                  </Typography>
                )}
                {stepComp[step].subTitle && stepComp[step].subTitle.split('\n').map((e) => (
                  <Typography key={e} align="center" className={classes.desc}>
                    {e}
                  </Typography>
                ))}
                {stepComp[step].form}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default TruckOwnerSetOperation;
