import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import colors from 'src/common/colors';
import { LocalShippingOutlined } from '@material-ui/icons';
import CustomSwitch from 'src/components/CustomSwitch';
import APIService from 'src/utils/APIService';
import OperationHours from '../application/OperationHours';
import PaymentMethods from './PaymentMethods';
import TextHeading from './TextHeading';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 80,
    width: 80,
    alignSelf: 'center',
  },
  pricePoint: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  descriptionContainer: {
    display: 'flex',
    marginTop: 15,
    flexDirection: 'column',
    alignItems: 'center',
  },
  descriptionWrapper: {
    padding: 10,
    width: '100%',
    borderRadius: 20,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='black' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  },
  truckWrapper: {
    display: 'flex',
    marginTop: 15,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  truckItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    marginTop: 10

  },
  truckName: {
    marginLeft: 15
  }
}));

const Business = ({
  className, user, data, onFetch, ...rest
}) => {
  const classes = useStyles();
  const [viewTruckList, setViewTruckList] = useState(false);
  const { pricePoint } = data;
  const points = pricePoint || user?.operation?.pricePoint;
  const { address, city, zipCode } = user?.contact || {};
  const pricePoints = Array(points || 1).fill('$').join('');
  // const openingHours = data?.openingHours?.length !== 0 && data?.operation !== undefined
  //   ? data?.openingHours
  //   : user?.operation?.openingHours;
  const openingHours = user?.operation?.openingHours;
  const paymentMethods = Array.isArray(data?.operation?.paymentMethods)
    && data?.operation?.paymentMethods?.length !== 0
    ? data?.operation?.paymentMethods
    : user?.operation?.paymentMethods;

  const onDeactiveTruck = (id) => {
    APIService.adminDeactiveTruck(id, (success, json) => {
      if (success && json.result) {
        onFetch();
      }
    });
  };
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <TextHeading variant="h6">Company Info</TextHeading>
          <Typography variant="h4">{user?.businessName}</Typography>
          <Typography>{user?.phoneNumber}</Typography>
          <Typography>
            {address}
            {city ? `, ${city}` : ''}
            {zipCode ? `, ${zipCode}` : ''}
          </Typography>
          <Box display="flex" mt={1}>
            <Box>
              <TextHeading variant="h6">Cuisine Type</TextHeading>
              <Typography align="center" variant="h6">{user?.cuisine?.title}</Typography>
            </Box>
            <Box ml={3}>
              <TextHeading variant="h6">Price Point</TextHeading>
              <Box className={classes.pricePoint}>
                <Typography align="center" variant="h6">{pricePoints}</Typography>
                <Typography align="center" variant="h6" style={{ color: colors.grey }}>{Array(4 - (pricePoints?.length ?? 0)).fill('$').join('')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <OperationHours data={openingHours || []} center />
        <PaymentMethods data={paymentMethods || []} />
        {user?.operation?.description
          && (
            <Box className={classes.descriptionContainer}>
              <TextHeading variant="h6">Description</TextHeading>
              <Box className={classes.descriptionWrapper}>
                <Typography>{user?.operation?.description}</Typography>
              </Box>
            </Box>
          )}
        <Box className={classes.truckWrapper}>
          <TextHeading variant="h6">Trucks</TextHeading>
          <IconButton onClick={() => { setViewTruckList(true); }}>
            <LocalShippingOutlined />
          </IconButton>
        </Box>
        <Dialog open={viewTruckList} onClose={() => setViewTruckList(false)}>
          <DialogTitle style={{ width: '100%', textAlign: 'center' }}>Trucks</DialogTitle>
          <DialogContent>
            {user?.trucks && user?.trucks?.sort((a, b) => a.id - b.id).map((truck, index) => {
              return (
                <Box className={classes.truckItem}>
                  <LocalShippingOutlined />
                  <Typography className={classes.truckName}>
                    Food Truck
                    {' '}
                    {index + 1}
                  </Typography>
                  <CustomSwitch
                    checked={truck.status === 'ACTIVE'}
                    disabled={truck.status !== 'ACTIVE'}
                    onChange={() => { onDeactiveTruck(truck.id); }}
                  />
                </Box>
              );
            })}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

Business.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  data: PropTypes.object,
  onFetch: PropTypes.func,
};

export default Business;
