import React from 'react';
import Settings from 'src/utils/Settings';
import LoginView from '../auth/LoginView';
import Filter from '../customer/components/Filter';
import CustomerHome from '../customer/pages/CustomerHome';

const RouteProxy = () => {
  const { hostname } = window.location;
  const isAdminSite = hostname.includes('admin');

  if (isAdminSite) return <LoginView />;

  return (
    Settings.sessionKey() ? <Filter /> : <CustomerHome />
  );
};

export default RouteProxy;
