import CryptoHelper from './CryptoHelper';
import Settings, { Roles } from './Settings';
import WebService from './WebService';

export default class APIService {
  static urlServerAddress = process.env.REACT_APP_API_SERVER;

  static baseAPI = () => {
    return `${APIService.urlServerAddress}/api/`;
  };

  static adminAPI = () => {
    let route = '';
    const role = Settings.role();

    switch (role) {
      case Roles.ADMIN:
        route = 'admin/';
        break;
      case Roles.TRUCK_OWNER:
        route = 'truck-owner/';
        break;
      case Roles.CUSTOMER:
        route = 'customer/';
        break;
      default:
        route = '';
    }
    return `${APIService.baseAPI()}${route}`;
  };

  static apiSignIn = () => {
    return `${APIService.baseAPI()}users/login`;
  };

  static apiVerifyToken = () => {
    return `${APIService.adminAPI()}users/check-token`;
  };

  static apiCheckToken = () => {
    return `${APIService.baseAPI()}users/check-token`;
  }

  static apiVerifyUser = () => {
    return `${APIService.adminAPI()}users/{id}/verify`;
  };

  static apiForgotPassword = () => {
    return `${APIService.baseAPI()}users/password/reset`;
  };

  static apiUploadImage = () => {
    return `${APIService.baseAPI()}image`;
  };

  static apiContacts = () => {
    return `${APIService.baseAPI()}contacts`;
  };

  static apiGetFaqs = () => {
    return `${APIService.baseAPI()}faqs`;
  };

  static apiGetUsers = () => {
    return `${APIService.adminAPI()}users`;
  }

  static apiDeleteUsers = () => {
    return `${APIService.adminAPI()}users/many`;
  }

  static apiVerifyEmailAccount = (token) => {
    return `${APIService.baseAPI()}users/verify/${token}`;
  }

  static apiCheckEmailAvailability = () => {
    return `${APIService.baseAPI()}users/check-email`;
  }

  static apiVerifyChangeEmailAccount = (token) => {
    return `${APIService.baseAPI()}users/change-email/${token}`;
  }

  static apiGetSubscriptions = () => {
    return `${APIService.adminAPI()}payment/subscriptions`;
  }

  static apiGetPrivacyPolicy = () => {
    return `${APIService.baseAPI()}privacy`;
  }

  static apiPrivacyPolicy = (id) => {
    return `${APIService.adminAPI()}dynamic-contents/privacy/${id}`;
  }

  static apiGetTermsAndConditions = () => {
    return `${APIService.baseAPI()}terms`;
  }

  static apiGetFAQs = () => {
    return `${APIService.baseAPI()}faqs`;
  }

  static apiTermsAndConditions = (id) => {
    return `${APIService.adminAPI()}dynamic-contents/terms/${id}`;
  }

  static apiDynamicContents = () => {
    return `${APIService.adminAPI()}dynamic-contents`;
  }

  static apiDynamicContentsById = () => {
    return `${APIService.adminAPI()}dynamic-contents/{id}`;
  }

  static apiDeleteManyDynamicContents = () => {
    return `${APIService.adminAPI()}dynamic-contents/many`;
  }

  static apiMenuGroups = () => {
    return `${APIService.adminAPI()}menu-groups`;
  }

  static apiMenuGroupById = () => {
    return `${APIService.adminAPI()}menu-groups/{id}`;
  }

  static apiDeleteManyMenuGroups = () => {
    return `${APIService.adminAPI()}menu-groups/many`;
  }

  static apiMenuGroupsByOperationId = () => {
    return `${APIService.adminAPI()}menus/{id}`;
  }

  static apiMenuFoods = () => {
    return `${APIService.adminAPI()}foods`;
  }

  static apiTruckOwnerSubscriptionDetail = (id) => {
    return `${APIService.adminAPI()}payment/truck-owners/${id}/subscription`;
  }

  static apiMenuFoodById = () => {
    return `${APIService.adminAPI()}foods/{id}`;
  }

  static apiDeleteManyMenuFoods = () => {
    return `${APIService.adminAPI()}foods/many`;
  }

  static apiProfile = () => {
    return `${APIService.adminAPI()}profile`;
  }

  static apiTag = () => {
    return `${APIService.adminAPI()}tag`;
  }

  static apiTagByGroup = () => {
    return `${APIService.adminAPI()}tag/{group}`;
  }

  static apiTagByIds = () => {
    return `${APIService.adminAPI()}tag/many`;
  }

  static apiChangePassword = () => {
    return `${APIService.adminAPI()}users/change-password`;
  }

  static apiLocalization = () => {
    return `${APIService.adminAPI()}localization`;
  }

  static apiGetLanguages = () => {
    return `${APIService.adminAPI()}localization/languages`;
  }

  static apiTranslations = () => {
    return `${APIService.adminAPI()}language`;
  }

  static apiTranslationsByLangId = () => {
    return `${APIService.adminAPI()}language/{langId}`;
  }

  static apiCache = () => {
    return `${APIService.adminAPI()}cache`;
  };

  static apiCacheKeys = () => {
    return `${APIService.adminAPI()}cache/keys`;
  };

  static apiCacheStats = () => {
    return `${APIService.adminAPI()}cache/stats`;
  };

  static apiCacheFlushAll = () => {
    return `${APIService.adminAPI()}cache/flush-cache`;
  };

  static apiGetLogs = () => {
    return `${APIService.adminAPI()}logs`;
  };

  static apiDeleteLog = () => {
    return `${APIService.adminAPI()}logs/{id}`;
  };

  static apiDeleteLogs = () => {
    return `${APIService.adminAPI()}logs/many`;
  };

  static apiSettings = () => {
    return `${APIService.adminAPI()}setting`;
  }

  static apiLiteSettings = () => {
    return `${APIService.baseAPI()}settings/lite`;
  }

  static apiSaveSettings = () => {
    return `${APIService.apiSettings()}/save`;
  }

  static apiSaveSetting = () => {
    return `${APIService.apiSettings()}/save/{key}`;
  }

  static apiDashboard = () => {
    return `${APIService.adminAPI()}dashboard`;
  };

  static apiCuisines = () => {
    return `${APIService.baseAPI()}cuisines`;
  };

  static apiAdminCuisines = () => {
    return `${APIService.adminAPI()}cuisines`;
  };

  static apiAdminCuisinesId = (id) => {
    return `${APIService.adminAPI()}cuisines/${id}`;
  };

  static apiAdminAvatar = () => {
    return `${APIService.adminAPI()}profile/avatar`;
  };

  static apiManyCuisines = () => {
    return `${APIService.adminAPI()}cuisines/many`;
  };

  static apiApplications = () => {
    return `${APIService.adminAPI()}applications`;
  }

  static apiApplicationById = (id) => {
    return `${APIService.adminAPI()}applications/${id}`;
  }

  static apiApplicationStatus = () => {
    return `${APIService.apiApplications()}/{id}/process`;
  }

  static apiApplicationTOUpdate = () => {
    return `${APIService.baseAPI()}applications/init`;
  }

  static apiAddCustomer = () => {
    return `${APIService.adminAPI()}customer`;
  };

  static apiTruckOwner = () => {
    return `${APIService.adminAPI()}truck-owner`;
  };

  static apiBaseTruckOwner = () => {
    return `${APIService.baseAPI()}users/truck-owner`;
  };

  static apiBaseCustomer = () => {
    return `${APIService.baseAPI()}users/customer`;
  };

  static apiTrucks = () => {
    return `${APIService.adminAPI()}trucks`;
  };

  static apiTruckById = () => {
    return `${APIService.adminAPI()}trucks/{id}`;
  };

  static apiResetPassword = () => {
    return `${APIService.baseAPI()}users/password/reset`;
  };

  static apiInitPassword = () => {
    return `${APIService.baseAPI()}users/customer/init`;
  };

  static apiGetUserProfileById = (id) => {
    return `${APIService.adminAPI()}profile/${id}`;
  };

  static apiConfirmPaySubscription = () => {
    return `${APIService.baseAPI()}truck-owner/payment/subscribe`;
  };

  static apiSubscribe = () => {
    return `${APIService.baseAPI()}truck-owner/payment/subscribe/v2`;
  };

  static apiCapturePayment = () => {
    return `${APIService.baseAPI()}truck-owner/payment/subscribe/capture`;
  };

  static apiSubscriptionById = (id) => {
    return `${APIService.adminAPI()}payment/subscriptions/${id}`;
  };

  static apiSuspendUser = (id) => {
    return `${APIService.adminAPI()}users/${id}/suspend`;
  };

  static apiUpdateTOProfile = () => {
    return `${APIService.adminAPI()}truck-owner/profile`;
  };

  static apiUpdateCustomerProfile = () => {
    return `${APIService.adminAPI()}customer/profile`;
  };

  static apiCheckInitPassword = (token) => {
    return `${APIService.baseAPI()}users/customer/${token}`;
  };

  static apiAdminDeactivateTruck = (id) => {
    return `${APIService.adminAPI()}trucks/${id}/deactivate`;
  };

  static apiNotificationToken = () => {
    return `${APIService.adminAPI()}notifications/token`;
  };

  static apiAdminChangePassword = () => {
    return `${APIService.adminAPI()}profile/password`;
  };

  static apiAdminGet2FA = () => {
    return `${APIService.adminAPI()}setting/secret2FA`;
  };

  static apiAdminEnable2FA = () => {
    return `${APIService.adminAPI()}setting/enable2FA`;
  };

  static apiAdminDisable2FA = () => {
    return `${APIService.adminAPI()}setting/disable2FA`;
  };

  static apiCheckEnabled2FA = () => {
    return `${APIService.baseAPI()}users/check2FA`;
  };

  static apiVerify2FACode = () => {
    return `${APIService.baseAPI()}users/verify2FA`;
  };

  // TODO: multipart
  static signUpTruckOwner(
    logo,
    firstName,
    lastName,
    businessName,
    cuisineId,
    phoneNumber,
    email,
    address,
    city,
    zipCode,
    callback
  ) {
    const formData = new FormData();
    formData.append('logo', logo);

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('businessName', businessName);
    formData.append('cuisineId', cuisineId);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('zipCode', zipCode);

    WebService.sendJsonPOST(
      this.apiBaseTruckOwner(),
      {
        jwt: Settings.sessionKey(),
        formData
      },
      callback,
    );
  }

  static signUpCustomer(
    firstName,
    lastName,
    email,
    password,
    callback
  ) {
    WebService.sendJsonPOST(
      this.apiBaseCustomer(),
      {
        firstName,
        lastName,
        email,
        password
      },
      callback,
    );
  }

  static signIn(email, password, callback) {
    WebService.sendJsonPOST(
      this.apiSignIn(),
      {
        email,
        password,
      },
      callback,
    );
  }

  static suspendUser(id, suspend, callback) {
    WebService.sendJsonPUT(
      this.apiSuspendUser(id),
      {
        suspend,
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static verifyUserToken(sessionKey, callback) {
    WebService.sendJsonPOST(
      this.apiVerifyToken(),
      {
        token: sessionKey,
        jwt: sessionKey,
      },
      callback,
    );
  }

  static confirmResetPassword(token, newPassword, callback) {
    WebService.sendJsonPUT(
      this.apiResetPassword(),
      {
        token,
        newPassword
      },
      callback,
    );
  }

  static initPassword(token, password, callback) {
    WebService.sendJsonPUT(
      this.apiInitPassword(),
      {
        token,
        password
      },
      callback,
    );
  }

  static checkToken(token, callback) {
    WebService.sendJsonPOST(
      this.apiCheckToken(),
      {
        token
      },
      callback
    );
  }

  static getUserProfileById(id, callback) {
    WebService.sendJsonGET(
      this.apiGetUserProfileById(id),
      {
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static adminDeactiveTruck(id, callback) {
    WebService.sendJsonPUT(
      this.apiAdminDeactivateTruck(id),
      {
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static adminUpdateAvatar(avatar, callback) {
    const formData = new FormData();
    formData.append('avatar', avatar.file);
    WebService.sendJsonPUT(
      this.apiAdminAvatar(),
      {
        formData,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static verifyUser(id, callback) {
    WebService.sendJsonPUT(
      this.apiVerifyUser().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static forgotPassword(email, callback) {
    WebService.sendJsonPOST(
      this.apiForgotPassword(),
      {
        email,
      },
      callback,
    );
  }

  static uploadAvatar(jwt, uri, type, callback) {
    const uriParams = uri.split('/');
    const fileName = uriParams[uriParams.length - 1];
    const fileData = fileName.split('.');
    const fileType = fileData[fileData.length - 1];

    const formData = new FormData();
    formData.append('image', {
      uri,
      name: `${fileName}`,
      type: type || `image/${fileType}`,
    });

    WebService.sendJsonPOST(
      this.apiUploadImage(),
      {
        jwt,
        formData,
      },
      callback,
    );
  }

  static getUsers(callback) {
    WebService.sendJsonGET(
      this.apiGetUsers(),
      {
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static updateTOProfile(params, callback) {
    const formData = new FormData();
    formData.append('id', params.id);
    formData.append('firstName', params.firstName);
    formData.append('lastName', params.lastName);
    formData.append('cuisineId', params.cuisineId);
    formData.append('businessName', params.businessName);
    formData.append('phoneNumber', params.phoneNumber);
    formData.append('pricePoint', params.pricePoint);
    formData.append('description', params.description);
    formData.append('email', params.email);
    formData.append('city', params.city);
    formData.append('state', params.state);
    formData.append('zipCode', params.zipCode);
    if (params.avatar) {
      formData.append('avatar', params.avatar);
    }
    if (params.operationHours) {
      params.operationHours.forEach((e, index) => {
        formData.append(`operationHours[${index}]`, JSON.stringify(e));
      });
    }
    if (params.paymentMethod) {
      params.paymentMethod.forEach((e, index) => {
        formData.append(`paymentMethod[${index}]`, e);
      });
    } else {
      formData.append('paymentMethod', []);
    }
    WebService.sendJsonPUT(
      this.apiUpdateTOProfile(),
      {
        jwt: Settings.sessionKey(),
        formData,
      },
      callback
    );
  }

  static updateCustomerProfile(id, firstName, lastName, email, callback) {
    WebService.sendJsonPUT(
      this.apiUpdateCustomerProfile(),
      {
        id,
        firstName,
        lastName,
        email,
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static deleteUsers(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteUsers(),
      {
        jwt: Settings.sessionKey(),
        ids
      },
      callback
    );
  }

  static addTruckOwner(
    logo,
    firstName,
    lastName,
    businessName,
    cuisineId,
    phoneNumber,
    email,
    address,
    city,
    zipCode,
    callback
  ) {
    const formData = new FormData();
    formData.append('logo', logo);

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('businessName', businessName);
    formData.append('cuisineId', cuisineId);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('zipCode', zipCode);

    WebService.sendJsonPOST(
      this.apiTruckOwner(),
      {
        jwt: Settings.sessionKey(),
        formData
      },
      callback,
    );
  }

  static getTrucks(filterOptions, callback) {
    WebService.sendJsonGET(
      this.apiTrucks(),
      {
        jwt: Settings.sessionKey(),
        sortBy: 'DISTANCE',
        ...filterOptions
      },
      callback
    );
  }

  static getTruckById(id, callback) {
    WebService.sendJsonGET(
      this.apiTruckById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static getCuisines(callback) {
    WebService.sendJsonGET(
      this.apiCuisines(),
      {},
      callback
    );
  }

  static getCuisinesByAdmin(callback) {
    WebService.sendJsonGET(
      this.apiAdminCuisines(),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static addCuisine(title, key, sortOrder, callback) {
    WebService.sendJsonPOST(
      this.apiAdminCuisines(),
      {
        jwt: Settings.sessionKey(),
        key,
        title,
        sortOrder
      },
      callback,
    );
  }

  static deleteManyCuisines(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiManyCuisines(),
      {
        jwt: Settings.sessionKey(),
        ids
      },
      callback
    );
  }

  static getSubscriptions(callback) {
    WebService.sendJsonGET(
      this.apiGetSubscriptions(),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static getPrivacyPolicy(callback) {
    WebService.sendJsonGET(
      this.apiGetPrivacyPolicy(),
      {},
      callback
    );
  }

  static getBaseFAQs(callback) {
    WebService.sendJsonGET(
      this.apiGetFAQs(),
      {},
      callback
    );
  }

  static setPrivacyPolicy(id, title, content, callback) {
    WebService.sendJsonPUT(this.apiPrivacyPolicy(id),
      {
        jwt: Settings.sessionKey(),
        key: 'privacy',
        type: 'PRIVACY_POLICY',
        title,
        content,
      },
      callback);
  }

  static getTermsAndConditions(callback) {
    WebService.sendJsonGET(
      this.apiGetTermsAndConditions(),
      {},
      callback
    );
  }

  static setTermsAndConditions(id, title, content, callback) {
    WebService.sendJsonPUT(this.apiTermsAndConditions(id),
      {
        jwt: Settings.sessionKey(),
        key: 'terms',
        type: 'TERM_OF_USE',
        title,
        content,
      },
      callback);
  }

  static getDynamicContents(type, callback) {
    const queries = {
      jwt: Settings.sessionKey()
    };
    if (type) queries.type = type;
    WebService.sendJsonGET(
      this.apiDynamicContents(),
      queries,
      callback
    );
  }

  static addDynamicContent(type, key, title, content, callback) {
    WebService.sendJsonPOST(
      this.apiDynamicContents(),
      {
        jwt: Settings.sessionKey(),
        type,
        key,
        title,
        content
      },
      callback,
    );
  }

  static updateDynamicContent(id, type, key, title, content, index, callback) {
    WebService.sendJsonPUT(
      this.apiDynamicContentsById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
        type,
        key,
        title,
        content,
        sortOrder: index
      },
      callback,
    );
  }

  static deleteManyDynamicContents(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteManyDynamicContents(),
      {
        jwt: Settings.sessionKey(),
        ids
      },
      callback
    );
  }

  static getMenuGroups(callback) {
    WebService.sendJsonGET(
      this.apiMenuGroups(),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static getTruckOwnerSubscriptionDetail(id, callback) {
    WebService.sendJsonGET(
      this.apiTruckOwnerSubscriptionDetail(id),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static addMenuGroup(operationId, name, description, callback) {
    WebService.sendJsonPOST(
      this.apiMenuGroups(),
      {
        jwt: Settings.sessionKey(),
        operationId,
        name,
        description
      },
      callback,
    );
  }

  static sendContactMessage(email, name, businessName, place, message, callback) {
    WebService.sendJsonPOST(
      this.apiContacts(),
      {
        // jwt: Settings.sessionKey(),
        email,
        name,
        businessName,
        place,
        message
      },
      callback,
    );
  }

  static updateMenuGroup(id, name, description, callback) {
    WebService.sendJsonPUT(
      this.apiMenuGroupById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
        name,
        description
      },
      callback,
    );
  }

  static deleteMenuGroup(id, callback) {
    WebService.sendJsonDELETE(
      this.apiMenuGroupById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static updateMenuFood(id, name, price, description, callback) {
    WebService.sendJsonPUT(
      this.apiMenuFoodById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
        name,
        price,
        description
      },
      callback,
    );
  }

  static deleteMenuFood(id, callback) {
    WebService.sendJsonDELETE(
      this.apiMenuFoodById().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getMenuGroupsByOperation(operationId, callback) {
    WebService.sendJsonGET(
      this.apiMenuGroupsByOperationId().replace('{id}', operationId),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static deleteManyMenuGroups(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteManyMenuGroups(),
      {
        jwt: Settings.sessionKey(),
        ids
      },
      callback
    );
  }

  static getMenuFoods(callback) {
    WebService.sendJsonGET(
      this.apiMenuFoods(),
      {
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static addMenuFood(groupId, name, price, description, callback) {
    WebService.sendJsonPOST(
      this.apiMenuFoods(),
      {
        jwt: Settings.sessionKey(),
        groupId,
        name,
        price,
        description
      },
      callback,
    );
  }

  static deleteManyMenuFoods(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteManyMenuFoods(),
      {
        jwt: Settings.sessionKey(),
        ids
      },
      callback
    );
  }

  static getFAQs(callback) {
    WebService.sendJsonGET(
      this.apiGetFaqs(),
      {
        jwt: Settings.sessionKey(),
        type: 'FAQ'
      },
      callback
    );
  }

  static addFAQ(title, content, index, callback) {
    WebService.sendJsonPOST(
      this.apiDynamicContents(),
      {
        jwt: Settings.sessionKey(),
        type: 'FAQ',
        key: 'faq',
        title,
        content,
        sortOrder: index
      },
      callback,
    );
  }

  static deleteFAQs(ids, callback) {
    APIService.deleteManyDynamicContents(ids, callback);
  }

  static getProfile(jwt, callback) {
    WebService.sendJsonGET(
      this.apiProfile(),
      {
        jwt: jwt || Settings.sessionKey()
      },
      callback
    );
  }

  static updateProfile(values, callback) {
    WebService.sendJsonPUT(
      this.apiProfile(),
      {
        ...values,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static editBasicProfile(values, callback) {
    const formData = new FormData();
    formData.append('avatar', values.tempAvatar);
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);

    WebService.sendJsonPUT(
      this.apiProfile(),
      {
        formData,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static changePassword(values, callback) {
    WebService.sendJsonPUT(
      this.apiChangePassword(),
      {
        ...values,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static getDashboard(callback) {
    WebService.sendJsonGET(
      this.apiDashboard(),
      {
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static getTags(callback) {
    WebService.sendJsonGET(
      this.apiTag(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static checkInitPassword(token, callback) {
    WebService.sendJsonGET(
      this.apiCheckInitPassword(token),
      {
      },
      callback,
    );
  }

  static getTagsByGroup(group, callback) {
    WebService.sendJsonGET(
      this.apiTagByGroup().replace('{group}', group),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getReports(callback) {
    WebService.sendJsonGET(
      this.apiGetReports(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static deletePost(id, callback) {
    WebService.sendJsonDELETE(
      this.apiDeletePost().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static deleteManyPost(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeletePosts(),
      {
        ids,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static getLogs(callback) {
    WebService.sendJsonGET(
      this.apiGetLogs(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static verifyEmailAccount(token, callback) {
    WebService.sendJsonGET(
      this.apiVerifyEmailAccount(token),
      {},
      callback,
    );
  }

  static checkEmailAvailability(email, callback) {
    WebService.sendJsonPOST(
      this.apiCheckEmailAvailability(),
      { email },
      callback,
    );
  }

  static verifyChangeEmailAccount(token, callback) {
    WebService.sendJsonGET(
      this.apiVerifyChangeEmailAccount(token),
      {},
      callback,
    );
  }

  static deleteLog(id, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteLog().replace('{id}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static deleteManyLogs(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiDeleteLogs(),
      {
        ids,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static updateCuisines(id, title, sortOrder, callback) {
    WebService.sendJsonPUT(
      this.apiAdminCuisinesId(id),
      {
        title,
        sortOrder,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static addTag(langId, label, group, callback) {
    WebService.sendJsonPOST(
      this.apiTagByGroup().replace('{group}', group),
      {
        jwt: Settings.sessionKey(),
        langId,
        label
      },
      callback,
    );
  }

  static deleteManyTags(ids, callback) {
    WebService.sendJsonDELETE(
      this.apiTagByIds(),
      {
        ids,
        jwt: Settings.sessionKey()
      },
      callback
    );
  }

  static getLocalizations(callback) {
    WebService.sendJsonGET(
      this.apiLocalization(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static updateLocalization(id, enableCountry, enableLanguage, enableDial, callback) {
    WebService.sendJsonPOST(
      this.apiLocalization(),
      {
        jwt: Settings.sessionKey(),
        id,
        country: enableCountry,
        language: enableLanguage,
        dial: enableDial,
      },
      callback,
    );
  }

  static getLanguages(callback) {
    WebService.sendJsonGET(
      this.apiGetLanguages(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getTranslations(callback) {
    WebService.sendJsonGET(
      this.apiTranslations(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static addTranslation(phrase, langId, localeId, callback) {
    WebService.sendJsonPOST(
      this.apiTranslations(),
      {
        jwt: Settings.sessionKey(),
        phrase,
        langId,
        localeId
      },
      callback,
    );
  }

  static editTranslation(id, phrase, langId, localeId, callback) {
    WebService.sendJsonPUT(
      this.apiTranslations(),
      {
        jwt: Settings.sessionKey(),
        id,
        phrase,
        langId,
        localeId
      },
      callback,
    );
  }

  static getCacheValueByKey(key, callback) {
    WebService.sendJsonGET(
      this.apiCache(),
      {
        jwt: Settings.sessionKey(),
        key,
      },
      callback,
    );
  }

  static getCacheKeys(callback) {
    WebService.sendJsonGET(
      this.apiCacheKeys(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static setCache(key, value, callback) {
    WebService.sendJsonPUT(
      this.apiCache(),
      {
        jwt: Settings.sessionKey(),
        key,
        value,
      },
      callback,
    );
  }

  static deleteCache(key, callback) {
    WebService.sendJsonDELETE(
      this.apiCache(),
      {
        jwt: Settings.sessionKey(),
        key,
      },
      callback,
    );
  }

  static getCacheStats(callback) {
    WebService.sendJsonGET(
      this.apiCacheStats(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static flushCache(callback) {
    WebService.sendJsonDELETE(
      this.apiCacheFlushAll(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getTranslationsByLangId(id, callback) {
    WebService.sendJsonGET(
      this.apiTranslationsByLangId().replace('{langId}', id),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getSettings(callback) {
    WebService.sendJsonGET(
      this.apiSettings(),
      {
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static getLiteSettings(callback) {
    WebService.sendJsonGET(
      this.apiLiteSettings(),
      null,
      callback,
    );
  }

  static saveSetting(key, data, callback) {
    const {
      value, title, description, enabled
    } = data;
    WebService.sendJsonPOST(
      this.apiSaveSetting().replace('{key}', key),
      {
        jwt: Settings.sessionKey(),
        value,
        title,
        description,
        enabled,
      },
      callback,
    );
  }

  static saveSettings(data, callback) {
    WebService.sendJsonPOST(
      this.apiSaveSettings(),
      {
        jwt: Settings.sessionKey(),
        data,
      },
      callback,
    );
  }

  static getApplications(callback) {
    WebService.sendJsonGET(
      this.apiApplications(),
      { jwt: Settings.sessionKey(), },
      callback,
    );
  }

  static getApplicationById(id, callback) {
    WebService.sendJsonGET(
      this.apiApplicationById(id),
      { jwt: Settings.sessionKey(), },
      callback,
    );
  }

  static addCustomer(firstName, lastName, email, callback) {
    WebService.sendJsonPOST(
      this.apiAddCustomer(),
      {
        firstName,
        lastName,
        email,
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static processApplicationStatus(id, status, extraContent, callback) {
    WebService.sendJsonPUT(
      this.apiApplicationStatus().replace('{id}', id),
      {
        status,
        extraContent,
        jwt: Settings.sessionKey(),
      },
      callback,
    );
  }

  static updateOperationProfile(jwt, profile, callback) {
    const {
      password, operation, pricePoint, menuImgs,
      stripeId, stripeToken, paymentMethods
    } = profile;

    const formData = new FormData();
    menuImgs.forEach((image, index) => {
      formData.append(`menuImgs[${index}]`, image);
    });

    formData.append('token', jwt);
    formData.append('password', password);
    operation.forEach((e, index) => {
      formData.append(`operation[${index}]`, JSON.stringify(e));
    });
    formData.append('pricePoint', pricePoint);
    formData.append('stripeId', stripeId);
    formData.append('stripeToken', stripeToken);

    paymentMethods.forEach((e, index) => {
      formData.append(`paymentMethods[${index}]`, e);
    });
    WebService.sendJsonPOST(
      this.apiApplicationTOUpdate(),
      { formData },
      callback,
    );
  }

  static async pushNotificationToken(token, deviceId, callback) {
    console.log(this.apiNotificationToken());
    WebService.sendJsonPOST(
      this.apiNotificationToken(),
      {
        token,
        deviceId,
        platform: 'CHROME',
        jwt: Settings.sessionKey(),
      },
      callback
    );
  }

  static async confirmSubscription(
    name, cardNumber, expMonth, expYear, cvc,
    jwt, callback
  ) {
    const cryptoCVC = CryptoHelper.encrypt(cvc);
    WebService.sendJsonPOST(this.apiConfirmPaySubscription(),
      {
        jwt,
        name,
        number: cardNumber,
        expMonth,
        expYear,
        cvc: cryptoCVC,
      },
      callback);
  }

  static async subscribe(
    paymentMethod,
    jwt,
    callback
  ) {
    WebService.sendJsonPOST(this.apiSubscribe(),
      {
        paymentMethod,
        jwt,
      },
      callback);
  }

  static async capturePayment(
    paymentIntent,
    jwt,
    callback
  ) {
    WebService.sendJsonPOST(this.apiCapturePayment(),
      {
        paymentIntent,
        jwt,
      },
      callback);
  }

  static async adminCancelSubscription(id, callback) {
    WebService.sendJsonDELETE(this.apiSubscriptionById(id),
      {
        jwt: Settings.sessionKey()
      },
      callback);
  }

  static async adminReSubscription(userId, callback) {
    WebService.sendJsonPOST(this.apiSubscriptionById(userId),
      {
        jwt: Settings.sessionKey()
      },
      callback);
  }

  static async adminChangePassword(oldPassword, newPassword, callback) {
    WebService.sendJsonPUT(this.apiAdminChangePassword(),
      {
        jwt: Settings.sessionKey(),
        oldPassword,
        newPassword
      },
      callback);
  }

  static async get2FACode(callback) {
    WebService.sendJsonPOST(this.apiAdminGet2FA(),
      {
        jwt: Settings.sessionKey()
      },
      callback);
  }

  static async enableAdmin2FA(secret, otp, callback) {
    WebService.sendJsonPOST(this.apiAdminEnable2FA(),
      {
        jwt: Settings.sessionKey(),
        secret,
        otp,
      },
      callback);
  }

  static async disableAdmin2FA(otp, callback) {
    WebService.sendJsonPOST(this.apiAdminDisable2FA(),
      {
        jwt: Settings.sessionKey(),
        otp,
      },
      callback);
  }

  static async check2FA(callback) {
    WebService.sendJsonPOST(this.apiCheckEnabled2FA(),
      {},
      callback);
  }

  static async verify2FACode(otp, callback) {
    WebService.sendJsonPOST(this.apiVerify2FACode(),
      {
        jwt: Settings.sessionKey(),
        otp,
      },
      callback);
  }
}
