import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
} from '@material-ui/core';
import ColorSection from 'src/components/ColorSection';
import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import PricePlan from 'src/components/PricePlan';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    backgroundColor: colors.skin,
    marginBottom: 80,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

const data = [
  {
    image: '/static/images/pricing_customer.png',
    title: 'Customer',
    content: 'Create an account to explore the food truck community, support local, and discover the flavors your city has to offer!',
    buttonTitle: 'Sign up',
    to: '/sign-up/customer'
  },
  {
    image: '/static/images/pricing_food_truck_owner.png',
    title: 'Food Truck Owner',
    content: 'Apply today to showcase your food truck, join the Treadmeal community, and gain exposure for your business!',
    price: 9.99,
    frequency: 'month',
    buttonTitle: 'Apply',
    to: '/sign-up/truck-owner'
  },
];

const Pricing = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ColorSection title="Pricing" color={colors.skin} {...props} className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={6} className={classes.wrapper}>
          {
            data.map((item) => {
              const {
                image, title, content,
                price, frequency,
                buttonTitle, to
              } = item;

              return (
                <Grid key={title.toString()} item xs={12} sm={8} md={6}>
                  <PricePlan
                    image={image}
                    title={title}
                    desc={content}
                    price={price ? `$${price}` : 'Free'}
                    frequency={frequency ? `per ${frequency}` : ' '}
                    buttonText={buttonTitle}
                    onClick={() => {
                      navigate(to);
                      window.scrollTo(0, 0);
                    }}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default Pricing;
