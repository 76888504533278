import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import QRCode from 'react-qr-code';
import { LockOutlined } from '@material-ui/icons';
import colors from 'src/common/colors';
import APIService from 'src/utils/APIService';
import CardTitle from 'src/components/CardTitle';

const useStyles = makeStyles(({
  root: {},
  hint: {
    marginBottom: 10,
  },
  submit: {
    height: 55,
    marginTop: 15,
    marginLeft: 20,
  }
}));

const GAuth2FA = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const [enabled2FA, setEnabled2FA] = useState(false);
  const [secret, setSecret] = useState('');
  const [showQR, setShowQR] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  useEffect(() => {
    const setting2FA = data.SECRET_2FA;
    const enabled = setting2FA?.value && setting2FA.value.length > 0;
    setEnabled2FA(enabled);
    setShowQR(false);
  }, [data]);

  const get2FACode = () => {
    APIService.get2FACode((success, json) => {
      if (success && json.result) {
        const { result } = json;
        setSecret(result.secret);
        setShowQR(result.url);
        setShowOTP(true);
      } else {
        alert(json.error);
      }
    });
  };

  const enable2FA = (otp, actions) => {
    APIService.enableAdmin2FA(secret, otp, (success, json) => {
      actions.setSubmitting(false);
      if (success && json.result) {
        setShowQR(false);
        setShowOTP(false);
        setEnabled2FA(true);
      } else {
        alert(json.error || 'Invalid OTP');
      }
    });
  };

  const toggle2FA = () => {
    if (!enabled2FA) {
      get2FACode();
    } else {
      setShowOTP(true);
    }
  };

  const disable2FA = (code, actions) => {
    APIService.disableAdmin2FA(code, (success, json) => {
      actions.setSubmitting(false);
      if (success && json.result) {
        setShowOTP(false);
        setEnabled2FA(false);
      } else {
        alert(json.error || 'Invalid OTP');
      }
    });
  };

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().length(6).required('Input 2FA Code')
      })}
      onSubmit={(values, actions) => {
        if (enabled2FA) {
          disable2FA(values.code, actions);
        } else {
          enable2FA(values.code, actions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              title={(<CardTitle title="Two-Factor Authentication" icon={<LockOutlined style={{ color: colors.red }} />} />)}
              subheader="Enhance security with 2 Factor Authentication"
            />
            <Divider />
            <CardContent>
              <FormControlLabel
                control={<Switch defaultChecked checked={enabled2FA} />}
                label="Enable 2 Factor Authentication"
                onClick={toggle2FA}
              />
              {!!showQR
                && (
                  <Box py={3}>
                    <Typography className={classes.hint}>
                      Scan QR with Google Authenticator
                    </Typography>
                    <QRCode value={showQR} size={150} />
                  </Box>
                )}
              {showOTP
                && (
                  <Box
                    display="flex"
                    flexDirection="row"
                  >
                    <TextField
                      label="Input OTP Code"
                      margin="normal"
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.code}
                      variant="outlined"
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className={classes.submit}
                    >
                      {enabled2FA ? 'Disable 2FA' : 'Enable 2FA'}
                    </Button>
                  </Box>
                )}
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

GAuth2FA.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
};

export default GAuth2FA;
