import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import APIService from 'src/utils/APIService';
import Page from 'src/components/Page';
import StatsCard from './StatsCard';
import CacheStats from '../reports/DashboardView/CacheStats';
import CacheInspector from '../reports/DashboardView/CacheInspector';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CacheMonitor = () => {
  const classes = useStyles();
  const location = useLocation();
  const [stats, setStats] = useState({});

  const fetchStats = () => {
    APIService.getCacheStats((success, json) => {
      if (success && json.result) {
        const statistics = json.result;
        setStats(statistics);
      }
    });
  };

  const flushCache = () => {
    APIService.flushCache((success, json) => {
      if (success && json) {
        fetchStats();
      }
    });
  };

  useEffect(() => {
    fetchStats();
  }, [location.pathname]);

  return (
    <Page
      className={classes.root}
      title="Cache Monitor"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <StatsCard stats={stats} onFlush={flushCache} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CacheStats data={stats} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CacheInspector />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CacheMonitor;
