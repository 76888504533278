import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
  Card,
  CardHeader,
  Drawer,
  List,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import APIService from 'src/utils/APIService';
import Settings, { ApplicationStatus, Roles } from 'src/utils/Settings';
import { onMessageListener } from 'src/firebase';
import NotificationItem from './NotificationItem';
import ApplicationForm from '../application/ApplicationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 480,
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.dark,
  },
}));

const NotificationDrawer = ({
  className,
  ...rest
}) => {
  const classes = useStyles();
  const { dataStore } = rest;
  const [item2Edit, setItem2Edit] = useState({});
  const [readItems, setReadItems] = useState([]);
  const [notiIds, setNotiIds] = useState('');
  const [notifications, setNotifications] = useState(dataStore.notifications || []);
  const parseAvatar = (notif) => {
    if (notif && notif.openingHours && notif.user) {
      return notif.user.operation?.logo || notif.user.operation?.logoURL;
    }
    return null;
  };

  const message4Status = (status) => {
    switch (status) {
      case ApplicationStatus.WAITING:
        return 'sent an application form';
      case ApplicationStatus.PENDING:
        return 'updated menu and operation hours';
      default:
        return '';
    }
  };

  const fetchNotifications = () => {
    const sessionKey = Settings.sessionKey();
    if (!sessionKey) return;

    const jwt = jwtDecode(sessionKey);
    const admin = jwt.role && jwt.role === Roles.ADMIN;
    if (!admin) return;

    APIService.getApplications((success, json) => {
      if (json.result) {
        const applications = json.result;
        const filterStatuses = [ApplicationStatus.WAITING, ApplicationStatus.PENDING];
        let newNotif = false;
        const notifs = applications
          .filter((e) => filterStatuses.includes(e.status))
          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
          .map((e) => {
            if (!notiIds.includes(e.id)) {
              if (!newNotif) {
                newNotif = true;
                dataStore.setNewNotification({
                  id: e.id,
                  avatar: parseAvatar(e),
                  name: `${e.user.firstName} ${e.user.lastName}`,
                  content: message4Status(e.status),
                  createdAt: e.createdAt,
                });
              }
              setNotiIds((preIds) => [...preIds, e.id]);
            }
            return {
              id: e.id,
              avatar: parseAvatar(e),
              name: `${e.user.firstName} ${e.user.lastName}`,
              content: message4Status(e.status),
              createdAt: e.createdAt,
            };
          });
        dataStore.setApplications(applications);
        dataStore.setNotifications(notifs);
        setNotifications(notifs);
      }
    });
  };

  const clearPollInterval = () => {
    if (dataStore.pollIntervalNotif) {
      clearInterval(dataStore.pollIntervalNotif);
    }
  };

  onMessageListener().then(() => {
    fetchNotifications();
  }).catch((err) => console.log('failed: ', err));

  useEffect(() => {
    fetchNotifications();

    return () => {
      clearPollInterval();
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [dataStore.role]);

  const onClose = () => {
    dataStore.showNotif(false);
  };

  const openItem = (notification) => {
    notification.seen = true;
    setReadItems([...readItems, notification.id]);
    setNotifications([...notifications]);
    setItem2Edit(dataStore.applications.find((e) => e.id === notification.id));
  };

  return (
    <Drawer
      anchor="right"
      open={dataStore.visibleNotif}
      onClose={() => onClose()}
    >
      <Card>
        <CardHeader title="Notifications" />
      </Card>
      <List className={clsx(classes.root, className)}>
        {notifications && notifications.map((e) => (
          <NotificationItem
            key={e.id}
            data={e}
            seen={e.seen || readItems.includes(e.id)}
            onClick={() => openItem(e)}
          />
        ))}
      </List>
      {
        item2Edit && item2Edit.id
        && (
          <ApplicationForm
            data={item2Edit}
            onClose={() => { setItem2Edit(null); fetchNotifications(); }}
          />
        )
      }
    </Drawer>
  );
};

NotificationDrawer.propTypes = {
  className: PropTypes.string,
};

export default inject('dataStore')(observer(NotificationDrawer));
