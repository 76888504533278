/* eslint-disable indent */
const keywords = ['RECIPIENT', 'SENDER', 'SENDER_EMAIL', 'VERIFY_BUTTON', 'DATE', 'DATETIME', 'NEW_EMAIL', 'CONTENT', 'APP_STORE_URL', 'PLAY_STORE_URL'];

const dropdownKeyword = {
    name: 'dropdown_keyword',
    display: 'submenu',

    title: 'Important keywords',
    innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>',
    add(core, targetElement) {
        const listDiv = this.setSubmenu.call(core);

        const self = this;
        listDiv.querySelectorAll('.se-btn-list').forEach((btn) => {
            if (btn) btn.addEventListener('click', self.onClick.bind(core));
        });
        core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu() {
        let menuHtml = '<div class="se-list-inner"><ul class="se-list-basic" style="width: 230px;">{{KEYWORDS}}</ul></div>';
        let keywordHtml = '';

        const listDiv = this.util.createElement('DIV');
        listDiv.className = 'se-submenu se-list-layer';

        Object.values(keywords).forEach((key) => {
            keywordHtml = keywordHtml.concat(`<li>
            <button type="button" class="se-btn-list" value={{${key}}}>
                <strong>{{${key}}}</strong>
            </button>
        </li>`);
        });
        menuHtml = menuHtml.replace(new RegExp('{{KEYWORDS}}'), keywordHtml);
        listDiv.innerHTML = menuHtml;

        return listDiv;
    },

    onClick(e) {
        const { value } = e.target;
        const node = this.util.createElement('span');
        this.util.addClass(node, 'se-keyword');
        node.textContent = value;

        this.insertNode(node);
        const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
        node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

        this.submenuOff();
    }
};

export default dropdownKeyword;
