import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import ImagesViewer from 'src/components/ImagesViewer';
import Thumb from '../widgets/Thumb';
import TextHeading from '../profile/TextHeading';

const useStyles = makeStyles(() => ({
  root: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
  },
  button: {
    cursor: 'pointer',
    marginRight: 10,
  }
}));

const MenuPhotos = ({
  className,
  images,
  onView,
  ...rest
}) => {
  const classes = useStyles();
  const [isViewImages, setIsViewImages] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openImage = (index) => {
    setImageIndex(index);
    setIsViewImages(true);
  };

  const closeImage = () => {
    setIsViewImages(false);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box mt={1} mb={3}>
          <TextHeading variant="h5" align="center">
            Menu Photos
          </TextHeading>
        </Box>
        <Box
          className={classes.wrapper}
        >
          {
          images && images.map((e, index) => (
            <Box key={e.split('/').slice(-1)[0]} onClick={() => openImage(index)} className={classes.button}>
              <Thumb uri={e} width="auto" height={80} />
            </Box>
          ))
        }
        </Box>
        <ImagesViewer
          active={isViewImages}
          images={images}
          index={imageIndex}
          onClose={closeImage}
        />
      </CardContent>
    </Card>
  );
};

MenuPhotos.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array,
  onView: PropTypes.func,
};

export default MenuPhotos;
