import { createSlice } from '@reduxjs/toolkit';

const notiSlice = createSlice({
  name: 'auth',
  initialState: {
    type: 'success',
    content: '',
    hasNoti: false,
  },
  reducers: {
    viewNoti(state, action) {
      state.type = action.payload.type;
      state.content = action.payload.content;
      state.hasNoti = true;
    },
    hideNoti(state) {
      state.hasNoti = false;
    }
  }
});

export const notiActions = notiSlice.actions;
export default notiSlice;
