import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  CardHeader,
  IconButton
} from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 90,
    width: 90
  },
  dash: {
    padding: 10,
    borderRadius: 55,
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23333' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
  }
}));

const Account = ({
  className, onEdit, disableEdit, ...rest
}) => {
  const classes = useStyles();
  const { user } = rest;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      {!disableEdit && (
      <CardHeader
        action={(
          <IconButton aria-label="edit" onClick={onEdit}>
            <EditOutlined />
          </IconButton>
        )}
      />
      )}
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Box className={classes.dash}>
            <Avatar
              className={classes.avatar}
              src={user?.avatarURL || user?.operation?.logo || user?.operation?.logoURL}
            />
          </Box>
          <Box mt={1} />
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {user?.email || ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Account.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func,
  disableEdit: PropTypes.bool
};

export default Account;
