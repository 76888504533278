import 'date-fns';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Typography,
  CircularProgress,
  Link,
} from '@material-ui/core';
import clsx from 'clsx';
import APIService from 'src/utils/APIService';
import colors from 'src/common/colors';
import RoundButton from 'src/components/RoundButton';
import { useDispatch } from 'react-redux';
import { notiActions } from 'src/common/store/noti-slice';
import {
  CardNumberElement, useElements, useStripe, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import RoundRadio from './RoundRadio';

const useStyles = makeStyles(() => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  label: {
    fontSize: 15,
    marginTop: 20,
  },
  input: {
    height: 60,
    fontSize: 20,
    fontWeight: '600',
  },
  expiration: {
    width: 132,
  },
  cvc: {
    width: 80,
  },
  submit: {
    width: 300,
    marginTop: 50,
    alignSelf: 'center',
  },
  submitLabel: {
    fontWeight: '600',
    textTransform: 'none',
  },
  price: {
    display: 'inline',
    fontWeight: '600',
  },
  agree2Terms: {
    paddingTop: 20,
  },
  link: {
    fontWeight: '600',
  },
  progress: {
    color: colors.white,
    alignSelf: 'center',
  },
  input2: {
    height: 60,
    fontWeight: '600',
    width: '100%',
    border: 'dashed 0.2px rgba(0,0,0,0.43)',
    background: '#fbfefa',
    padding: '16px 10px',
    borderRadius: '10px',
    // flex: 1
  },
  element: {
    fontSize: 20
  }
}));

const SetPayment = ({
  token, onSubmit, email, name
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit2 = async (callback) => {
    const paymentMethod = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    });
    if (paymentMethod?.paymentMethod?.id) {
      APIService.subscribe(
        paymentMethod?.paymentMethod?.id,
        token,
        async (success, json) => {
          if (success && json.result) {
            const confirmPayment = await stripe?.confirmCardPayment(
              json.result.clientSecret
            );
            if (confirmPayment.paymentIntent?.status === 'succeeded') {
              APIService.capturePayment(
                confirmPayment.paymentIntent.id,
                token,
                () => {
                  if (onSubmit) {
                    onSubmit();
                  }
                }
              );
            } else {
              callback();
              dispatch(notiActions.viewNoti({
                type: 'error',
                content: 'There was a problem processing your payment. Please try again later.'
              }));
            }
          } else {
            callback();
            dispatch(notiActions.viewNoti({
              type: 'error',
              content: 'There was a problem processing your payment. Please try again later.'
            }));
          }
        }
      );
    } else if (paymentMethod.error && paymentMethod.error.message) {
      callback();
      dispatch(notiActions.viewNoti({ type: 'error', content: paymentMethod.error.message }));
    }
  };

  return (
    <Formik
      initialValues={{
        agree2Pay: false,
        agree2Terms: false,
      }}
      validationSchema={Yup.object().shape({
        agree2Pay: Yup.boolean().required('You must confirm to pay'),
        agree2Terms: Yup.boolean().required('You must agree to our terms and conditions'),
      })}
      onSubmit={(values, actions) => {
        const { agree2Pay, agree2Terms } = values;
        if (!agree2Pay) {
          actions.setSubmitting(false);
          dispatch(notiActions.viewNoti({ type: 'error', content: 'You must confirm to pay' }));
          return;
        }
        if (!agree2Terms) {
          actions.setSubmitting(false);
          dispatch(notiActions.viewNoti({ type: 'error', content: 'You must agree to our terms and conditions' }));
          return;
        }
        onSubmit2(() => {
          actions.setSubmitting(false);
        });
      }}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className={classes.formContent} onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            mt={3}
          >
            <Typography className={classes.label}>Card Number</Typography>
            <CardNumberElement
              className={classes.input2}
              options={{
                style: {
                  base: {
                    fontSize: '20px',
                    padding: '10px',
                  }
                }
              }}
            />

            <Box className={classes.row}>
              <Box className={classes.expiration}>
                <Typography className={classes.label}>Expiration Date</Typography>
                <CardExpiryElement
                  className={classes.input2}
                  options={{
                    style: {
                      base: {
                        fontSize: '20px',
                        padding: '10px',
                      }
                    }
                  }}
                />
              </Box>
              <Box display="flex" flexGrow={1} />
              <Box className={classes.cvc}>
                <Typography className={classes.label}>CVV/CVC</Typography>
                <CardCvcElement
                  className={classes.input2}
                  options={{
                    style: {
                      base: {
                        fontSize: '20px',
                        padding: '10px',
                      }
                    }
                  }}
                />
              </Box>
            </Box>

            <Box className={classes.row} mt={4}>
              <RoundRadio
                isSelected={values.agree2Pay}
                onClick={() => setFieldValue('agree2Pay', !values.agree2Pay)}
              />
              <Typography>
                By checking this box  you are agreeing to pay
                <Typography className={classes.price}> $9.99 </Typography>
                a month to use the Treadmeal App.
              </Typography>
            </Box>
            <Box className={clsx(classes.row, classes.agree2Terms)}>
              <RoundRadio
                isSelected={values.agree2Terms}
                onClick={() => setFieldValue('agree2Terms', !values.agree2Terms)}
              />
              <Typography>
                By checking this box  you are agreeing to our
                <Link className={classes.link} href="/terms" target="_blank"> Terms and Conditions </Link>
                and
                <Link className={classes.link} href="/privacy" target="_blank"> Privacy Policy.</Link>
              </Typography>
            </Box>
          </Box>
          <RoundButton className={classes.submit} type="submit">
            {isSubmitting
              ? <CircularProgress size={24} className={classes.progress} />
              : <Typography className={classes.submitLabel}>Complete and Pay</Typography>}
          </RoundButton>
        </form>
      )}
    </Formik>
  );
};

SetPayment.propTypes = {
  token: PropTypes.string,
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string
};

export default SetPayment;
