import {
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import React from 'react';
import * as Yup from 'yup';
// import { useNavigate } from 'react-router-dom';
import colors from 'src/common/colors';
import ColorSection from 'src/components/ColorSection';
import APIService from 'src/utils/APIService';
import InputSC from 'src/views/menu/InputSC';
import RoundButton from 'src/components/RoundButton';
import { notiActions } from 'src/common/store/noti-slice';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh'
  },
  container: {
    padding: 30,
  },
  logo: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 35,
    paddingLeft: 80,
    paddingRight: 80,
  },
  title: {
    fontWeight: '600',
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            direction="column"
            alignItems="center"
          >
            <Card className={classes.card}>
              <img alt="" src="/static/images/treadmeal_logo.png" className={classes.logo} />
              <Box mt={4} mb={3}>
                <Typography align="center" className={classes.title}>
                  Please enter your email to reset your password
                </Typography>
              </Box>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Invalid email format').required('Email is required'),
                })}
                onSubmit={(values, actions) => {
                  const { email } = values;
                  APIService.forgotPassword(email, (success, json) => {
                    if (success && json.result) {
                      dispatch(notiActions.viewNoti({ type: 'success', content: 'The request is sent to your email!' }));
                      actions.resetForm();
                    } else {
                      dispatch(notiActions.viewNoti({ type: 'error', content: json.error }));
                    }
                  });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form className={classes.formContent} onSubmit={handleSubmit}>
                    <InputSC
                      className={classes.input}
                      label="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={errors.email}
                    />
                    <Box style={{ height: 20 }} />
                    <RoundButton className={classes.submit} type="submit">
                      <Typography className={classes.submitLabel}>Reset password</Typography>
                    </RoundButton>
                  </form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default ForgotPassword;
