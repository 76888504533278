import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Button,
} from '@material-ui/core';
import {
  Edit3 as EditIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  searchText,
  onSelected,
  onEditItem,
  selectedIds,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((item) => {
    return item.text.toLowerCase().indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  ['Phrase', ''].map((title) => <TableCell key={title}>{title}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell>
                      {item.text}
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => onEditItem && onEditItem(item)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  onEditItem: PropTypes.func,
  selectedIds: PropTypes.array,
};

export default Results;
