import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyle = makeStyles({
  picker: {
    '& .MuiInput-input': {
      textTransform: 'lowercase',
    }
  }
});

export default function RoundTimePicker({ initialValue, onTimeChange }) {
  const classes = useStyle();
  const [selectedDate, setSelectedDate] = React.useState(initialValue
    ? new Date(initialValue) : new Date());
  const [isOpen, setIsOpen] = React.useState(false);

  return (

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box style={{
        width: '80px',
        position: 'relative',
        height: 30,
        border: 'dashed 1px rgba(0,0,0,0.43)',
        borderRadius: '30px',
        alignItems: 'center',
      }}
      >
        <KeyboardTimePicker
          style={{ position: 'absolute', top: -18, left: 3 }}
          className={classes.picker}
          margin="normal"
          id="time-picker"
          value={selectedDate}
          keyboardIcon={null}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          open={isOpen}
          KeyboardButtonProps={{
            'aria-label': 'change time',
            disabled: true,
            style: { display: 'none' }
          }}
          onChange={(value) => setSelectedDate(new Date(value))}
          onClick={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onAccept={onTimeChange}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
}

RoundTimePicker.propTypes = {
  initialValue: PropTypes.any,
  onTimeChange: PropTypes.func.isRequired,
};
