import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Button
} from '@material-ui/core';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: 150,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.27)',
    background: colors.action,
    padding: '10px 20px',
    borderRadius: '30px',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: colors.tmGreen,
    }
  },
}));

const RoundButton = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      type="button"
      className={clsx(classes.root, className)}
      {...props}
    />
  );
};

RoundButton.propTypes = {
  className: PropTypes.string,
};

export default RoundButton;
