import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Snackbar,
} from '@material-ui/core';
import APIService from 'src/utils/APIService';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import SunREInput from '../../dynamic-contents/Editor';

const PrivacySetting = ({ onSave }) => {
  const location = useLocation();
  const [privacy, setPrivacy] = useState({ id: 0 });
  const [notice, showNotice] = useState(false);

  const fetchPrivacy = () => {
    APIService.getPrivacyPolicy((success, json) => {
      if (success && json.result) {
        const item = json.result;
        setPrivacy(item);
      }
    });
  };

  useEffect(() => {
    fetchPrivacy();
  }, [location.pathname]);

  return (
    <Formik
      initialValues={{
        title: 'Privacy Policy',
        content: ''
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('Content is required'),
      })}
      onSubmit={(values, actions) => {
        APIService.setPrivacyPolicy(
          privacy.id,
          values.title,
          values.content,
          (success, json) => {
            actions.setSubmitting(false);
            if (success && json.result) {
              showNotice(true);
              fetchPrivacy();
              actions.setStatus('Success');
              if (onSave) onSave(json);
            } else {
              actions.setErrors({ error: 'Failed to save!' });
            }
          }
        );
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Card>
          <CardHeader
            title="Privacy Policy"
            subheader="Edit Privacy Policy"
            action={(
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </Box>
            )}
          />
          <Divider />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <SunREInput
                name="content"
                content={privacy.content}
                height={600}
                placeholder="Content"
                onChange={(text) => setFieldValue('content', text)}
              />
            </form>
          </CardContent>
          <Snackbar open={notice} autoHideDuration={2000} onClose={() => showNotice(false)}>
            <Alert severity="success">Saved successfully!</Alert>
          </Snackbar>
        </Card>
      )}
    </Formik>
  );
};

PrivacySetting.propTypes = {
  onSave: PropTypes.func,
};
export default PrivacySetting;
