import {
  Container,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LogoTreadMeal from 'src/components/LogoTreadMeal';
import colors from 'src/common/colors';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import APIService from 'src/utils/APIService';
import ColorSection from 'src/components/ColorSection';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  contentWrapper: {
    backgroundColor: '#fff',
    borderRadius: 20,
    maxWidth: 500,
    paddingTop: 300,
    paddingBottom: 300,
  },
  title: {
    fontSize: 21,
    fontWeight: 400,
  },
  message: {
    fontSize: 15,
    fontWeight: 400,
  },
  image: {
    width: '100%',
    maxWidth: 375,
    maxHeight: 51.8,
    marginTop: 40,
    marginBottom: 40,
  },

}));

const CustomerEmailVerificationDone = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const verification = () => {
    const token = location.pathname.split('/').reverse()[0];
    APIService.verifyEmailAccount(token, (success, json) => {
      if (success && json.result) {
        setIsLoading(false);
      } else {
        navigate('/404');
      }
    });
  };

  useEffect(() => {
    verification();
  }, [location.pathname]);

  return (
    <ColorSection color={colors.skin} className={classes.root}>
      <Container className={classes.contentWrapper}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          {
            !isLoading
              ? (
                <>
                  <Typography className={classes.title} align="center">Thank you for joining with us</Typography>
                  <LogoTreadMeal className={classes.image} />
                  <Typography className={classes.message} align="center">
                    You now can login with your account email
                  </Typography>
                </>
              )
              : (
                <Typography className={classes.title} align="center">Loading...</Typography>
              )
          }
        </Grid>
      </Container>
    </ColorSection>
  );
};

export default CustomerEmailVerificationDone;
