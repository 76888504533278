import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import colors from 'src/common/colors';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: '600',
    color: colors.headingTeal,
    textTransform: 'uppercase',
  }
}));

const TextHeading = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.heading} {...props} />
  );
};

export default TextHeading;
