import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
  Avatar,
  Button,
  Chip,
} from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { ApplicationStatus } from 'src/utils/Settings';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  data,
  filter,
  searchText,
  onSelected,
  selectedIds,
  onEdit,
  onView,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedItemIds;

    if (event.target.checked) {
      newSelectedItemIds = data.map((item) => item.id);
    } else {
      newSelectedItemIds = [];
    }
    onSelected(newSelectedItemIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedItemIds = newSelectedItemIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItemIds = newSelectedItemIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    onSelected(newSelectedItemIds);
  };

  const renderStatusChip = (status) => {
    let backgroundColor = 'grey';
    let color = 'white';
    switch (status) {
      case ApplicationStatus.APPROVED: backgroundColor = '#00A19D'; break;
      case ApplicationStatus.PENDING: color = 'black'; backgroundColor = '#FFF8E5'; break;
      case ApplicationStatus.WAITING: backgroundColor = '#FFB344'; break;
      case ApplicationStatus.DENIED: backgroundColor = '#E05D5D'; break;
      default:
    }
    return <Chip size="small" label={status} style={{ color, backgroundColor }} />;
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const search = searchText ? searchText.toLowerCase() : '';
  const records = data.filter((item) => {
    const date = moment(item.created_at).format('DD/MM/YYYY HH:mm');

    if (filter) {
      let not = false;
      Object.keys(filter)
        .forEach((key) => {
          if (!filter[key]) return;
          if (item[key] !== filter[key]) not = true;
        });
      if (not) return false;
    }

    return item?.user?.businessName?.toLowerCase().indexOf(search) >= 0
      || item?.user?.cuisine?.title.toLowerCase().indexOf(search) >= 0
      || item?.user?.phoneNumber?.indexOf(search) >= 0
      || item?.user?.email?.indexOf(search) >= 0
      || item?.status?.toLowerCase().indexOf(search) >= 0
      || date.toLowerCase().indexOf(search) >= 0;
  });
  const maxPage = Math.floor(records.length / limit);
  if (page > maxPage) {
    setPage(maxPage);
  }

  const editItem = (item) => {
    if (onEdit) onEdit(item);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === data.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {
                  ['Business', 'Cuisine', 'Contact', 'Status', 'Created At', ''].map((e) => (
                    <TableCell key={e}>{e}</TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {records.slice(page * limit, page * limit + limit).map((item) => {
                const date = moment(item.createdAt).format('DD/MM/YYYY HH:mm');
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={selectedIds.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.indexOf(item.id) !== -1}
                        onChange={(event) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        onClick={() => onView && onView(item.user)}
                        style={{ cursor: 'pointer' }}
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={item.user.operation.logoURL}
                        />
                        <Typography>{item.user.businessName}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.user.cuisine.title}
                    </TableCell>
                    <TableCell>
                      <Typography>{item.user.phoneNumber}</Typography>
                      <Typography>{item.user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      {renderStatusChip(item.status)}
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => editItem(item)}>
                        <EditOutlined />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={records.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  searchText: PropTypes.string,
  onSelected: PropTypes.func,
  selectedIds: PropTypes.array,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
};

export default Results;
